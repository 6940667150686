import { FC } from 'react';

interface ITelegramLinks {
  path: string;
}

const TelegramLinks: FC<ITelegramLinks> = ({ path }) => {
  if (!path) {
    return;
  }

  const links = path.split(',');

  const formattedLinks = links.map(link => {
    if (/^https:\/\/t\.me\//i.test(link)) {
      const username = link.split('https://t.me/')[1];
      return (
        <a key={link} href={link} data-testid="telegram" target={'_blank'} rel={'noreferrer'}>
          @{username}
        </a>
      );
    }
    if (/^@/i.test(link)) {
      const formattedNick = link.replace('@', '');
      return (
        <a
          key={link}
          data-testid="telegram"
          href={`https://t.me/${formattedNick}`}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {link}
        </a>
      );
    }
    return null;
  });

  return (
    <div
      style={{
        display: 'inline-flex',
        gap: '10px',
        flexWrap: 'wrap',
      }}
    >
      {formattedLinks}
    </div>
  );
};

export default TelegramLinks;
