import { FC, useState } from 'react';
import CustomTable from '../UI/Table';
import { ISearchRequestHistoriesPageQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { RU_DATE, RU_DATE_TIME } from '../../core/constants/dateFormatting';
import { useGetRequestHistoriesQuery } from '../../store/services/requests';
import { getContractStatusName, getPosTableNum } from '../../core/utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';

interface IRequestHistoriesTable {
  id: string | undefined;
}

const RequestHistoriesTable: FC<IRequestHistoriesTable> = ({ id }) => {
  const [searchAndPaginationParams, setSearchAndPaginationParams] = useState<ISearchRequestHistoriesPageQuery>({
    requestId: id,
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const { data } = useGetRequestHistoriesQuery(searchAndPaginationParams);
  const paginationCurrentPage = searchAndPaginationParams.page ? searchAndPaginationParams.page + 1 : 1;

  const requestHistories = data?.data.map((requestHistory, index) => ({
    ...requestHistory,
    posNum: getPosTableNum(
      index,
      searchAndPaginationParams.page || DEFAULT_PAGE,
      searchAndPaginationParams.size || DEFAULT_PAGE_SIZE
    ),
    changedBy: `${requestHistory.creator.lastName} ${requestHistory.creator.firstName} ${
      requestHistory.creator.patronymic ? requestHistory.creator.patronymic : ''
    }`,
    key: requestHistory.id,
  }));

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Обновил/а',
      dataIndex: 'changedBy',
      key: 'changedBy',
    },
    {
      title: 'Дата обновления',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: dateAndTime => {
        return dayjs(dateAndTime).format(RU_DATE_TIME);
      },
    },
    {
      title: 'Дата заявки',
      dataIndex: 'date',
      key: 'date',
      render: date => {
        return date && dayjs(date).format(RU_DATE);
      },
    },
    {
      title: 'Дата от',
      dataIndex: 'fromDate',
      key: 'fromDate',
      align: 'center',
      render: date => {
        return date && dayjs(date).format(RU_DATE);
      },
    },
    {
      title: 'Дата до',
      dataIndex: 'toDate',
      key: 'toDate',
      align: 'center',
      render: date => {
        return date && dayjs(date).format(RU_DATE);
      },
    },
    {
      title: 'Статус документа',
      dataIndex: 'documentStatus',
      key: 'documentStatus',
      render: (status: string) => {
        return status && <>{getContractStatusName(status)}</>;
      },
    },
    {
      title: 'Комментарии',
      dataIndex: 'comments',
      key: 'comments',
      render: comments => {
        return comments && comments;
      },
    },
  ];
  const handlePaginationChange = (page: number, size: number) => {
    setSearchAndPaginationParams({
      ...searchAndPaginationParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      <CustomTable
        tableKey={'RequestHistoriesTable'}
        dataSource={requestHistories}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: searchAndPaginationParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
    </>
  );
};

export default RequestHistoriesTable;
