import Title from 'antd/es/typography/Title';
import ProjectDirectionsTable from '../../components/Tables/ProjectDirectionsTable';

const ProjectDirections = () => {
  return (
    <div>
      <Title>Направления</Title>
      <ProjectDirectionsTable />
    </div>
  );
};

export default ProjectDirections;
