import { api } from '../index';
import { IContractsCourse, IContractsCourseMutationType } from '../../../core/types/entities';

export const coursesApi = api.injectEndpoints({
  endpoints: builder => ({
    getContractsCourses: builder.query<IContractsCourse[], void>({
      query: () => `/contracts/courses`,
      providesTags: ['contracts-courses'],
    }),
    addContractsCourse: builder.mutation<IContractsCourse, IContractsCourseMutationType>({
      query: data => ({
        url: '/contracts/courses',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['contracts-courses'],
    }),
    updateContractsCourse: builder.mutation<IContractsCourse, [number, IContractsCourseMutationType]>({
      query: ([id, data]) => ({
        url: `/contracts/courses/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['contracts-courses'],
    }),
    deleteContractsCourse: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/courses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['contracts-courses'],
    }),
  }),
});

export const {
  useGetContractsCoursesQuery,
  useAddContractsCourseMutation,
  useUpdateContractsCourseMutation,
  useDeleteContractsCourseMutation,
} = coursesApi;
