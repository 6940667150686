import { api } from '../index';
import { IDeal, IDealHistories, ISearchPage } from '../../../core/types/entities';
import { ISearchDealHistoriesQuery, ISearchDealsPageQuery } from '../../../core/types/query';
import { IDealCreateForm, IDealUpdateForm, IMassActionDeals, IUploadFilesToEntity } from '../../../core/types/forms';
import { ContractStatusesEnum } from '../../../core/types/enums';

export const dealsApi = api.injectEndpoints({
  endpoints: builder => ({
    getDeals: builder.query<ISearchPage<IDeal>, ISearchDealsPageQuery>({
      query: params => ({
        url: `/deals`,
        params: params,
      }),
      providesTags: ['deals'],
    }),
    getDeal: builder.query<IDeal, string | undefined>({
      query: id => ({
        url: `/deals/${id}`,
      }),
      providesTags: ['deals'],
    }),
    addDeal: builder.mutation<IDeal, IDealCreateForm>({
      query: dealData => ({
        url: `/deals`,
        method: 'POST',
        body: dealData,
      }),
      invalidatesTags: ['deals'],
    }),
    changeDealsStatus: builder.mutation<void, [ContractStatusesEnum, IMassActionDeals]>({
      query: ([status, data]) => ({
        url: `/deals/statuses?status=${status}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['deals'],
    }),
    uploadFilesToDeal: builder.mutation<IDeal, [number, IUploadFilesToEntity]>({
      query: ([id, uploadData]) => ({
        url: `/deals/${id}/files`,
        method: 'POST',
        body: uploadData,
      }),
      invalidatesTags: ['deals'],
    }),
    updateDeal: builder.mutation<IDeal, [number, IDealUpdateForm]>({
      query: ([id, dealData]) => ({
        url: `/deals/${id}`,
        method: 'PATCH',
        body: dealData,
      }),
      invalidatesTags: ['deals'],
    }),
    removeDeal: builder.mutation<void, number>({
      query: id => ({
        url: `/deals/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['deals'],
    }),
    getDealHistories: builder.query<ISearchPage<IDealHistories>, ISearchDealHistoriesQuery>({
      query: params => ({
        url: `/deals/histories`,
        params: params,
      }),
      providesTags: ['deals'],
    }),
    regenerateDealDocuments: builder.mutation<void, number>({
      query: id => ({
        url: `/deals/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['deals'],
    }),
  }),
});

export const {
  useGetDealsQuery,
  useGetDealQuery,
  useAddDealMutation,
  useChangeDealsStatusMutation,
  useUploadFilesToDealMutation,
  useUpdateDealMutation,
  useRemoveDealMutation,
  useGetDealHistoriesQuery,
  useRegenerateDealDocumentsMutation,
} = dealsApi;
