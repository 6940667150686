import { api } from '../index';
import { ISearchPage, Meeting, MeetingCreate } from '../../../core/types/entities';
import { ISearchPageQuery } from '../../../core/types/query';

export const meetingsApi = api.injectEndpoints({
  endpoints: builder => ({
    getMeetings: builder.query<ISearchPage<Meeting>, ISearchPageQuery>({
      query: () => ({
        url: `/meetings`,
      }),
      providesTags: ['meetings'],
    }),
    getMeeting: builder.query<void, number>({
      query: id => ({
        url: `/meetings/${id}`,
      }),
      providesTags: ['meetings'],
    }),
    addMeeting: builder.mutation<Meeting, MeetingCreate>({
      query: params => ({
        url: '/meetings',
        method: 'POST',
        params: params,
      }),
      invalidatesTags: ['meetings'],
    }),
    editMeeting: builder.mutation<void, MeetingCreate>({
      query: params => ({
        url: `/meetings/${params.id}`,
        method: 'PATCH',
        params: params,
      }),
      invalidatesTags: ['meetings'],
    }),
  }),
});

export const { useGetMeetingQuery, useGetMeetingsQuery, useAddMeetingMutation, useEditMeetingMutation } = meetingsApi;
