import React, { useContext, useMemo } from 'react';
import { EyeInvisibleOutlined, EyeOutlined, HolderOutlined } from '@ant-design/icons';
import type { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks/redux';
import {
  hideColumnTable,
  setNewOrderedColumnsToTable,
  showColumnTable,
} from '../../../../store/slices/table/TableSlice';

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const ToggleShow: React.FC<{ tableKey: string; columnKey: string }> = ({ tableKey, columnKey }) => {
  const dispatch = useAppDispatch();

  const showColumns = useAppSelector(state => state.tableReducer[tableKey].showColumns);
  const isVisible = showColumns.includes(columnKey);
  const onClick = () => {
    if (isVisible) {
      if (showColumns.length <= 3) {
        return;
      }

      dispatch(
        hideColumnTable({
          tableKey,
          columnKey,
        })
      );
      return;
    }
    dispatch(
      showColumnTable({
        tableKey,
        columnKey,
      })
    );
  };

  return <Button shape="circle" onClick={onClick} icon={isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />} />;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const columns: ColumnsType<any> = [
  {
    key: 'sort',
    align: 'center',
    width: 40,
    render: () => <DragHandle />,
  },
  {
    title: 'Название колонки',
    dataIndex: 'title',
    render: (record: string) => {
      return <>{record || 'Управление'}</>;
    },
  },
  {
    title: '',
    width: 40,
    dataIndex: 'action',
    render: (_, record) => {
      return <ToggleShow tableKey={record.tableKey} columnKey={record.key} />;
    },
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<RowProps> = props => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

interface DndColumnsControlProps {
  tableColumns: ColumnsType;
  tableKey: string;
}

const DndColumnsControl: React.FC<DndColumnsControlProps> = ({ tableColumns, tableKey }) => {
  const dispatch = useAppDispatch();
  const orderedColumns = useAppSelector(state => state.tableReducer[tableKey].orderedColumns);
  const orderedData = orderedColumns.map(c => ({
    ...tableColumns.find(i => i.key === c),
    tableKey,
  })) as ColumnsType;
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = orderedData.findIndex(record => record.key === active?.id);
      const overIndex = orderedData.findIndex(record => record.key === over?.id);
      dispatch(
        setNewOrderedColumnsToTable({
          tableKey,
          columns: arrayMove(orderedData, activeIndex, overIndex).map(i => i.key) as string[],
        })
      );
    }
  };

  console.log(tableKey);

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={tableColumns.map(i => i.key) as UniqueIdentifier[]}
        strategy={verticalListSortingStrategy}
      >
        <Table
          size={'small'}
          pagination={false}
          rowKey="key"
          components={{ body: { row: Row } }}
          columns={columns}
          dataSource={orderedData}
        />
      </SortableContext>
    </DndContext>
  );
};

export default DndColumnsControl;
