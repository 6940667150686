import { FC, useEffect } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { toast } from 'react-toastify';
import { useGetUsersQuery } from '../../store/services/users';
import { RolesEnum } from '../../core/types/enums';
import {
  customFilterOption,
  getFullNameUser,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
} from '../../core/utils';
import { useGetActsQuery } from '../../store/services/acts';
import { useAppSelector } from '../../core/hooks/redux';
import { IReceiptCreate } from '../../core/types/forms';
import { useCreateReceiptMutation } from '../../store/services/receipts';
import DatePickerBase from '../UI/DatePickerBase';

interface ICertificateCreateModal {
  open: boolean;
  setClose: () => void;
  actId?: number;
}

const ReceiptControlModal: FC<ICertificateCreateModal> = ({ open, setClose, actId }) => {
  const { role, user } = useAppSelector(state => state.authReducer);
  const [form] = Form.useForm();
  const [createReceipt, createReceiptStates] = useCreateReceiptMutation();
  const agents = useGetUsersQuery(
    {
      roles: RolesEnum.AGENT,
      size: 999,
    },
    { skip: role === RolesEnum.AGENT || !open }
  ).data;
  const acts = useGetActsQuery({ size: 999 }, { skip: !open }).data;

  useEffect(() => {
    if (createReceiptStates.isSuccess) {
      toast.success('Справка успешно создана');
      handleCloseModal();
    }
    if (createReceiptStates.isError) {
      toast.error('Возникла проблема при создании справки');
    }
  }, [createReceiptStates.isError, createReceiptStates.isSuccess]);

  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  const handleCreateReceipt = (data: IReceiptCreate) => {
    if (role === RolesEnum.AGENT) {
      data['agentId'] = user?.id as number;
    }
    createReceipt(data);
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={(role === RolesEnum.AGENT ? 'Загрузить' : 'Создать') + ' чек'}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={() => form.submit()}
      onCancel={handleCloseModal}
      okText={'Создать'}
      afterOpenChange={() => form.getFieldInstance('date')?.focus()}
    >
      <Form form={form} layout={'vertical'} onFinish={handleCreateReceipt}>
        <Form.Item
          label={'Дата чека'}
          name={'date'}
          getValueFromEvent={getValueFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
        >
          <DatePickerBase
            disabledDate={date => {
              const dayOfWeek = date.day();
              return dayOfWeek === 0 || dayOfWeek === 6;
            }}
          />
        </Form.Item>
        {role !== RolesEnum.AGENT.toString() && (
          <Form.Item label={'Контрагент'} name={'agentId'}>
            <Select showSearch filterOption={customFilterOption} allowClear>
              <Select.OptGroup label={'Контрагент'} />
              {agents?.data.map(agent => (
                <Select.Option key={agent.id} value={agent.id}>
                  {getFullNameUser(agent)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!actId && (
          <Form.Item label={'Акты'} name={'actIds'}>
            <Select allowClear mode="multiple">
              <Select.OptGroup label={'Акты'} />
              {acts?.data.map(act => (
                <Select.Option key={act.id} value={act.id}>
                  № {act.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label={'Ссылка'} name={'url'}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReceiptControlModal;
