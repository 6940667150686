import Title from 'antd/es/typography/Title';
import ActsPaymentsTable from '../../components/Tables/ActsPaymentsTable';

const ActsPaymentsPage = () => {
  return (
    <>
      <Title>Реестр оплат:</Title>
      <ActsPaymentsTable />
    </>
  );
};

export default ActsPaymentsPage;
