import Title from 'antd/es/typography/Title';
import ServiceUnitsTable from '../../components/Tables/SeviceUnitsTable';

const ServiceUnits = () => {
  return (
    <>
      <Title>Единицы измерения</Title>
      <ServiceUnitsTable />
    </>
  );
};

export default ServiceUnits;
