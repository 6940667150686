import { ActStatus, ContractValidityStatusesEnum, RolesEnum, TableFiltersItemsEnum } from '../core/types/enums';
import { ChangeEvent, CSSProperties, Dispatch, FC, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { useGetProjectsQuery } from '../store/services/contracts/projects';
import { useGetProjectsDirectionsQuery } from '../store/services/contracts/projects-directions';
import { useGetProjectSpecificationsQuery } from '../store/services/contracts/projects-specifications';
import { useGetSignSystemsQuery } from '../store/services/contracts/sign-systems';
import { useGetTaxStatusesQuery } from '../store/services/contracts/tax-status';
import { useGetContractsQuery } from '../store/services/contracts/contracts';
import { useGetRequestsQuery } from '../store/services/requests';
import { useGetActsQuery } from '../store/services/acts';
import { debounce } from 'lodash';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ISO_DATE } from '../core/constants/dateFormatting';
import dayjs from 'dayjs';
import {
  customFilterOption,
  getActsStatusName,
  getContractValidityStatusName,
  getFullNameUser,
  getUserRoleName,
} from '../core/utils';
import DatePickerBase from './UI/DatePickerBase';
import { useGetUsersQuery } from '../store/services/users';

const DEFAULT_PAGE_SIZE = 999;
const DEBOUNCE_DELAY = 500;
const FILTER_ROW_KEY_PREFIX = 'filter_row_';
const FILTER_COL_KEY_PREFIX = 'filter_col_';

interface ITableFilters {
  fields: TableFiltersItemsEnum[];
  visibleRows?: number;
  columns?: 4 | 3 | 2;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  params: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setParams: Dispatch<SetStateAction<any>>;
  footer?: ReactNode;
}

const TableFilters: FC<ITableFilters> = ({ fields, visibleRows = 1, columns = 4, params, setParams, footer }) => {
  const responsibleUsers = useGetUsersQuery(
    {
      roles: `${RolesEnum.ADMIN}, ${RolesEnum.MANAGER}`,
      size: 999,
    },
    { skip: !fields.includes(TableFiltersItemsEnum.RESPONSIBLE_USER) }
  );
  const projects = useGetProjectsQuery(undefined, { skip: !fields.includes(TableFiltersItemsEnum.PROJECT) });
  const projectsDirection = useGetProjectsDirectionsQuery(params.projectId, {
    skip: !fields.includes(TableFiltersItemsEnum.PROJECT_DIRECTION),
  });
  const projectSpecifications = useGetProjectSpecificationsQuery(params.projectId, {
    skip: !fields.includes(TableFiltersItemsEnum.PROJECT_SPECIFICATION),
  });
  const signSystems = useGetSignSystemsQuery(undefined, { skip: !fields.includes(TableFiltersItemsEnum.SIGN_SYSTEM) });
  const taxStatuses = useGetTaxStatusesQuery(undefined, { skip: !fields.includes(TableFiltersItemsEnum.TAX_STATUS) });
  const contracts = useGetContractsQuery(
    { size: DEFAULT_PAGE_SIZE },
    { skip: !fields.includes(TableFiltersItemsEnum.CONTRACT) }
  );
  const requests = useGetRequestsQuery(
    { size: DEFAULT_PAGE_SIZE },
    { skip: !fields.includes(TableFiltersItemsEnum.REQUEST) }
  );
  const acts = useGetActsQuery({ size: DEFAULT_PAGE_SIZE }, { skip: !fields.includes(TableFiltersItemsEnum.ACT) });
  const [isMoreFilters, setIsMoreFilters] = useState<boolean>(false);

  const rows: TableFiltersItemsEnum[][] = useMemo(() => {
    const computedRows = [];
    for (let i = 0; i < fields.length; i += columns) {
      computedRows.push(fields.slice(i, i + columns));
    }
    return computedRows;
  }, [fields]);

  const itemLabelCol = { span: 24 };
  const formItemsStyles: CSSProperties = {
    margin: 0,
  };
  const colResponsiveConfig = {
    4: { lg: 6, md: 12, xs: 24 },
    3: { lg: 6, md: 8, xs: 24 },
    2: { xl: 12, lg: 12, md: 24, xs: 24 },
  };

  const handleSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const findText = event.target.value;
    setParams({
      ...params,
      page: 0,
      query: findText,
    });
  }, DEBOUNCE_DELAY);

  const fieldsComponents: Record<TableFiltersItemsEnum, ReactNode> = {
    [TableFiltersItemsEnum.FIND_BY_FIO]: (
      <Form.Item label="Поиск по ФИО" labelCol={itemLabelCol} style={formItemsStyles}>
        <Input allowClear placeholder="Введите ФИО" onChange={handleSearch} />
      </Form.Item>
    ),
    [TableFiltersItemsEnum.FIND_BY_AGENT_FIO]: (
      <Form.Item label="Поиск по ФИО контрагента" labelCol={itemLabelCol} style={formItemsStyles}>
        <Input allowClear placeholder="Введите ФИО контрагента" onChange={handleSearch} />
      </Form.Item>
    ),
    [TableFiltersItemsEnum.RESPONSIBLE_USER]: (
      <Form.Item label={'Ответственный'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          allowClear
          showSearch
          filterOption={customFilterOption}
          onChange={responsibleUserId =>
            setParams({
              ...params,
              page: 0,
              responsibleUserId,
            })
          }
          value={params.responsibleUserId}
        >
          <Select.OptGroup label={'Ответственный'} />
          {responsibleUsers.data?.data?.map(responsibleUser => (
            <Select.Option key={responsibleUser.id} value={responsibleUser.id}>
              {getFullNameUser(responsibleUser)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.DATE]: (
      <Form.Item label={'Дата'} labelCol={itemLabelCol} style={formItemsStyles}>
        <DatePickerBase
          style={{ width: '100%' }}
          onChange={date =>
            setParams({
              ...params,
              page: 0,
              date: date?.format(ISO_DATE),
            })
          }
          value={params?.date ? dayjs(params?.date, ISO_DATE) : undefined}
        />
      </Form.Item>
    ),
    [TableFiltersItemsEnum.FROM_DATE]: (
      <Form.Item label={'Дата от'} labelCol={itemLabelCol} style={formItemsStyles}>
        <DatePickerBase
          style={{ width: '100%' }}
          onChange={date =>
            setParams({
              ...params,
              page: 0,
              fromDate: date?.format(ISO_DATE),
            })
          }
          value={params?.fromDate ? dayjs(params?.fromDate, ISO_DATE) : undefined}
        />
      </Form.Item>
    ),
    [TableFiltersItemsEnum.FROM_PAYMENT_DATE]: (
      <Form.Item label={'Дата оплаты Акта от'} labelCol={itemLabelCol} style={formItemsStyles}>
        <DatePickerBase
          style={{ width: '100%' }}
          onChange={date =>
            setParams({
              ...params,
              page: 0,
              fromPaymentDate: date?.format(ISO_DATE),
            })
          }
          value={params?.fromPaymentDate ? dayjs(params?.fromPaymentDate, ISO_DATE) : undefined}
        />
      </Form.Item>
    ),
    [TableFiltersItemsEnum.TO_DATE]: (
      <Form.Item label={'Дата до'} labelCol={itemLabelCol} style={formItemsStyles}>
        <DatePickerBase
          style={{ width: '100%' }}
          onChange={date =>
            setParams({
              ...params,
              page: 0,
              toDate: date?.format(ISO_DATE),
            })
          }
          value={params?.toDate ? dayjs(params?.toDate, ISO_DATE) : undefined}
        />
      </Form.Item>
    ),
    [TableFiltersItemsEnum.TO_PAYMENT_DATE]: (
      <Form.Item label={'Дата оплаты Акта до'} labelCol={itemLabelCol} style={formItemsStyles}>
        <DatePickerBase
          style={{ width: '100%' }}
          onChange={date =>
            setParams({
              ...params,
              page: 0,
              toPaymentDate: date?.format(ISO_DATE),
            })
          }
          value={params?.toPaymentDate ? dayjs(params?.toPaymentDate, ISO_DATE) : undefined}
        />
      </Form.Item>
    ),
    [TableFiltersItemsEnum.DATE_WITHOUT_REQUEST]: (
      <Form.Item label={'Дата у которой нет заявки'} labelCol={itemLabelCol} style={formItemsStyles}>
        <DatePickerBase
          style={{ width: '100%' }}
          onChange={date =>
            setParams({
              ...params,
              page: 0,
              dateWithoutRequest: date?.format(ISO_DATE) || undefined,
            })
          }
          value={params?.dateWithoutRequest ? dayjs(params?.dateWithoutRequest, ISO_DATE) : undefined}
        />
      </Form.Item>
    ),
    [TableFiltersItemsEnum.PROJECT]: (
      <Form.Item label={'Проект'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          onChange={projectId =>
            setParams({
              ...params,
              page: 0,
              projectId,
            })
          }
          value={params.projectId}
          allowClear
        >
          <Select.OptGroup label={'Проект'} />

          {projects.data?.map(project => (
            <Select.Option key={project.id} value={project.id}>
              {project.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.PROJECT_DIRECTION]: (
      <Form.Item label={'Направление проекта'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          allowClear
          disabled={!params.projectId}
          onChange={projectDirectionId =>
            setParams({
              ...params,
              page: 0,
              projectDirectionId,
            })
          }
          value={params.projectDirectionId}
        >
          <Select.OptGroup label={'Направление проекта'} />

          {projectsDirection.data?.map(projectDirection => (
            <Select.Option key={projectDirection.id} value={projectDirection.id}>
              {projectDirection.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.PROJECT_SPECIFICATION]: (
      <Form.Item label={'Cпецификация проекта'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          allowClear
          disabled={!params.projectId}
          onChange={projectSpecificationId =>
            setParams({
              ...params,
              page: 0,
              projectSpecificationId,
            })
          }
          value={params.projectSpecificationId}
        >
          <Select.OptGroup label={'Cпецификация проекта'} />

          {projectSpecifications.data?.map(projectSpecification => (
            <Select.Option key={projectSpecification.id} value={projectSpecification.id}>
              {projectSpecification.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.SIGN_SYSTEM]: (
      <Form.Item label={'Тип подписи'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          onChange={signSystemId =>
            setParams({
              ...params,
              page: 0,
              signSystemId,
            })
          }
          allowClear
          value={params.signSystemId}
        >
          <Select.OptGroup label={'Тип подписи'} />

          {signSystems.data?.map(signSystem => (
            <Select.Option key={signSystem.id} value={signSystem.id}>
              {signSystem.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.TAX_STATUS]: (
      <Form.Item label={'Налоговый статус'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          onChange={taxStatusId =>
            setParams({
              ...params,
              page: 0,
              taxStatusId,
            })
          }
          allowClear
          value={params.taxStatusId}
        >
          <Select.OptGroup label={'Налоговый статус'} />

          {taxStatuses.data?.map(taxStatus => (
            <Select.Option key={taxStatus.id} value={taxStatus.id}>
              {taxStatus.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.CONTRACT]: (
      <Form.Item label={'Договор'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          allowClear
          onChange={contractId =>
            setParams({
              ...params,
              page: 0,
              contractId,
            })
          }
          value={params.contractId}
        >
          <Select.OptGroup label={'Договор'} />
          {contracts.data?.data.map(contract => (
            <Select.Option key={contract.id} value={contract.id}>
              № {contract.number}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.REQUEST]: (
      <Form.Item label={'Заявка'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          allowClear
          onChange={requestId =>
            setParams({
              ...params,
              page: 0,
              requestId,
            })
          }
          value={params.requestId}
        >
          <Select.OptGroup label={'Заявка'} />
          {requests.data?.data.map(request => (
            <Select.Option key={request.id} value={request.id}>
              № {request.number}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.ACT]: (
      <Form.Item label={'Акт'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select onChange={actId => setParams({ ...params, page: 0, actId: actId })} value={params.actId} allowClear>
          <Select.OptGroup label={'Акт'} />

          {acts?.data?.data?.map(act => (
            <Select.Option key={act.id} value={act.id}>
              № {act.number}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.ROLE]: (
      <Form.Item label={'Роль'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          onChange={curRole => {
            setParams({
              ...params,
              page: 0,
              roles: curRole !== 'all' ? curRole : `${RolesEnum.ADMIN}, ${RolesEnum.MANAGER}`,
            });
          }}
          defaultValue={'all'}
        >
          <Select.OptGroup label={'Роль пользователя'} />
          <Select.Option value={'all'}>Все</Select.Option>
          {Object.values(RolesEnum)
            .filter(role => role !== RolesEnum.PUBLIC && role !== RolesEnum.AGENT)
            .map(role => (
              <Select.Option key={role}>{getUserRoleName(role)}</Select.Option>
            ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.VALIDITY_STATUS]: (
      <Form.Item label={'Статус действия'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          onChange={validityStatus =>
            setParams({
              ...params,
              page: 0,
              validityStatus: validityStatus,
            })
          }
          value={params.validityStatus}
          allowClear
        >
          <Select.OptGroup label={'Статус действия'} />
          {Object.values(ContractValidityStatusesEnum).map(validityStatus => (
            <Select.Option key={validityStatus}>{getContractValidityStatusName(validityStatus)}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.WITH_REQUEST]: (
      <Form.Item label={'Акты в заявках'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          onChange={withRequests =>
            setParams({
              ...params,
              page: 0,
              withRequests,
            })
          }
          value={params.withRequests}
          allowClear
        >
          <Select.OptGroup label={'Акты в заявках'} />
          <Select.Option value={false}>Только акты</Select.Option>
          <Select.Option value={true}>Акты с заявками</Select.Option>
        </Select>
      </Form.Item>
    ),
    [TableFiltersItemsEnum.PAYMENT_STATUS]: (
      <Form.Item label={'Статус оплаты'} labelCol={itemLabelCol} style={formItemsStyles}>
        <Select
          allowClear
          onChange={status =>
            setParams({
              ...params,
              page: 0,
              status: status,
            })
          }
          value={params.status}
        >
          <Select.OptGroup label={'Статус оплаты'} />
          {Object.values(ActStatus).map(status => (
            <Select.Option key={status}>{getActsStatusName(status)}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    ),
  };

  const renderRows = rows.map((row, index) => {
    if (index + 1 <= visibleRows || isMoreFilters) {
      return (
        <Row key={FILTER_ROW_KEY_PREFIX + index} gutter={[20, 20]} align={'bottom'}>
          {row.map((item, colIndex) => (
            <Col key={FILTER_COL_KEY_PREFIX + colIndex} {...colResponsiveConfig[columns]}>
              {fieldsComponents[item]}
            </Col>
          ))}
        </Row>
      );
    }
    return null;
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '20px' }}>
      {renderRows}
      <Row gutter={[20, 20]}>
        {visibleRows < rows.length && (
          <Col>
            <Button onClick={() => setIsMoreFilters(!isMoreFilters)}>
              {!isMoreFilters ? 'Другие ' : 'Скрыть '}
              фильтры
            </Button>
          </Col>
        )}
        {footer}
      </Row>
    </div>
  );
};

export default TableFilters;
