import { FC, useState } from 'react';
import CustomTable from '../UI/Table';
import { ISearchActHistoriesQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { RU_DATE, RU_DATE_TIME } from '../../core/constants/dateFormatting';
import { getActsStatusName, getFullNameUser, getPosTableNum } from '../../core/utils';
import { useGetActHistoriesQuery } from '../../store/services/acts';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';

interface IActsHistoriesTable {
  id: string | undefined;
}

const ActsHistoriesTable: FC<IActsHistoriesTable> = ({ id }) => {
  const [searchAndPaginationParams, setSearchAndPaginationParams] = useState<ISearchActHistoriesQuery>({
    actId: id,
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const { data } = useGetActHistoriesQuery(searchAndPaginationParams);
  const paginationCurrentPage = searchAndPaginationParams.page ? searchAndPaginationParams.page + 1 : 1;

  const actHistories = data?.data.map((actHistory, index) => ({
    ...actHistory,
    posNum: getPosTableNum(
      index,
      searchAndPaginationParams.page || DEFAULT_PAGE,
      searchAndPaginationParams.size || DEFAULT_PAGE_SIZE
    ),
    changedBy: getFullNameUser(actHistory.creator),
    key: actHistory.id,
  }));

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Обновил/а',
      dataIndex: 'changedBy',
      key: 'changedBy',
    },
    {
      title: 'Дата обновления',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: dateAndTime => dayjs(dateAndTime).format(RU_DATE_TIME),
    },
    {
      title: 'Номер',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Статус оплаты',
      dataIndex: 'status',
      key: 'status',
      render: status => getActsStatusName(status),
    },
    {
      title: 'Дата оплаты',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
      render: date => {
        if (date) {
          return dayjs(date).format(RU_DATE);
        }
      },
    },
  ];
  const handlePaginationChange = (page: number, size: number) => {
    setSearchAndPaginationParams({
      ...searchAndPaginationParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      <CustomTable
        tableKey={'ActsHistoriesTable'}
        dataSource={actHistories}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: searchAndPaginationParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
    </>
  );
};

export default ActsHistoriesTable;
