import {
  ActStatus,
  CertificateTypeEnum,
  ContractStatusesEnum,
  ContractValidityStatusesEnum,
  FixedActionTypesEnum,
  RolesEnum,
  TemplateList,
  TypeLessonGroupEnum,
} from '../types/enums';

export const documentTypeTranslations: Record<TemplateList | string, string> = {
  [TemplateList.CONTRACT]: 'Договор',
  [TemplateList.ACT]: 'Акт',
  [TemplateList.REQUEST]: 'Заявка',
  [TemplateList.DEAL]: 'Доп. соглашение',
};

export const userRoleTranslations: Record<RolesEnum | string, string> = {
  [RolesEnum.ADMIN]: 'Администратор',
  [RolesEnum.MANAGER]: 'Куратор',
  [RolesEnum.AGENT]: 'Агент',
  [RolesEnum.PUBLIC]: '',
};

export const certificateTypeTranslations: Record<CertificateTypeEnum | string, string> = {
  [CertificateTypeEnum.INCOME]: 'Расчет по налогу',
  [CertificateTypeEnum.REGISTRATION]: 'Постановка на учет',
};

export const actsStatusTranslations: Record<ActStatus | string, string> = {
  [ActStatus.PAID]: 'Оплачено',
  [ActStatus.NOT_PAID]: 'Не оплачено',
};

export const contractValidityStatusTranslations: Record<ContractValidityStatusesEnum | string, string> = {
  [ContractValidityStatusesEnum.VALID]: 'Действует',
  [ContractValidityStatusesEnum.TERMINATED]: 'Расторгнут',
  [ContractValidityStatusesEnum.COMPETED]: 'Завершен',
};

export const contractStatusTranslations: Record<ContractStatusesEnum | string, string> = {
  [ContractStatusesEnum.CREATED]: 'Создан',
  [ContractStatusesEnum.SENT]: 'Отправлен',
  [ContractStatusesEnum.SIGNED]: 'Подписан',
};

export const fixedActionTypesTranslations: Record<string, string> = {
  [FixedActionTypesEnum.COMMON]: 'Общий тип',
  [FixedActionTypesEnum.INNO_HOMEWORKS_COUNT]: 'Кол-во дз с Иннополиса',
  [FixedActionTypesEnum.INNO_LESSONS_HOURS]: 'Кол-во часов лекций Иннополиса',
};

export const errorTranslations: Record<string, string> = {
  "File isn't pdf file": 'Формат файла должен быть PDF',
  "Uploaded file isn't PDF": 'Формат файла должен быть PDF',
  'Incorrect Date in Certificate': 'Дата в справке должна соответствовать сегодняшней дате',
  'Access denied for Banned User': 'Пользователь заблокирован',
  'Incorrect login or password': 'Неверный логин или пароль',
  "Can't change Contracts params for all Acts": 'Невозможно изменить параметры контрактов для всех актов',
  'Act with payment date is after today exist': 'Выбран/ы акт/ы с датой платежа после сегодняшнего дня',
  "Can't change Deals params for all Acts": 'Невозможно изменить параметры доп. соглашений для всех актов',
  'Incorrect FromDate or ToDate for Contract': 'Некорректно указана дата договора',
  'Incorrect FromDate or ToDate for Deal': 'Некорректно указана дата доп.соглашения',
  'Contract fixed action type is Common': 'Тип фиксированного действия контракта — общий.',
  'Contract has requests': 'Для выбранного договора формирование акта доступно только через заявку',
  "File name in Form is blank, null or extension isn't docx": 'Имя файла в форме пустое, или расширение не docx.',
  "Manager isn't Responsible user of Contract": 'Не удалось отредактировать. Вы не являетесь ответственным!',
  "Manager can't update user with id":
    'Не удалось отредактировать данного контрагента, так как он был создан другим пользователем!',
};

export const lessonGroupTypeTranslations: Record<TypeLessonGroupEnum, string> = {
  [TypeLessonGroupEnum.ALL]: 'Все',
  [TypeLessonGroupEnum.ONLINE]: 'Онлайн',
  [TypeLessonGroupEnum.OFFLINE]: 'Офлайн',
};
