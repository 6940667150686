import { useAppSelector } from '../../core/hooks/redux';
import { Key, useEffect, useState } from 'react';
import { ISearchDealsPageQuery } from '../../core/types/query';
import { toast } from 'react-toastify';
import { ColumnsType } from 'antd/lib/table';
import { IContract, IDeal, IUser } from '../../core/types/entities';
import { Button, Col, Dropdown, MenuProps, Modal, Row } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { RolesEnum, TableFiltersItemsEnum, TemplateList } from '../../core/types/enums';
import { RU_DATE } from '../../core/constants/dateFormatting';
import CustomTable from '../UI/Table';
import {
  useGetDealsQuery,
  useRegenerateDealDocumentsMutation,
  useRemoveDealMutation,
} from '../../store/services/deals';
import DealFormModal from '../Modals/DealFormModal';
import dayjs from 'dayjs';
import { getContractStatusName, getFullNameUser } from '../../core/utils';
import { Link } from 'react-router-dom';
import FileTypeButtonGroup from '../FileTypeButtonGroup';
import DocsStatusModal from '../Modals/DocsStatusModal';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import TableFilters from '../TableFilters';

const { confirm } = Modal;
const DealsTable = () => {
  const { role } = useAppSelector(state => state.authReducer);
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchDealsPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isMassSelect, setIsMassSelect] = useState(false);
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const deals = useGetDealsQuery(paginationAndSearchParams);
  const [removeDeal, removeDealStates] = useRemoveDealMutation();
  const [regenerateDealDocuments, regenerateDealDocumentsStates] = useRegenerateDealDocumentsMutation();
  const [selectedDeal, setSelectedDeal] = useState<IDeal | undefined>(undefined);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const dealsData = deals.data?.data?.map(deal => ({
    ...deal,
    key: deal.id,
  }));

  useEffect(() => {
    if (removeDealStates.isSuccess) {
      toast.success('Доп. соглашение успешно удалено');
    }
    if (removeDealStates.isError) {
      toast.error('Возникла проблема при удалении доп. соглашения');
    }
  }, [removeDealStates.isError, removeDealStates.isSuccess]);

  useEffect(() => {
    if (regenerateDealDocumentsStates.isSuccess) {
      toast.success('Документы доп. соглашения успешно перегенерированы');
    }
    if (regenerateDealDocumentsStates.isError) {
      toast.error('Возникла проблема при перегенерировании документов доп. соглашения');
    }
  }, [regenerateDealDocumentsStates.isSuccess, regenerateDealDocumentsStates.isError]);

  const columns: ColumnsType = [
    {
      title: 'Соглашение №',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Дата доп. Соглашения',
      dataIndex: 'fromDate',
      key: 'fromDate',
      align: 'center',
      render: date => {
        return dayjs(date).format(RU_DATE);
      },
    },
    {
      title: 'Срок действия',
      dataIndex: 'toDate',
      key: 'toDate',
      align: 'center',
      render: date => {
        if (date) {
          return dayjs(date).format(RU_DATE);
        }
      },
    },
    {
      title: 'Статус',
      dataIndex: 'documentStatus',
      key: 'documentStatus',
      render: status => {
        return getContractStatusName(status);
      },
    },
    {
      title: 'Договор',
      dataIndex: 'contract',
      key: 'contract',
      render: data => {
        const contract = data as IContract;
        if (contract) {
          return <Link to={`/contracts/${contract?.id}`}>№ {contract?.number}</Link>;
        }
      },
    },
    {
      title: 'Контрагент',
      dataIndex: ['contract', 'agent'],
      key: 'agent',
      render: (data: IUser) => <Link to={`/agents/${data?.id}`}>{getFullNameUser(data)}</Link>,
    },
    {
      title: 'Файлы соглашения',
      key: 'files',
      render: (_, data) => {
        const deal = data as IDeal;
        return <FileTypeButtonGroup items={[deal?.pdfFile, deal?.wordFile]} />;
      },
    },
    {
      title: 'Подписанное соглашение',
      key: 'signedDeal',
      render: (_, data) => {
        const deal = data as IDeal;
        return <FileTypeButtonGroup items={[deal?.signedFile]} />;
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, data: unknown) => {
        const deal = data as IDeal;
        return (
          <Dropdown menu={{ items: renderDropDownItems(deal.id) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const renderDropDownItems = (id: number) => {
    const deal = deals?.data?.data?.find(deal => deal.id === id);
    const items: MenuProps['items'] = [
      {
        key: 'drop-down-item-1',
        onClick: () => {
          setSelectedDeal(deal);
          setIsFormModalOpen(true);
        },
        label: 'Редактировать',
      },
    ];
    if (role === RolesEnum.ADMIN.toString()) {
      items.push(
        {
          key: 'drop-down-item-2',
          onClick: () => {
            window.open(`/deals/histories/${id}`, '_blank');
          },
          label: 'История изменений',
        },
        {
          key: 'drop-down-item-3',
          onClick: () => {
            confirm({
              title: 'Вы точно хотите перегенерировать документы доп. соглашения?',
              centered: true,
              cancelText: 'Отмена',
              okText: 'Подтвердить',
              onOk() {
                regenerateDealDocuments(id);
              },
            });
          },
          label: 'Перегенерировать документы доп. соглашения',
        },
        {
          key: 'drop-down-item-4',
          onClick: () => {
            confirm({
              title: 'Вы точно хотите удалить доп. соглашение?',
              centered: true,
              cancelText: 'Отмена',
              okText: 'Подтвердить',
              onOk() {
                removeDeal(id);
              },
            });
          },
          label: 'Удалить',
          danger: true,
        }
      );
    }
    return items;
  };

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  const onSelectAll = (selected: boolean, selectedRows: Key[], changeRows: IDeal[]) => {
    if (!isMassSelect && selectedRowKeys.length === deals.data?.data?.length) {
      return setIsMassSelect(true);
    }
    if (selected) {
      const newSelectedRowKeys = changeRows.map((row: IDeal) => row.id);
      setSelectedRowKeys(prevState => [...prevState, ...newSelectedRowKeys]);
    } else {
      setSelectedRowKeys([]);
    }

    setIsMassSelect(selected);
  };

  const onSelect = (deal: IDeal) => {
    const isSelected = selectedRowKeys.includes(deal.id);
    if (isMassSelect && selectedRowKeys.length === deals.data?.data?.length) {
      setIsMassSelect(false);
    }
    if (isSelected) {
      return setSelectedRowKeys(selectedRowKeys.filter(key => key !== deal.id));
    }
    setSelectedRowKeys([...selectedRowKeys, deal.id]);
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect,
    onSelectAll,
  };

  return (
    <>
      <Row gutter={[20, 0]}>
        <Col xs={24} style={{ marginBottom: '20px' }}>
          <Button type={'primary'} onClick={() => setIsFormModalOpen(true)}>
            Создать доп. соглашение
          </Button>
        </Col>
      </Row>
      <TableFilters
        fields={[
          TableFiltersItemsEnum.FIND_BY_AGENT_FIO,
          TableFiltersItemsEnum.CONTRACT,
          TableFiltersItemsEnum.ACT,
          TableFiltersItemsEnum.FROM_DATE,
        ]}
        params={paginationAndSearchParams}
        setParams={setPaginationAndSearchParams}
        footer={
          <>
            <Col>
              <Button
                onClick={() => setIsStatusModalOpen(true)}
                type={'primary'}
                disabled={selectedRowKeys.length === 0}
              >
                Изменить статус
              </Button>
            </Col>
            {isMassSelect && (
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Выбрано {deals.data?.total}
              </Col>
            )}
          </>
        }
      />
      <CustomTable
        tableKey={'DealsTable'}
        dataSource={dealsData}
        columns={columns}
        rowSelection={rowSelection}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: deals.data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <DealFormModal
        open={isFormModalOpen}
        setClose={() => {
          setIsFormModalOpen(false);
          setSelectedDeal(undefined);
        }}
        initialData={selectedDeal}
      />
      <DocsStatusModal
        open={isStatusModalOpen}
        setClose={() => setIsStatusModalOpen(false)}
        ids={selectedRowKeys}
        type={TemplateList.DEAL}
        withParams={isMassSelect}
        queryParamsForm={{
          ...paginationAndSearchParams,
          page: undefined,
          size: undefined,
        }}
      />
    </>
  );
};

export default DealsTable;
