import CustomTable from '../UI/Table';
import { useRef, useState } from 'react';
import { ISearchGroupsPageQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import { Button, Col, Row } from 'antd';
import { IGroup, IHomework } from '../../core/types/entities';
import { getFullNameUser, getPosTableNum } from '../../core/utils';
import { useGetHomeworksQuery } from '../../store/services/homeworks';
import HomeworkUploadModal from '../Modals/HomeworkUploadModal';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import dayjs from 'dayjs';
import { RU_DATE } from '../../core/constants/dateFormatting';
import { Link } from 'react-router-dom';
import { RolesEnum } from '../../core/types/enums';
import { useAppSelector } from '../../core/hooks/redux';

const HomeworksTable = () => {
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchGroupsPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
    sort: 'createdAt,desc',
  });
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const { data } = useGetHomeworksQuery(paginationAndSearchParams);
  const [showControlGroup, setShowControlGroup] = useState(false);
  const selectedGroup = useRef<IGroup | undefined>();
  const { role } = useAppSelector(state => state.authReducer);

  const homeWorks = data?.data.map((homeWork, index) => ({
    ...homeWork,
    posNum: getPosTableNum(
      index,
      paginationAndSearchParams.page || DEFAULT_PAGE,
      paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
    ),
    key: homeWork.id,
  }));

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Дата загрузки',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: date => (date ? dayjs(date).format(RU_DATE) : ''),
    },
    {
      title: 'Кол-во новых дз',
      dataIndex: 'newCount',
      key: 'newCount',
    },
    {
      title: 'Пропущенные дубли в excel',
      dataIndex: 'skippedExcelCount',
      key: 'skippedExcelCount',
      render: (data, dataRow) => {
        const dataRowType = dataRow as IHomework;
        return <Link to={`/duplicates-inno-homeworks/${dataRowType.id}`}>{data}</Link>;
      },
    },
    {
      title: 'Пропущенные дубли в системе',
      dataIndex: 'skippedDataBaseCount',
      key: 'skippedDataBaseCount',
    },
    {
      title: 'Общее кол-во дз обработанных',
      dataIndex: 'totalCount',
      key: 'totalCount',
    },
    {
      title: 'Загрузил (ФИО)',
      dataIndex: 'creator',
      key: 'creator',
      render: data => getFullNameUser(data),
    },
    {
      title: 'Имя файла',
      dataIndex: 'fileName',
      key: 'fileName',
    },
  ];

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Row gutter={[20, 0]}>
          <Col xs={24} style={{ marginBottom: '20px' }}>
            <Button
              type={'primary'}
              onClick={() => {
                selectedGroup.current = undefined;
                setShowControlGroup(true);
              }}
            >
              Загрузить файл
            </Button>
          </Col>
        </Row>
      )}
      <CustomTable
        tableKey={'HomeWorksTable'}
        dataSource={homeWorks ?? []}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <HomeworkUploadModal setShowModal={setShowControlGroup} showModal={showControlGroup} />
    </>
  );
};

export default HomeworksTable;
