import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import './styles.scss';
import FileTypeButtonGroup from '../FileTypeButtonGroup';
import { useGetContractQuery, useUpdateContractMutation } from '../../store/services/contracts/contracts';
import Title from 'antd/es/typography/Title';
import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useGetContractsTypesQuery } from '../../store/services/contracts/contracts-types';
import { useGetProjectsQuery } from '../../store/services/contracts/projects';
import { useGetProjectsDirectionsQuery } from '../../store/services/contracts/projects-directions';
import { useGetProjectSpecificationsQuery } from '../../store/services/contracts/projects-specifications';
import { useGetAgentFunctionsQuery } from '../../store/services/contracts/agent-functions';
import { RU_DATE, RU_DATE_TIME } from '../../core/constants/dateFormatting';
import {
  customFilterOption,
  formatFileName,
  getExceptionMessage,
  getFullNameUser,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
  isFetchBaseQueryError,
  validateTextForInvalidCharacters,
} from '../../core/utils';
import { useGetUsersQuery } from '../../store/services/users';
import { useGetActsQuery } from '../../store/services/acts';
import { IAct, IDeal, IFixedActions, IRequest } from '../../core/types/entities';
import { useGetRequestsQuery } from '../../store/services/requests';
import { useGetTaxStatusesQuery } from '../../store/services/contracts/tax-status';
import { useGetSignSystemsQuery } from '../../store/services/contracts/sign-systems';
import { IException } from '../../core/types/response';
import DatePickerBase from '../UI/DatePickerBase';
import { useGetDealsQuery } from '../../store/services/deals';
import { fixedActionTypesTranslations } from '../../core/constants/translations';

interface IContractDetails {
  id: string | undefined;
}

const { Text } = Typography;
const ContractDetails: FC<IContractDetails> = ({ id }) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const contract = useGetContractQuery(id).data;
  const [currentProjectId, setCurrentProjectId] = useState(contract?.project.id);
  const contractsTypes = useGetContractsTypesQuery();
  const projects = useGetProjectsQuery();
  const projectsDirection = useGetProjectsDirectionsQuery(currentProjectId);
  const projectSpecifications = useGetProjectSpecificationsQuery(currentProjectId);
  const agents = useGetUsersQuery({
    roles: RolesEnum.AGENT,
    size: 999,
  });
  const responsibleUsers = useGetUsersQuery({
    roles: `${RolesEnum.ADMIN}, ${RolesEnum.MANAGER}`,
    size: 999,
  });

  const agentFunctions = useGetAgentFunctionsQuery();
  const taxStatuses = useGetTaxStatusesQuery();
  const signSystems = useGetSignSystemsQuery();
  const [updateContract, updateContractStates] = useUpdateContractMutation();
  const { role } = useAppSelector(state => state.authReducer);
  const canUpdate = role === RolesEnum.ADMIN.toString() || role === RolesEnum.MANAGER.toString();
  const projectId = form.getFieldsValue().projectId;
  const acts = useGetActsQuery({
    contractId: id,
    size: 999,
  }).data;
  const requests = useGetRequestsQuery({
    contractId: Number(id),
    size: 999,
  }).data;
  const deals = useGetDealsQuery({
    contractId: id,
    size: 999,
  }).data;

  useEffect(() => {
    if (contract) {
      form.setFieldsValue({
        fromDate: contract.fromDate,
        toDate: contract.toDate,
        number: contract.number,
        comments: contract.comments,
        typeId: contract.type.id,
        projectId: contract.project.id,
        projectDirectionId: contract.projectDirection?.id || null,
        projectSpecificationId: contract.projectSpecification?.id || null,
        agentFunctionId: contract.agentFunction.id,
        responsibleUserId: contract.responsibleUser.id || null,
        seniorTeacherId: contract.seniorTeacher?.id || null,
        agentId: contract.agent.id || null,
      });
    }
  }, [contract]);

  useEffect(() => {
    if (projectId && projectId !== contract?.project.id) {
      form.setFieldsValue({
        projectDirectionId: null,
        projectSpecificationId: null,
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (updateContractStates.isSuccess && isEditing) {
      setIsEditing(false);
      toast.success('Договор успешно изменен!');
    }
    if (
      isFetchBaseQueryError(updateContractStates.error) &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updateContractStates.error.data.exceptionMessage === "Manager isn't Responsible user of Contract"
    ) {
      toast.error('Не удалось отредактировать договор. Вы не являетесь ответственным за данный договор!');
      return;
    }
    if (isFetchBaseQueryError(updateContractStates.error)) {
      toast.error(
        getExceptionMessage(updateContractStates.error.data as IException) || 'Произошла ошибка при изменении договора'
      );
    }
  }, [updateContractStates.isSuccess, updateContractStates.isError]);

  const fixedActionFormsColumns: ColumnsType = [
    {
      title: 'Услуга',
      align: 'center',
      dataIndex: ['action', 'name'],
      key: 'fixedActionName',
    },
    {
      title: 'Единица измерения',
      align: 'center',
      dataIndex: ['unit', 'name'],
      key: 'fixedActionUnitName',
    },
    {
      title: 'Ставка',
      align: 'center',
      dataIndex: 'rate',
      key: 'fixedActionRate',
    },
    {
      title: 'Тип',
      align: 'center',
      dataIndex: 'type',
      key: 'fixedActionType',
      render: (_, data) => {
        const fixedActionData = data as IFixedActions;
        return <div>{fixedActionTypesTranslations[fixedActionData.type]}</div>;
      },
    },
  ];

  const dealsColumns: ColumnsType = [
    {
      title: '№ Доп. соглашения',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
    },
    {
      title: 'Дата',
      dataIndex: 'fromDate',
      key: 'fromDate',
      align: 'center',
      render: date => dayjs(date).format(RU_DATE),
    },
    {
      title: 'Скачать',
      dataIndex: 'actions',
      key: 'dealsFiles',
      align: 'center',
      width: 200,
      render: (_, data) => {
        const deal = data as IDeal;
        return (
          <div>
            <FileTypeButtonGroup items={deal?.wordFile || deal?.pdfFile ? [deal?.pdfFile, deal?.wordFile] : []} />
          </div>
        );
      },
    },
    {
      title: 'Подписанное доп. соглашение',
      key: 'signedAct',
      align: 'center',
      width: 100,
      render: (_, data) => {
        const act = data as IAct;
        return (
          <div>
            <FileTypeButtonGroup items={(act?.signedFile && [act.signedFile]) || []} />
          </div>
        );
      },
    },
  ];

  const actsColumns: ColumnsType = [
    {
      title: '№ Акта',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
    },
    {
      title: 'Акт от',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: date => dayjs(date).format(RU_DATE),
    },
    {
      title: 'Скачать акт',
      dataIndex: 'actions',
      key: 'actsFiles',
      align: 'center',
      width: 200,
      render: (_, data) => {
        const act = data as IAct;
        const files =
          act?.wordFile || act?.pdfFile
            ? [act?.pdfFile, act?.wordFile].map(file => ({
                ...file,
                customFileName: formatFileName(file?.originalFileName, act?.agent?.lastName),
              }))
            : [];
        return (
          <div>
            <FileTypeButtonGroup items={files} />
          </div>
        );
      },
    },
    {
      title: 'Подписанный акт',
      key: 'signedAct',
      align: 'center',
      width: 100,
      render: (_, data) => {
        const act = data as IAct;
        return (
          <div>
            <FileTypeButtonGroup items={(act?.signedFile && [act.signedFile]) || []} />
          </div>
        );
      },
    },
  ];

  const requestsColumns: ColumnsType = [
    {
      title: '№ Заявки',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
    },
    {
      title: 'Заявка от',
      dataIndex: 'fromDate',
      key: 'fromDate',
      align: 'center',
      render: date => dayjs(date).format(RU_DATE),
    },
    {
      title: 'Файлы',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, data) => {
        const request = data as IRequest;
        return (
          <div>
            <FileTypeButtonGroup
              items={request?.wordFile || request?.pdfFile ? [request?.pdfFile, request?.wordFile] : []}
            />
          </div>
        );
      },
    },
  ];

  const handleSubmitForm = () => {
    const contractUpdateForm = form.getFieldsValue();

    if (id && contractUpdateForm) {
      updateContract([id, contractUpdateForm]);
    }
  };

  return (
    <>
      <Form form={form} onFinish={handleSubmitForm}>
        <ul className={'contractDetails'}>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Дата создания:</Text>
            <Text>{dayjs(contract?.createdAt).format(RU_DATE_TIME)}</Text>
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Дата:</Text>
            {isEditing && canUpdate ? (
              <>
                <Text>от</Text>
                <Form.Item
                  style={{ margin: 0 }}
                  name={'fromDate'}
                  getValueFromEvent={getValueFormDatePicker}
                  getValueProps={getValueFormDatePickerProps}
                >
                  <DatePickerBase />
                </Form.Item>
                <Text>до</Text>
                <Form.Item
                  style={{ margin: 0 }}
                  name={'toDate'}
                  getValueFromEvent={getValueFormDatePicker}
                  getValueProps={getValueFormDatePickerProps}
                >
                  <DatePickerBase />
                </Form.Item>
              </>
            ) : (
              <Text>
                от {dayjs(contract?.fromDate).format(RU_DATE)}
                {contract?.toDate && ` до ${dayjs(contract?.toDate).format(RU_DATE)}`}
              </Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Контрагент:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                key={'agentId'}
                name={'agentId'}
                style={{
                  margin: 0,
                  width: '100%',
                  maxWidth: '300px',
                }}
              >
                <Select allowClear showSearch filterOption={customFilterOption}>
                  <Select.OptGroup label={'Контрагент'} />
                  {agents.data?.data?.map(agent => (
                    <Select.Option key={agent.id} value={agent.id}>
                      {getFullNameUser(agent)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{getFullNameUser(contract?.agent)}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Шаблон:</Text>
            <Text>
              {contract?.patternVersion ? (
                <>
                  {contract?.patternVersion.pattern.name} Версия № {contract?.patternVersion.version}
                </>
              ) : (
                '-'
              )}
            </Text>
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Документ:</Text>
            <div>
              <FileTypeButtonGroup
                items={contract?.wordFile || contract?.pdfFile ? [contract?.pdfFile, contract?.wordFile] : []}
              />
            </div>
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Подписанный документ:</Text>
            <div>
              <FileTypeButtonGroup items={(contract?.signedFile && [contract.signedFile]) || []} />
            </div>
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Тип документа:</Text>
            {isEditing && canUpdate ? (
              <Form.Item key={'typeId'} name={'typeId'} style={{ margin: 0 }}>
                <Select allowClear>
                  <Select.OptGroup label={'Тип договора'} />
                  {contractsTypes.data?.map(contractsType => (
                    <Select.Option
                      key={contractsType.id}
                      value={contractsType.id}
                      style={{
                        margin: 0,
                        fontSize: '12px',
                      }}
                    >
                      {contractsType.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{contract?.type.name}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Номер документа:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                style={{ margin: 0 }}
                name={'number'}
                rules={[
                  {
                    validator: validateTextForInvalidCharacters,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : (
              <Text>{contract?.number}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Проект:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                key={'projectId'}
                name={'projectId'}
                style={{
                  margin: 0,
                  width: '200px',
                }}
              >
                <Select onChange={projectId => setCurrentProjectId(projectId)} allowClear>
                  <Select.OptGroup label={'Проект'} />
                  {projects.data?.map(project => (
                    <Select.Option key={project.id} value={project.id}>
                      {project.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{contract?.project?.name}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Направление:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                key={'projectDirectionId'}
                name={'projectDirectionId'}
                style={{
                  margin: 0,
                  width: '200px',
                }}
              >
                <Select disabled={projectsDirection?.data?.length === 0} allowClear>
                  <Select.OptGroup label={'Направление проекта'} />

                  {projectsDirection.data?.map(projectDirection => (
                    <Select.Option key={projectDirection.id} value={projectDirection.id}>
                      {projectDirection.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{contract?.projectDirection?.name || '-'}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Спецификация:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                key={'projectSpecificationId'}
                name={'projectSpecificationId'}
                style={{
                  margin: 0,
                  width: '100%',
                  maxWidth: '300px',
                }}
              >
                <Select disabled={projectSpecifications?.data?.length === 0} allowClear>
                  <Select.OptGroup label={'Cпецификация проекта'} />

                  {projectSpecifications.data?.map(projectSpecification => (
                    <Select.Option key={projectSpecification.id} value={projectSpecification.id}>
                      {projectSpecification.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{contract?.projectSpecification?.name || '-'}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Старший преподаватель:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                key={'seniorTeacherId'}
                name={'seniorTeacherId'}
                style={{
                  margin: 0,
                  width: '100%',
                  maxWidth: '300px',
                }}
                normalize={value => (value !== undefined ? value : null)}
              >
                <Select allowClear showSearch filterOption={customFilterOption}>
                  <Select.OptGroup label={'Старший преподаватель'} />
                  {agents.data?.data?.map(agent => (
                    <Select.Option key={agent.id} value={agent.id}>
                      {getFullNameUser(agent)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{getFullNameUser(contract?.seniorTeacher) || '-'}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Функция:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                key={'agentFunctionId'}
                name={'agentFunctionId'}
                style={{
                  margin: 0,
                  width: '200px',
                }}
              >
                <Select allowClear>
                  <Select.OptGroup label={'Функции контргента'} />
                  {agentFunctions.data?.map(agentFunction => (
                    <Select.Option key={agentFunction.id} value={agentFunction.id}>
                      {agentFunction.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{contract?.agentFunction.name}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Ответственный:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                key={'responsibleUserId'}
                name={'responsibleUserId'}
                style={{
                  margin: 0,
                  width: '100%',
                  maxWidth: '300px',
                }}
              >
                <Select allowClear showSearch filterOption={customFilterOption}>
                  <Select.OptGroup label={'Ответственный'} />
                  {responsibleUsers.data?.data?.map(responsibleUser => (
                    <Select.Option key={responsibleUser.id} value={responsibleUser.id}>
                      {getFullNameUser(responsibleUser)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{getFullNameUser(contract?.responsibleUser)}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Налоговый статус:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                key={'taxStatusId'}
                name={'taxStatusId'}
                initialValue={contract?.taxStatus.id}
                style={{
                  margin: 0,
                  width: '100%',
                  maxWidth: '200px',
                }}
              >
                <Select allowClear>
                  <Select.OptGroup label={'Налоговый статус'} />

                  {taxStatuses.data?.map(taxStatus => (
                    <Select.Option key={taxStatus.id} value={taxStatus.id}>
                      {taxStatus.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{contract?.taxStatus.name}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Тип подписи:</Text>
            {isEditing && canUpdate ? (
              <Form.Item
                key={'signSystemId'}
                name={'signSystemId'}
                initialValue={contract?.signSystem.id}
                style={{
                  margin: 0,
                  width: '100%',
                  maxWidth: '200px',
                }}
              >
                <Select allowClear>
                  <Select.OptGroup label={'Тип подписи'} />

                  {signSystems.data?.map(signSystem => (
                    <Select.Option key={signSystem.id} value={signSystem.id}>
                      {signSystem.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Text>{contract?.signSystem.name}</Text>
            )}
          </li>
          <li className={'contractDetails__item'}>
            <Text className={'contractDetails__label'}>Комментарии:</Text>
            {isEditing && canUpdate ? (
              <Form.Item style={{ margin: 0 }} name={'comments'}>
                <Input />
              </Form.Item>
            ) : (
              <Text>{contract?.comments || '-'}</Text>
            )}
          </li>
          {canUpdate && (
            <Form.Item>
              <Button
                onClick={() => setIsEditing(!isEditing)}
                type={'primary'}
                style={{
                  width: 'fit-content',
                  marginRight: '20px',
                }}
              >
                {!isEditing ? 'Изменить' : 'Отмена'}
              </Button>
              {isEditing && (
                <Button type="primary" htmlType="submit" style={{ width: 'fit-content' }}>
                  Сохранить
                </Button>
              )}
            </Form.Item>
          )}
        </ul>
      </Form>

      <Row
        gutter={[20, 60]}
        style={{
          marginTop: '40px',
          marginBottom: '60px',
          maxWidth: '730px',
        }}
      >
        {deals?.data.length !== 0 && (
          <Col span={24}>
            <Title level={3}>Доп. соглашения:</Title>
            <CustomTable
              tableKey={'ContractDetailsDealsTable'}
              dataSource={deals?.data}
              columns={dealsColumns}
              rowKey={'id'}
              pagination={false}
            />
          </Col>
        )}
        {acts?.data.length !== 0 && (
          <Col span={24}>
            <Title level={3}>Акты:</Title>
            <CustomTable
              tableKey={'ContractDetailsActsTable'}
              dataSource={acts?.data}
              columns={actsColumns}
              rowKey={'id'}
              pagination={false}
            />
          </Col>
        )}
        {requests?.data.length !== 0 && (
          <Col span={24}>
            <Title level={3}>Заявки:</Title>
            <CustomTable
              tableKey={'ContractDetailsRequestsTable'}
              dataSource={requests?.data}
              columns={requestsColumns}
              rowKey={'id'}
              pagination={false}
            />
          </Col>
        )}
        <Col span={24}>
          <Title level={3}>Информация по услугам:</Title>
          <CustomTable
            tableKey={'ContractDetailsFixedActionsTable'}
            dataSource={contract?.fixedActions}
            columns={fixedActionFormsColumns}
            rowKey={'id'}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default ContractDetails;
