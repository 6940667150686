import { FC, useEffect } from 'react';
import { IAuthFormData } from '../../../core/types/forms';
import FormBuilder, { IField, IFormBuilderProps } from '../../FormBuilder';
import './styles.scss';
import { useLoginMutation } from '../../../store/services/auth';
import { setUser } from '../../../store/slices/auth/AuthSlice';
import { useAppDispatch } from '../../../core/hooks/redux';
import { toast } from 'react-toastify';
import { getExceptionMessage, isFetchBaseQueryError } from '../../../core/utils';
import { IException } from '../../../core/types/response';

const LoginForm: FC = () => {
  const [login, { isError, error }] = useLoginMutation();
  const dispatch = useAppDispatch();
  const onFinish = async (values: IAuthFormData) => {
    await login(values);
    dispatch(setUser());
  };

  useEffect(() => {
    if (isFetchBaseQueryError(error)) {
      toast.error(getExceptionMessage(error.data as IException) || 'Произошла ошибка, попробуйте позже');
    }
  }, [isError]);

  const fields: IField[] = [
    {
      name: 'login',
      label: 'Логин',
      type: 'text',
      required: true,
    },
    {
      name: 'password',
      label: 'Пароль',
      type: 'password',
      required: true,
    },
  ];

  const config: IFormBuilderProps<IAuthFormData> = {
    className: 'loginForm',
    labelCol: {
      span: 4,
      style: { textAlign: 'start' },
    },
    fields: fields,
    onSubmit: onFinish,
    submitButtonProps: {
      text: 'Войти',
      className: 'loginForm__btnWrp',
    },
    initialValues: {
      login: '',
      password: '',
    },
  };

  return <FormBuilder {...config} />;
};

export default LoginForm;
