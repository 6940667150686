import { Button, Form, Modal, Upload } from 'antd';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSaveHomeworkMutation } from '../../store/services/homeworks';
import { UploadOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

interface LessonGroupControlModalProps {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

interface Error {
  exception: string;
}

const HomeworkUploadModal = ({ showModal, setShowModal }: LessonGroupControlModalProps) => {
  const [form] = Form.useForm();
  const [saveFile, { isLoading, isSuccess, isError, error }] = useSaveHomeworkMutation();

  const handleSubmitForm = (data: { file: [{ originFileObj: File }] }) => {
    const formData = new FormData();
    formData.append('file', data.file[0].originFileObj);
    saveFile(formData);
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (error && (error as FetchBaseQueryError).status === 500) {
      toast.error('Неправильный формат таблицы!');
      return;
    }

    if (error && ((error as FetchBaseQueryError).data as Error)?.exception === 'HomeWorkTeacherNotFoundException') {
      toast.error('Не все контрагенты есть в системе!');
      return;
    }

    if (error && (error as FetchBaseQueryError).status === 403) {
      toast.error('Север отказал в доступе! Повторите попытку');
      return;
    }

    if (isError) {
      toast.error('Произошла ошибка, повторите попытку');
      return;
    }

    if (isSuccess) {
      toast.success('Данные обрабатываются. Пожалуйста, подождите!');
      setShowModal(false);
      form.resetFields();
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      open={showModal}
      centered
      title={'Загрузить файл'}
      okText={'Сохранить'}
      onCancel={() => setShowModal(false)}
      onOk={() => form.submit()}
      confirmLoading={isLoading}
    >
      <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
        <Form.Item label={'Файл домашних заданий'} name={'file'} valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload
            maxCount={1}
            customRequest={options => {
              const { onSuccess } = options;
              if (onSuccess) {
                onSuccess(options.file, undefined);
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Прикрепить файл</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HomeworkUploadModal;
