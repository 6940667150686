import './style.scss';
import Title from 'antd/es/typography/Title';
import { useGetAgentInfosQuery } from '../../store/services/users/agents-infos';
import { useAppSelector } from '../../core/hooks/redux';
import { getFullNameUser } from '../../core/utils';
import AgentProfileDocTable from '../Tables/AgentProfileDocTable';
import { Button, Col, Row } from 'antd';
import AgentProfileReceiptsTable from '../Tables/AgentProfileReceiptsTable';
import { RolesEnum } from '../../core/types/enums';
import { FC } from 'react';
import TelegramLinks from '../UI/TelegramLinks';
import Welcome from '../UI/Welcome';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { RU_DATE } from '../../core/constants/dateFormatting';

interface IAgentProfile {
  agentId?: string;
}

const AgentProfile: FC<IAgentProfile> = ({ agentId }) => {
  const { user, role } = useAppSelector(state => state.authReducer);
  const { data } = useGetAgentInfosQuery(getId(), { skip: !getId() });
  const navigate = useNavigate();

  function getId() {
    if (role === RolesEnum.AGENT.toString()) {
      return user?.id;
    }
    if (agentId) {
      return Number(agentId);
    }
    return;
  }

  if (!getId()) {
    return (
      <>
        <Welcome />
      </>
    );
  }

  if (data) {
    return (
      <>
        <Row
          gutter={[20, 40]}
          style={{
            maxWidth: '744px',
            marginBottom: '60px',
          }}
        >
          <Col span={24}>
            <Title>{getFullNameUser(data?.agent)}</Title>
          </Col>
          <Col span={24}>
            <Title style={{ marginBottom: '30px' }} level={3}>
              Персональные данные
            </Title>
            <ul className={'agentPersonalInfo'}>
              <li className={'agentPersonalInfo__item'}>
                <span>Телефон:</span> {data?.agent.phoneNumber}
              </li>
              <li className={'agentPersonalInfo__item'}>
                <span>Телеграм:</span> <TelegramLinks path={data?.agent.telegram} />
              </li>
              <li className={'agentPersonalInfo__item'}>
                <span>Реквизиты:</span>
                <ul className={'agentRequisites'}>
                  <li className={'agentRequisites__item'}>
                    <span>ФИО:</span> {getFullNameUser(data?.agent)}
                  </li>
                  <li className={'agentRequisites__item'}>
                    <span>Паспорт: </span> серия {data?.passportSeries} №{data?.passportNumber}
                  </li>
                  <li className={'agentRequisites__item'}>
                    <span>Выдан: </span> {data?.passportReleasedBy}, {dayjs(data?.passportReleasedAt).format(RU_DATE)}
                  </li>
                  <li className={'agentRequisites__item'}>
                    <span>ИНН: </span> {data?.inn}
                  </li>
                  <li className={'agentRequisites__item'}>
                    <span>Адрес регистрации: </span> {data?.registrationAddress}
                  </li>
                  {data.residentialAddress && (
                    <li className={'agentRequisites__item'}>
                      <span>Адрес проживания: </span> {data?.residentialAddress}
                    </li>
                  )}
                  <li className={'agentRequisites__item'}>
                    <span>Р/счет: </span> {data?.paymentAccount}
                  </li>
                  {data.ogrnip && (
                    <li className={'agentRequisites__item'}>
                      <span>ОГРНИП: </span> {data?.ogrnip}
                    </li>
                  )}
                  <li className={'agentRequisites__item'}>
                    <span>Банк получателя: </span> {data?.recipientBank}
                  </li>
                  <li className={'agentRequisites__item'}>
                    <span>Корр. счет: </span> {data?.corrAccount}
                  </li>
                  <li className={'agentRequisites__item'}>
                    <span>БИК: </span> {data?.bik}
                  </li>
                  {data.bankInn && (
                    <li className={'agentRequisites__item'}>
                      <span>ИНН банка: </span> {data?.bankInn}
                    </li>
                  )}
                  {data.bankKpp && (
                    <li className={'agentRequisites__item'}>
                      <span>КПП: </span> {data?.bankKpp}
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </Col>
          {(role === RolesEnum.ADMIN || role === RolesEnum.MANAGER) && (
            <Col span={24}>
              <Button type="primary" onClick={() => navigate(`/users/upload-homework-agent/${agentId}`)}>
                Загруженные ДЗ
              </Button>
            </Col>
          )}
          <Col span={24}>
            <Title style={{ marginBottom: '30px' }} level={3}>
              Документы
            </Title>
            <AgentProfileDocTable agentId={agentId} />
          </Col>
          <Col span={24}>
            <Title style={{ marginBottom: '30px' }} level={3}>
              Документы по оплатам:
            </Title>
            <AgentProfileReceiptsTable agentId={agentId} />
          </Col>
        </Row>
      </>
    );
  }
};

export default AgentProfile;
