import { FC, useState } from 'react';
import { useGetUserHistoriesQuery } from '../../store/services/users';
import CustomTable from '../UI/Table';
import { ISearchUsersHistoriesPageQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IUser } from '../../core/types/entities';
import { UserStateEnum } from '../../core/types/enums';
import { Tag } from 'antd';
import { RU_DATE, RU_DATE_TIME } from '../../core/constants/dateFormatting';
import { getFullNameUser, getPosTableNum } from '../../core/utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';

interface IUsersHistoriesTable {
  id: string | undefined;
}

const UsersHistoriesTable: FC<IUsersHistoriesTable> = ({ id }) => {
  const [searchAndPaginationParams, setSearchAndPaginationParams] = useState<ISearchUsersHistoriesPageQuery>({
    userId: id,
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const { data } = useGetUserHistoriesQuery(searchAndPaginationParams);
  const paginationCurrentPage = searchAndPaginationParams.page ? searchAndPaginationParams.page + 1 : 1;

  const userHistories = data?.data.map((userHistory, index) => ({
    ...userHistory,
    posNum: getPosTableNum(
      index,
      searchAndPaginationParams.page || DEFAULT_PAGE,
      searchAndPaginationParams.size || DEFAULT_PAGE_SIZE
    ),
    changedBy: getFullNameUser(userHistory.creator),
    key: userHistory.id,
  }));

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Обновил/а',
      dataIndex: 'changedBy',
      key: 'changedBy',
    },
    {
      title: 'Дата обновления',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: dateAndTime => {
        return dayjs(dateAndTime).format(RU_DATE_TIME);
      },
    },
    {
      title: 'СТАТУС',
      dataIndex: 'state',
      key: 'state',
      render: (state: string, data: unknown) => {
        if (state) {
          const user = data as IUser;
          return (
            <Tag color={state === UserStateEnum.CONFIRMED ? 'green' : 'red'} key={'state' + user.id}>
              {state === UserStateEnum.CONFIRMED ? 'Активный' : 'Заблокирован'}
            </Tag>
          );
        }
      },
    },
    {
      title: 'Имя',
      dataIndex: 'firstName',
      key: 'firstName',
      render: firstName => {
        if (firstName) {
          return firstName;
        }
      },
    },
    {
      title: 'Фамилия',
      dataIndex: 'lastName',
      key: 'lastName',
      render: lastName => {
        if (lastName) {
          return lastName;
        }
      },
    },
    {
      title: 'Отчество',
      dataIndex: 'patronymic',
      key: 'patronymic',
      render: patronymic => {
        if (patronymic) {
          return patronymic;
        }
      },
    },
    {
      title: 'Дата рождения',
      dataIndex: 'birthDate',
      key: 'birthDate',
      align: 'center',
      render: birthDate => {
        if (birthDate) {
          return dayjs(birthDate).format(RU_DATE);
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: email => {
        if (email) {
          return email;
        }
      },
    },
    {
      title: 'Телефон',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: phoneNumber => {
        if (phoneNumber) {
          return phoneNumber;
        }
      },
    },
    {
      title: 'Телеграм',
      dataIndex: 'telegram',
      key: 'telegram',
      render: (path: string) => {
        if (path) {
          if (!path) {
            return 'Не указан';
          }
          if (path.includes('@')) {
            const formattedNick = path.replace('@', '');
            return (
              <a href={`https://t.me/${formattedNick}`} target={'_blank'} rel={'noreferrer'}>
                {path}
              </a>
            );
          }
          if (path.includes('https://t.me/')) {
            return (
              <a href={path} target={'_blank'} rel={'noreferrer'}>
                @{path.split('https://t.me/')[1]}
              </a>
            );
          }
          return path;
        }
      },
    },
    {
      title: 'Комментарии',
      dataIndex: 'comments',
      key: 'comments',
      render: comments => {
        if (comments) {
          return comments;
        }
      },
    },
  ];
  const handlePaginationChange = (page: number, size: number) => {
    setSearchAndPaginationParams({
      ...searchAndPaginationParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      <CustomTable
        tableKey={'UsersHistoriesTable'}
        dataSource={userHistories}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: searchAndPaginationParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
    </>
  );
};

export default UsersHistoriesTable;
