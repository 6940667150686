import { useEffect } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { useAddTemplateMutation, useEditTemplateMutation } from '../../../store/services/templates';
import { Template } from '../../../core/types/entities';
import { toast } from 'react-toastify';
import { generateFileApiToForm } from '../../../core/utils';
import { TemplateList } from '../../../core/types/enums';
import { ITemplateCreateFormData } from '../../../core/types/forms';
import { useGetProjectsQuery } from '../../../store/services/contracts/projects';
import { useGetProjectsDirectionsQuery } from '../../../store/services/contracts/projects-directions';

const { TextArea } = Input;
const { Option } = Select;

interface CreateTemplateFormProps {
  type: TemplateList;
  showModal: boolean;
  selectedTemplate: Template | null;
  setShowModal: (state: boolean) => void;
}

const CreateTemplateForm = ({ type, showModal, selectedTemplate, setShowModal }: CreateTemplateFormProps) => {
  const [form] = Form.useForm();
  const projectId = Form.useWatch('projectId', form);

  const [addTemplate, addTemplateStates] = useAddTemplateMutation();
  const [editTemplate, editTemplateStates] = useEditTemplateMutation();
  const { data: projects } = useGetProjectsQuery();
  const { data: projectsDirection } = useGetProjectsDirectionsQuery(projectId);

  const handleSubmitForm = (data: ITemplateCreateFormData) => {
    if (selectedTemplate?.id) {
      editTemplate({
        ...data,
        id: selectedTemplate.id,
        type: type,
      });
      return;
    }
    addTemplate({
      ...data,
      type: type,
    });
  };

  useEffect(() => {
    form.resetFields();
    if (selectedTemplate) {
      form.setFieldsValue({
        ...selectedTemplate,
        fileName: selectedTemplate?.lastVersion?.file
          ? [generateFileApiToForm(selectedTemplate?.lastVersion?.file)]
          : undefined,
        projectId: selectedTemplate?.project?.id,
        directionId: selectedTemplate?.direction?.id,
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (addTemplateStates.isSuccess) {
      toast.success('Шаблон успешно добавлен!');
      setShowModal(false);
      form.resetFields();
    }
    if (addTemplateStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [addTemplateStates.isSuccess, addTemplateStates.isError]);

  useEffect(() => {
    if (editTemplateStates.isSuccess) {
      toast.success('Шаблон успешно изменен!');
      setShowModal(false);
      form.resetFields();
    }
    if (editTemplateStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [editTemplateStates.isSuccess, editTemplateStates.isError]);

  return (
    <Modal
      open={showModal}
      centered
      title={selectedTemplate?.id ? 'Изменить шаблон' : 'Создать шаблон'}
      okText={'Сохранить'}
      onCancel={() => setShowModal(false)}
      onOk={() => form.submit()}
      confirmLoading={addTemplateStates.isLoading}
      afterOpenChange={() => form.getFieldInstance('name')?.focus()}
    >
      <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
        <Form.Item
          label={'Название'}
          name={'name'}
          rules={[
            {
              required: true,
              message: 'Поле "Название" обязательное!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Проект'}
          key={'projectId'}
          name={'projectId'}
          rules={[
            {
              required: true,
              message: 'Поле "Проект" обязательное!',
            },
          ]}
        >
          <Select
            placeholder="Выберите проект"
            allowClear
            onChange={() => {
              form.setFieldValue('directionId', undefined);
            }}
          >
            <Select.OptGroup label={'Выберите проект'} />
            {!!projects &&
              projects?.map(p => (
                <Option value={p.id} key={p.id}>
                  {p.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {projectId && (
          <Form.Item
            label={'Направление'}
            key={'directionId'}
            name={'directionId'}
            rules={[
              {
                required: true,
                message: 'Поле "Проект" обязательное!',
              },
            ]}
          >
            <Select placeholder="Выберите направление" allowClear>
              <Select.OptGroup label={'Выберите направление'} />
              {!!projectsDirection &&
                projectsDirection?.map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label={'Комментарий'} name={'comments'}>
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateTemplateForm;
