import Title from 'antd/es/typography/Title';
import AgentsTable from '../../components/Tables/AgentsTable';

const AgentsPage = () => {
  return (
    <>
      <Title>Контрагенты</Title>
      <AgentsTable />
    </>
  );
};

export default AgentsPage;
