import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { toast } from 'react-toastify';
import { ColumnsType } from 'antd/lib/table';
import { ISignSystem } from '../../core/types/entities';
import CustomTable from '../UI/Table';
import {
  useAddSignSystemMutation,
  useGetSignSystemsQuery,
  useRemoveSignSystemMutation,
  useUpdateSignSystemMutation,
} from '../../store/services/contracts/sign-systems';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';

const { confirm } = Modal;

const SignSystemsTable = () => {
  const { data } = useGetSignSystemsQuery();
  const [addSignSystem, addSignSystemStates] = useAddSignSystemMutation();
  const [updateSignSystem, updateSignSystemStates] = useUpdateSignSystemMutation();
  const [removeSignSystem, removeSignSystemStates] = useRemoveSignSystemMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedSignSystem, setSelectedSignSystem] = useState<ISignSystem | undefined>(undefined);
  const { role } = useAppSelector(state => state.authReducer);

  const signSystemData = data?.map((signSystem, index) => ({
    ...signSystem,
    posNum: index + 1,
    key: signSystem.id,
  }));

  useEffect(() => {
    if (addSignSystemStates.isSuccess) {
      toast.success('Новая система подписи успешно добавлена!');
      setShowModal(false);
      form.resetFields();
    }
    if (addSignSystemStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (removeSignSystemStates.isError) {
      toast.error('Произошла ошибка при удалении системы подписи');
    }
  }, [addSignSystemStates.isSuccess, addSignSystemStates.isError, removeSignSystemStates.isError]);

  useEffect(() => {
    if (updateSignSystemStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateSignSystemStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateSignSystemStates.isSuccess, updateSignSystemStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'Система подписи',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const signSystem = data as ISignSystem;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              data-testid={`edit-sign-system-${signSystem.id}`}
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue(signSystem);
                setSelectedSignSystem(signSystem);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              data-testid={`delete-sign-system-${signSystem.id}`}
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить систему подписи?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveContractType(signSystem.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: { name: string }) => {
    if (selectedSignSystem) {
      return updateSignSystem([selectedSignSystem.id, data]);
    }
    addSignSystem(data);
  };

  const handleRemoveContractType = (id: number) => {
    removeSignSystem(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSignSystem(undefined);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable tableKey={'SignSystemsTable'} columns={columns} dataSource={signSystemData} pagination={false} />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(selectedSignSystem ? 'Редактировать' : 'Добавить') + ' систему подписи'}
        okText={selectedSignSystem ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addSignSystemStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Система подписи'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Система подписи" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SignSystemsTable;
