import { AxiosResponse } from 'axios';
import { $api } from '../core/http';
import { ISearchActsPaymentsPageQuery } from '../core/types/query';

export default class FileService {
  static async getFile(fileName: string): Promise<AxiosResponse<BlobPart>> {
    return $api.get(`/files/${fileName}`, { responseType: 'blob' });
  }

  static async getActsPayments(
    params: Omit<ISearchActsPaymentsPageQuery, 'page' | 'size'>
  ): Promise<AxiosResponse<BlobPart>> {
    return $api.get(`/acts/payments/download`, { responseType: 'blob', params: params });
  }
}
