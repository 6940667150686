import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { toast } from 'react-toastify';
import { FixedActionTypesEnum, RolesEnum, TemplateList } from '../../core/types/enums';
import {
  getExceptionMessage,
  getFixedActionTypeName,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
  isFetchBaseQueryError,
  normFile,
  validateTextForInvalidCharacters,
} from '../../core/utils';
import FileUpload from '../UI/FileUpload';
import { IDealCreateForm } from '../../core/types/forms';
import { useAddDealMutation, useUpdateDealMutation } from '../../store/services/deals';
import { useGetServiceAreasQuery } from '../../store/services/contracts/service-areas';
import { useGetServiceUnitsQuery } from '../../store/services/contracts/service-units';
import { IDeal, Template } from '../../core/types/entities';
import { useGetContractsQuery } from '../../store/services/contracts/contracts';
import { useGetTemplatesQuery, useGetTemplateVersionsQuery } from '../../store/services/templates';
import { IException } from '../../core/types/response';
import dayjs from 'dayjs';
import DatePickerBase from '../UI/DatePickerBase';
import { useAppSelector } from '../../core/hooks/redux';

interface IDealCreateModal {
  open: boolean;
  setClose: () => void;
  initialData?: IDeal;
}

const { TextArea } = Input;

const DealFormModal: FC<IDealCreateModal> = ({ open, setClose, initialData }) => {
  const { role, user } = useAppSelector(state => state.authReducer);
  const [form] = Form.useForm();
  const [createDeal, createDealStates] = useAddDealMutation();
  const [updateDeal, updateDealStates] = useUpdateDealMutation();
  const serviceAreas = useGetServiceAreasQuery();
  const serviceUnits = useGetServiceUnitsQuery();
  const contracts = useGetContractsQuery({
    size: 999,
    responsibleUserId: role === RolesEnum.MANAGER ? String(user?.id) : undefined,
  });
  const templates = useGetTemplatesQuery({
    size: 999,
    type: TemplateList.DEAL,
  });
  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>(undefined);
  const templatesVersions = useGetTemplateVersionsQuery(selectedTemplate?.id || 0, { skip: !selectedTemplate });
  const isFileUploaded = !!Form.useWatch('fileName', form);

  useEffect(() => {
    if (initialData && open) {
      form.setFieldsValue(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (createDealStates.isSuccess) {
      toast.success('Доп. соглашение успешно создано');
      handleCloseModal();
    }
    if (createDealStates.isError && 'data' in createDealStates.error) {
      toast.error('Возникла проблема при создании доп. соглашения');
    }
  }, [createDealStates.isError, createDealStates.isSuccess]);

  useEffect(() => {
    if (updateDealStates.isSuccess) {
      toast.success('Доп. соглашение успешно изменено');
      handleCloseModal();
    }
    if (isFetchBaseQueryError(updateDealStates.error)) {
      toast.error(
        getExceptionMessage(updateDealStates.error.data as IException) ||
          'Возникла проблема при изменении доп. соглашения'
      );
    }
  }, [updateDealStates.isError, updateDealStates.isSuccess]);

  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  const handleCreateDeal = () => {
    const data = form.getFieldsValue();
    if (initialData) {
      return updateDeal([initialData?.id, data]);
    }
    const resultData: IDealCreateForm = {
      ...data,
      withPattern: !!data.patternVersionId,
      fileName: data.fileName?.[0]?.uid,
    };
    if (dayjs(resultData.fromDate).isAfter(dayjs(resultData.toDate))) {
      toast.error('Некорректно указана дата доп.соглашения');
      return;
    }
    createDeal(resultData);
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={`${!initialData ? 'Создать' : 'Изменить'} доп. соглашение`}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={() => form.submit()}
      onCancel={handleCloseModal}
      okText={!initialData ? 'Создать' : 'Сохранить'}
      afterOpenChange={() => form.getFieldInstance('fromDate')?.focus()}
    >
      <Form form={form} layout={'vertical'} onFinish={handleCreateDeal}>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Дата доп. Соглашения'}
              key={'fromDate'}
              name={'fromDate'}
              rules={[
                {
                  required: !initialData,
                  message: 'Поле "Дата доп. Соглашения" обязательное!',
                },
              ]}
              getValueFromEvent={getValueFormDatePicker}
              getValueProps={getValueFormDatePickerProps}
            >
              <DatePickerBase style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Дата до'}
              key={'toDate'}
              name={'toDate'}
              rules={[{ message: 'Поле "Дата до" обязательное!' }]}
              getValueFromEvent={getValueFormDatePicker}
              getValueProps={getValueFormDatePickerProps}
            >
              <DatePickerBase style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        {initialData && (
          <Form.Item
            label={'Номер'}
            key={'number'}
            name={'number'}
            rules={[
              {
                validator: validateTextForInvalidCharacters,
              },
            ]}
          >
            <Input type={'number'} />
          </Form.Item>
        )}
        {!initialData && (
          <>
            <Form.Item
              label={'Договор'}
              key={'contractId'}
              name={'contractId'}
              rules={[
                {
                  required: !initialData,
                  message: 'Поле "Договор" обязательное!',
                },
              ]}
            >
              <Select allowClear>
                <Select.OptGroup label={'Договор'} />
                {contracts.data?.data.map(contract => (
                  <Select.Option key={contract.id} value={contract.id}>
                    № {contract.number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={'Шаблон'}>
              <Select
                onChange={templateId => {
                  setSelectedTemplate(templates?.data?.data.find(template => template.id === templateId));
                  form.resetFields(['patternVersionId']);
                }}
                allowClear
                value={selectedTemplate?.id}
                disabled={isFileUploaded}
              >
                <Select.OptGroup label={'Шаблон'} />
                {templates.data?.data?.map(template => (
                  <Select.Option key={template.id} value={template.id}>
                    {template.name ? template.name : `Шаблон id ${template.id}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={'Версия шаблона'}
              key={'patternVersionId'}
              name={'patternVersionId'}
              help={selectedTemplate && templatesVersions.data?.data.length === 0 ? 'Нет доступных версий' : ''}
            >
              <Select
                disabled={templatesVersions.data?.data.length === 0 || !templatesVersions.data || !selectedTemplate}
                allowClear
              >
                <Select.OptGroup label={'Версия шаблона'} />
                {selectedTemplate &&
                  templatesVersions.data?.data?.map(templateVersion => (
                    <Select.Option key={templateVersion.id} value={templateVersion.id}>
                      Версия № {templateVersion.version}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={'Доп. соглашение'}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              name={'fileName'}
            >
              <FileUpload onChange={() => {}} accept={'*'} disabled={!!selectedTemplate} />
            </Form.Item>
            <Form.Item label="Зафиксированные услуги">
              <Form.List name="fixedActionForms">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(field => (
                      <div key={field.key} style={{ margin: '0 0 20px 20px' }}>
                        <Form.Item
                          name={[field.name, 'rate']}
                          label="Ставка"
                          rules={[
                            {
                              required: true,
                              message: 'Поле "Ставка" обязательное!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item name={[field.name, 'workCount']} label="Объем работ">
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'type']}
                          label="Тип зафиксированной услуги"
                          rules={[
                            {
                              required: true,
                              message: 'Поле "Тип зафиксированной услуги" обязательное!',
                            },
                          ]}
                        >
                          <Select allowClear>
                            <Select.OptGroup label={'Тип зафиксированной услуги'} />
                            {Object.values(FixedActionTypesEnum).map(actionType => (
                              <Select.Option key={actionType}>{getFixedActionTypeName(actionType)}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'actionId']}
                          label="Услуга"
                          rules={[
                            {
                              required: true,
                              message: 'Поле "Услуга" обязательное!',
                            },
                          ]}
                        >
                          <Select allowClear>
                            <Select.OptGroup label={'Услуга'} />
                            {serviceAreas.data?.map(serviceArea => (
                              <Select.Option key={serviceArea.id} value={serviceArea.id}>
                                {serviceArea.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'actionUnitId']}
                          label="Единица измерения"
                          rules={[
                            {
                              required: true,
                              message: 'Поле "Единица измерения" обязательное!',
                            },
                          ]}
                        >
                          <Select allowClear>
                            <Select.OptGroup label={'Единица измерения'} />
                            {serviceUnits.data?.map(serviceUnit => (
                              <Select.Option key={serviceUnit.id} value={serviceUnit.id}>
                                {serviceUnit.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Button onClick={() => remove(field.name)} danger>
                          Удалить
                        </Button>
                      </div>
                    ))}
                    <Button onClick={() => add()}>Добавить услугу</Button>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </>
        )}
        <Form.Item label={'Комментарии'} name={'comments'}>
          <TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DealFormModal;
