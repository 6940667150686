import { api } from '../index';
import { IProjectSpecification } from '../../../core/types/entities';

export const projectsApi = api.injectEndpoints({
  endpoints: builder => ({
    getProjectSpecifications: builder.query<IProjectSpecification[], number | void>({
      query: projectId => `/contracts/projects/specifications${projectId ? '?projectId=' + projectId : ''}`,
      providesTags: ['projects-specifications'],
    }),
    getProjectSpecification: builder.query<IProjectSpecification, number>({
      query: id => ({
        url: `/contracts/projects/specifications/${id}`,
      }),
    }),
    addProjectSpecification: builder.mutation<IProjectSpecification, { name: string }>({
      query: data => ({
        url: '/contracts/projects/specifications',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['projects-specifications'],
    }),
    updateProjectSpecification: builder.mutation<IProjectSpecification, [number, { name: string }]>({
      query: ([id, data]) => ({
        url: `/contracts/projects/specifications/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['projects-specifications'],
    }),
    removeProjectSpecification: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/projects/specifications/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['projects-specifications'],
    }),
  }),
});

export const {
  useGetProjectSpecificationsQuery,
  useAddProjectSpecificationMutation,
  useUpdateProjectSpecificationMutation,
  useRemoveProjectSpecificationMutation,
  useGetProjectSpecificationQuery,
} = projectsApi;
