import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { toast } from 'react-toastify';
import { ColumnsType } from 'antd/lib/table';
import { IContractsCourse, IContractsCourseMutationType } from '../../core/types/entities';
import CustomTable from '../UI/Table';
import { useGetProjectsDirectionsQuery } from '../../store/services/contracts/projects-directions';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';
import {
  useAddContractsCourseMutation,
  useDeleteContractsCourseMutation,
  useGetContractsCoursesQuery,
  useUpdateContractsCourseMutation,
} from '../../store/services/contracts/courses';
import { useGetProjectsQuery } from '../../store/services/contracts/projects';
import { getValueFormDatePicker, getValueFormDatePickerProps } from '../../core/utils';
import DatePickerBase from '../UI/DatePickerBase';
import dayjs from 'dayjs';

const { Option } = Select;
const { confirm } = Modal;

const CourseFaqTable = () => {
  const { data: courses } = useGetContractsCoursesQuery();
  const { data: projects } = useGetProjectsQuery();
  const { data: projectsDirection } = useGetProjectsDirectionsQuery();
  const [addContractsCourses, addContractsCoursesStates] = useAddContractsCourseMutation();
  const [updateContractsCourse, updateContractsCourseStates] = useUpdateContractsCourseMutation();
  const [deleteContractsCourse, deleteContractsCourseStates] = useDeleteContractsCourseMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [editCourseData, setEditCourseData] = useState<IContractsCourse | null>(null);
  const { role } = useAppSelector(state => state.authReducer);

  const coursesData = courses?.map((course, index) => ({
    ...course,
    posNum: index + 1,
    key: course.id,
  }));

  useEffect(() => {
    if (addContractsCoursesStates.isSuccess) {
      toast.success('Новый курс добавлен!');
      handleCloseModal();
    }
    if (addContractsCoursesStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (deleteContractsCourseStates.isError) {
      toast.error('Произошла ошибка при удалении курса');
    }
  }, [addContractsCoursesStates.isSuccess, addContractsCoursesStates.isError, deleteContractsCourseStates.isError]);

  useEffect(() => {
    if (updateContractsCourseStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateContractsCourseStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateContractsCourseStates.isSuccess, updateContractsCourseStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'Курс',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const contractsCourse = data as IContractsCourse;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue({
                  ...contractsCourse,
                  projectId: contractsCourse.project.id,
                  projectDirectionId: contractsCourse.projectDirection.id,
                });
                setEditCourseData(contractsCourse);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить курс?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveContractType(contractsCourse.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: IContractsCourseMutationType) => {
    const formData = form.getFieldsValue();

    if (dayjs(formData.fromDate).isAfter(dayjs(formData.toDate))) {
      toast.error('Некорректно указана "Дата от" или "Дата до"');
      return;
    }

    if (editCourseData) {
      return updateContractsCourse([editCourseData.id, data]);
    }
    return addContractsCourses(data);
  };

  const handleRemoveContractType = (id: number) => {
    deleteContractsCourse(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditCourseData(null);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable tableKey={'CourseFaqTable'} columns={columns} dataSource={coursesData} pagination={false} />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(editCourseData ? 'Редактировать' : 'Добавить') + ' курс'}
        okText={editCourseData ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addContractsCoursesStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Название'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Название" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={'Проект'}
            key={'projectId'}
            name={'projectId'}
            rules={[
              {
                required: true,
                message: 'Поле "Проект" обязательное!',
              },
            ]}
          >
            <Select placeholder="Выберите проект" allowClear>
              <Select.OptGroup label={'Выберите проект'} />
              {!!projects &&
                projects?.map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'Направление'}
            key={'projectDirectionId'}
            name={'projectDirectionId'}
            rules={[
              {
                required: true,
                message: 'Поле "Направление" обязательное!',
              },
            ]}
          >
            <Select placeholder="Выберите направление" allowClear>
              <Select.OptGroup label={'Выберите направление'} />
              {!!projectsDirection &&
                projectsDirection?.map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'Дата начала'}
            key={'fromDate'}
            name={'fromDate'}
            getValueFromEvent={getValueFormDatePicker}
            getValueProps={getValueFormDatePickerProps}
            rules={[
              {
                required: true,
                message: 'Поле "Дата начала" обязательное!',
              },
            ]}
          >
            <DatePickerBase style={{ width: '100%' }} autoFocus />
          </Form.Item>
          <Form.Item
            label={'Дата завершения'}
            key={'toDate'}
            name={'toDate'}
            getValueFromEvent={getValueFormDatePicker}
            getValueProps={getValueFormDatePickerProps}
            rules={[
              {
                required: true,
                message: 'Поле "Дата завершения" обязательное!',
              },
            ]}
          >
            <DatePickerBase style={{ width: '100%' }} autoFocus />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CourseFaqTable;
