import { api } from '../index';
import { ICertificate, ISearchPage } from '../../../core/types/entities';
import { ISearchCertificatesPageQuery } from '../../../core/types/query';
import { ICertificateCreateForm } from '../../../core/types/forms';

export const certificatesApi = api.injectEndpoints({
  endpoints: builder => ({
    getCertificates: builder.query<ISearchPage<ICertificate>, ISearchCertificatesPageQuery>({
      query: params => ({
        url: `/certificates`,
        params: params,
      }),
      providesTags: ['certificates'],
    }),
    getCertificate: builder.query<ICertificate, number>({
      query: id => ({
        url: `/certificates/${id}`,
      }),
    }),
    createCertificate: builder.mutation<ICertificate, ICertificateCreateForm>({
      query: data => ({
        url: `/certificates`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['certificates'],
    }),
    removeCertificate: builder.mutation<void, number>({
      query: id => ({
        url: `/certificates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['certificates'],
    }),
  }),
});

export const {
  useGetCertificatesQuery,
  useGetCertificateQuery,
  useCreateCertificateMutation,
  useRemoveCertificateMutation,
} = certificatesApi;
