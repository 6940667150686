import { AxiosResponse } from 'axios';
import { $api } from '../core/http';
import { IAuthFormData } from '../core/types/forms';
import { IAuthResponse, IRefreshTokensResponse } from '../core/types/response';

export default class AuthService {
  static async login({ login, password }: IAuthFormData): Promise<AxiosResponse<IAuthResponse>> {
    return $api.post<IAuthResponse>('/auth/login', {
      login,
      password,
    });
  }

  static async validateToken(token: string): Promise<AxiosResponse<void>> {
    return $api.post(`/auth/validate?token=${token}`);
  }

  static async refreshToken(token: string): Promise<AxiosResponse<IRefreshTokensResponse>> {
    return $api.post(`/auth/refresh?refresh-token=${token}`);
  }
}
