import { Checkbox, Form, Modal } from 'antd';
import { IAgentInfos, IFieldsExcludeForAgent } from '../../core/types/entities';
import { useEffect } from 'react';
import { useUpdateAgentInfosMutation } from '../../store/services/users/agents-infos';
import { toast } from 'react-toastify';
import { formattedFieldsToExcludeForAgent } from '../Tables/AgentsTable';

const AgentExcludedFieldsModal = ({
  isExcludedFieldsModal,
  selectedAgent,
  fieldsToExcludeForAgent,
  handleCloseExcludedFieldsModal,
}: {
  isExcludedFieldsModal: boolean;
  selectedAgent: IAgentInfos | undefined;
  fieldsToExcludeForAgent?: IFieldsExcludeForAgent[];
  handleCloseExcludedFieldsModal: () => void;
}) => {
  const [agentForm] = Form.useForm();
  const [updateAgentInfo, updateAgentInfoStates] = useUpdateAgentInfosMutation();

  useEffect(() => {
    if (selectedAgent && isExcludedFieldsModal) {
      agentForm.setFieldsValue({
        excludedFieldsIds: selectedAgent.excludedFields.map(field => field.id),
      });
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (updateAgentInfoStates.isSuccess) {
      toast.success('Информация об контрагенте успешно изменена');
      handleCloseExcludedFieldsModal();
    }
    if (updateAgentInfoStates.isError) {
      toast.error('Не удалось изменить информацию об контрагенте');
    }
  }, [updateAgentInfoStates.isSuccess, updateAgentInfoStates.isError]);

  const onHandleSubmitForm = (excludedFieldsIds: Record<'excludedFieldsIds', number[]>) => {
    const data = {
      ...excludedFieldsIds,
    };

    updateAgentInfo({
      id: selectedAgent!.id,
      data,
    });
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={'Исключение полей из документов'}
      open={isExcludedFieldsModal}
      centered={true}
      onCancel={handleCloseExcludedFieldsModal}
      okText={'Сохранить'}
      onOk={() => agentForm.submit()}
    >
      <Form form={agentForm} layout={'vertical'} onFinish={onHandleSubmitForm}>
        <Form.Item label="Поля для исключения из документов" name="excludedFieldsIds">
          <Checkbox.Group
            options={formattedFieldsToExcludeForAgent(fieldsToExcludeForAgent ?? [])}
            style={{ flexDirection: 'column' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AgentExcludedFieldsModal;
