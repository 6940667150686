import { FC, useEffect } from 'react';
import { Form, Modal, Select } from 'antd';
import { toast } from 'react-toastify';
import {
  customFilterOption,
  getExceptionMessage,
  getValueFormDatePickerProps,
  getValueWithTimeFormDatePicker,
} from '../../core/utils';
import { IException } from '../../core/types/response';
import { useGetUsersQuery } from '../../store/services/users';
import { RolesEnum } from '../../core/types/enums';
import { useAddMeetingMutation, useEditMeetingMutation } from '../../store/services/meetings';
import { Meeting, MeetingCreate } from '../../core/types/entities';
import { RU_DATE_TIME } from '../../core/constants/dateFormatting';
import { useAppSelector } from '../../core/hooks/redux';
import DatePickerBase from '../UI/DatePickerBase';

interface ICertificateDownloadByAgentModal {
  open: boolean;
  setClose: () => void;
  meeting?: Meeting;
}

const PlanMeetingModalForm: FC<ICertificateDownloadByAgentModal> = ({ open, setClose, meeting }) => {
  const [form] = Form.useForm();
  const [addMeeting, addMeetingStates] = useAddMeetingMutation();
  const [editMeeting, editMeetingStates] = useEditMeetingMutation();
  const { role } = useAppSelector(state => state.authReducer);
  const agents = useGetUsersQuery(
    {
      roles: RolesEnum.AGENT,
      size: 999,
    },
    { skip: role === RolesEnum.AGENT }
  );

  const isEdit = !!meeting;

  useEffect(() => {
    if (addMeetingStates.isSuccess) {
      toast.success('Видеоконференция успешно создана!');
      handleCloseModal();
    }
    if (addMeetingStates.isError && 'data' in addMeetingStates.error) {
      toast.error(
        getExceptionMessage(addMeetingStates.error.data as IException) ||
          'Возникла проблема при создании видеоконференции'
      );
    }
  }, [addMeetingStates.isError, addMeetingStates.isSuccess]);

  useEffect(() => {
    if (editMeetingStates.isSuccess) {
      toast.success('Видеоконференция успешно измененна!');
      handleCloseModal();
    }
    if (editMeetingStates.error && 'status' in editMeetingStates.error && editMeetingStates.error.status === 403) {
      toast.error('Возникла проблема при изменении видеоконференции. Сервер отказал в доступе!');
      return;
    }
    if (editMeetingStates.isError && 'data' in editMeetingStates.error) {
      toast.error(
        getExceptionMessage(editMeetingStates.error.data as IException) ||
          'Возникла проблема при изменении видеоконференции'
      );
    }
  }, [editMeetingStates.isError, editMeetingStates.isSuccess]);

  useEffect(() => {
    if (meeting) {
      form.setFieldsValue({
        id: meeting.id,
        dateTime: meeting.startDateTime,
      });
    }
  }, [meeting]);

  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  const handleCreateMeeting = (values: MeetingCreate) => {
    if (isEdit) {
      editMeeting({ id: `${meeting!.id}`, ...values });
      return;
    }
    addMeeting(values);
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={(isEdit ? 'Изменить' : 'Запланировать') + ' видеоконференцию'}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={() => form.submit()}
      onCancel={handleCloseModal}
      okText={isEdit ? 'Сохранить' : 'Создать'}
      afterOpenChange={() => form.getFieldInstance('userId')?.focus()}
    >
      <Form form={form} layout={'vertical'} onFinish={handleCreateMeeting}>
        {!isEdit && role !== RolesEnum.AGENT && (
          <Form.Item
            label={'Контрагент'}
            key={'userId'}
            name={'userId'}
            rules={[
              {
                required: true,
                message: 'Поле "Контрагент" обязательное!',
              },
            ]}
          >
            <Select showSearch filterOption={customFilterOption} allowClear>
              <Select.OptGroup label={'Контрагент'} />
              {agents.data?.data?.map(agent => (
                <Select.Option key={agent.id} value={agent.id}>
                  {`${agent.lastName} ${agent.firstName} ${agent.patronymic ?? ''}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={'Дата и время'}
          key={'dateTime'}
          name={'dateTime'}
          rules={[
            {
              required: true,
              message: 'Поле "Дата и время" обязательное!',
            },
          ]}
          getValueFromEvent={getValueWithTimeFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
        >
          <DatePickerBase showTime style={{ width: '100%' }} format={RU_DATE_TIME} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PlanMeetingModalForm;
