import CustomTable from '../UI/Table';
import { Key, useEffect, useState } from 'react';
import { ISearchRequestsPageQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import { Button, Col, Dropdown, MenuProps, Modal, Row } from 'antd';
import { RolesEnum, TableFiltersItemsEnum, TemplateList } from '../../core/types/enums';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { IContract, IRequest, IUser } from '../../core/types/entities';
import { SettingOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../core/hooks/redux';
import { toast } from 'react-toastify';
import RequestFormModal from '../Modals/RequestFormModal';
import {
  useGetRequestsQuery,
  useRegenerateRequestDocumentsMutation,
  useRemoveRequestMutation,
} from '../../store/services/requests';
import { RU_DATE } from '../../core/constants/dateFormatting';
import GenerateActsModal from '../Modals/GenerateActsModal';
import { getFullNameUser, getPosTableNum } from '../../core/utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import TableFilters from '../TableFilters';

const { confirm } = Modal;

const RequestsTable = () => {
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchRequestsPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isActsModalOpen, setIsActsModalOpen] = useState(false);
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const { data } = useGetRequestsQuery(paginationAndSearchParams);
  const [removeApplication, removeApplicationStates] = useRemoveRequestMutation();
  const [regenerateRequestDocuments, regenerateRequestDocumentsStates] = useRegenerateRequestDocumentsMutation();
  const { role } = useAppSelector(state => state.authReducer);
  const [selectedRequest, setSelectedRequest] = useState<IRequest | undefined>(undefined);
  const isSelectedRequests = selectedRowKeys.length > 0;

  useEffect(() => {
    if (removeApplicationStates.isSuccess) {
      toast.success('Заявка успешно удалена');
    }
    if (removeApplicationStates.isError) {
      toast.error('Возникла проблема при удалении заявки');
    }
  }, [removeApplicationStates.isSuccess, removeApplicationStates.isError]);

  useEffect(() => {
    if (regenerateRequestDocumentsStates.isSuccess) {
      toast.success('Документы заявки успешно перегенерированы');
    }
    if (regenerateRequestDocumentsStates.isError) {
      toast.error('Возникла проблема при перегенерировании документов заявки');
    }
  }, [regenerateRequestDocumentsStates.isSuccess, regenerateRequestDocumentsStates.isError]);

  const requests = data?.data?.map((request, index) => ({
    ...request,
    posNum: getPosTableNum(
      index,
      paginationAndSearchParams.page || DEFAULT_PAGE,
      paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
    ),
    key: request.id,
  }));

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Номер заявки',
      dataIndex: 'number',
      key: 'number',
      width: 100,
      render: number => <>№ {number}</>,
    },
    {
      title: 'Дата заявки',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: date => date && dayjs(date).format(RU_DATE),
    },
    {
      title: 'Создал/а',
      dataIndex: 'creator',
      key: 'creator',
      render: data => {
        const creator = data as IUser;
        return getFullNameUser(creator);
      },
    },
    {
      title: 'Договор №',
      dataIndex: 'contract',
      key: 'contract',
      render: data => {
        const contract = data as IContract;
        return <Link to={`/contracts/${contract?.id}`}>№ {contract?.number}</Link>;
      },
    },
    {
      title: 'Контрагент',
      dataIndex: ['contract', 'agent'],
      key: 'agent',
      render: (data: IUser) => <Link to={`/agents/${data?.id}`}>{getFullNameUser(data)}</Link>,
    },
    {
      title: 'Срок действия',
      dataIndex: 'validity',
      key: 'validity',
      align: 'center',
      render: (_, data) => {
        const applicationData = data as IRequest;
        return (
          dayjs(applicationData?.fromDate).format(RU_DATE) + ' - ' + dayjs(applicationData?.toDate).format(RU_DATE)
        );
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, data: unknown) => {
        const contract = data as IContract;
        return (
          <Dropdown menu={{ items: renderDropDownItems(contract.id) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const renderDropDownItems = (id: number) => {
    const items: MenuProps['items'] = [];

    if (role === RolesEnum.ADMIN.toString() || role === RolesEnum.MANAGER.toString()) {
      items.push(
        {
          key: 'drop-down-item-1',
          onClick: () => {
            setSelectedRequest(requests?.find(request => request.id === id));
            setIsFormModalOpen(true);
          },
          label: 'Изменить',
        },
        {
          key: 'drop-down-item-2',
          onClick: () => {
            window.open(`/requests/histories/${id}`, '_blank');
          },
          label: 'История изменений',
        },
        {
          key: 'drop-down-item-3',
          onClick: () => {
            confirm({
              title: 'Вы точно хотите перегенерировать документы заявки?',
              centered: true,
              cancelText: 'Отмена',
              okText: 'Подтвердить',
              onOk() {
                regenerateRequestDocuments(id);
              },
            });
          },
          label: 'Перегенерировать документы заявки',
        }
      );
    }

    if (role === RolesEnum.MANAGER) {
      items.splice(-1, 1);
    }

    if (role === RolesEnum.ADMIN.toString()) {
      items.push({
        key: 'drop-down-item-4',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить заявку?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              removeApplication(id);
            },
          });
        },
        label: 'Удалить',
        danger: true,
      });
    }

    return items;
  };
  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Row gutter={[20, 0]}>
        <Col xs={24} style={{ marginBottom: '20px' }}>
          <Button type={'primary'} onClick={() => setIsFormModalOpen(true)}>
            Создать заявку
          </Button>
        </Col>
      </Row>
      <TableFilters
        fields={[
          TableFiltersItemsEnum.FIND_BY_AGENT_FIO,
          TableFiltersItemsEnum.PROJECT,
          TableFiltersItemsEnum.FROM_DATE,
          TableFiltersItemsEnum.TO_DATE,
          TableFiltersItemsEnum.CONTRACT,
          TableFiltersItemsEnum.PROJECT_DIRECTION,
          TableFiltersItemsEnum.PROJECT_SPECIFICATION,
          TableFiltersItemsEnum.SIGN_SYSTEM,
          TableFiltersItemsEnum.TAX_STATUS,
        ]}
        params={paginationAndSearchParams}
        setParams={setPaginationAndSearchParams}
        footer={
          <Col>
            <Button onClick={() => setIsActsModalOpen(true)} type={'primary'} disabled={!isSelectedRequests}>
              Сформировать акты
            </Button>
          </Col>
        }
      />
      <CustomTable
        tableKey={'RequestsTable'}
        rowSelection={rowSelection}
        dataSource={requests}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <RequestFormModal
        open={isFormModalOpen}
        setClose={() => {
          setIsFormModalOpen(false);
          setSelectedRequest(undefined);
        }}
        initialData={selectedRequest}
      />
      <GenerateActsModal
        open={isActsModalOpen}
        setClose={() => setIsActsModalOpen(false)}
        ids={selectedRowKeys}
        documentType={TemplateList.REQUEST}
      />
    </>
  );
};

export default RequestsTable;
