import { api } from '../index';
import { ISearchContractHistoriesQuery, ISearchContractPageQuery } from '../../../core/types/query';
import { IContract, IContractHistories, ISearchPage } from '../../../core/types/entities';
import {
  IContractFormUpdate,
  ICreateContract,
  IMassActionContracts,
  IUploadFilesToEntity,
} from '../../../core/types/forms';
import { ContractStatusesEnum } from '../../../core/types/enums';

export const contractsApi = api.injectEndpoints({
  endpoints: builder => ({
    getContracts: builder.query<ISearchPage<IContract>, ISearchContractPageQuery>({
      query: params => ({
        url: `/contracts`,
        params: params,
      }),
      providesTags: ['contracts'],
    }),
    getContract: builder.query<IContract, string | undefined>({
      query: id => ({
        url: `/contracts/${id}`,
      }),
      providesTags: ['contract'],
    }),
    addContract: builder.mutation<IContract, ICreateContract>({
      query: contractData => ({
        url: `/contracts`,
        method: 'POST',
        body: contractData,
      }),
      invalidatesTags: ['contracts'],
    }),
    changeContractStatus: builder.mutation<void, [ContractStatusesEnum, IMassActionContracts]>({
      query: ([status, data]) => ({
        url: `/contracts/statuses?status=${status}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['contracts'],
    }),
    updateContract: builder.mutation<IContract, [string, IContractFormUpdate]>({
      query: ([id, contractData]) => ({
        url: `/contracts/${id}`,
        method: 'PATCH',
        body: contractData,
      }),
      invalidatesTags: ['contracts', 'contract'],
    }),
    uploadFilesToContract: builder.mutation<IContract, [number, IUploadFilesToEntity]>({
      query: ([id, uploadData]) => ({
        url: `/contracts/${id}/files`,
        method: 'POST',
        body: uploadData,
      }),
      invalidatesTags: ['contracts'],
    }),
    removeContract: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['contracts'],
    }),
    getContractHistories: builder.query<ISearchPage<IContractHistories>, ISearchContractHistoriesQuery>({
      query: params => ({
        url: `/contracts/histories`,
        params,
      }),
      providesTags: ['contractsHistories'],
    }),
    regenerateContractDocuments: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['contracts'],
    }),
  }),
});

export const {
  useGetContractsQuery,
  useGetContractQuery,
  useAddContractMutation,
  useChangeContractStatusMutation,
  useUpdateContractMutation,
  useUploadFilesToContractMutation,
  useRemoveContractMutation,
  useGetContractHistoriesQuery,
  useRegenerateContractDocumentsMutation,
} = contractsApi;
