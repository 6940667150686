import { api } from '../index';
import { IAuthFormData } from '../../../core/types/forms';

export interface ILogin {
  accessToken: {
    token: string;
  };
  refreshToken: {
    token: string;
  };
}

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    validateToken: builder.query<void, string>({
      query: accessToken => {
        if (accessToken === null) {
          throw new Error('AccessToken is null');
        }

        return {
          url: `/auth/validate`,
          method: 'POST',
          params: {
            token: accessToken,
          },
        };
      },
      providesTags: ['auth'],
    }),
    login: builder.mutation<ILogin, IAuthFormData>({
      query: body => ({
        url: `/auth/login`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ILogin) => {
        localStorage.setItem('accessToken', response.accessToken.token);
        localStorage.setItem('refreshToken', response.refreshToken.token);
        return response;
      },
      invalidatesTags: ['auth'],
    }),
  }),
});

export const { useLoginMutation, useValidateTokenQuery } = authApi;
