import { useParams } from 'react-router-dom';
import ContractDetails from '../../components/ContractDetails';
import Title from 'antd/es/typography/Title';
import { Tabs, TabsProps } from 'antd';
import ContractsHistoriesTable from '../../components/Tables/ContractsHistoriesTable';

const ContractPage = () => {
  const { id } = useParams();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Информация',
      children: <ContractDetails id={id} />,
    },
    {
      key: '2',
      label: 'История изменений',
      children: <ContractsHistoriesTable id={id} />,
    },
  ];

  if (id) {
    return (
      <>
        <Title>Договор</Title>
        <Tabs defaultActiveKey="1" items={items} />
      </>
    );
  }
};

export default ContractPage;
