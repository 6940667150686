import Title from 'antd/es/typography/Title';
import { useParams } from 'react-router-dom';
import DealsHistoriesTable from '../../components/Tables/DealsHistoriesTable';
const DealHistoriesPage = () => {
  const { id } = useParams();
  return (
    <>
      <Title>История изменения доп. соглашения</Title>
      <DealsHistoriesTable id={id} />
    </>
  );
};

export default DealHistoriesPage;
