import { api } from '../index';
import { IFixedActions } from '../../../core/types/entities';

export const fixedActionsApi = api.injectEndpoints({
  endpoints: builder => ({
    getFixedActions: builder.query<IFixedActions[], { contractId?: number; requestId?: number }>({
      query: params => ({
        url: `/fixed-actions`,
        params: params,
      }),
    }),
  }),
});

export const { useGetFixedActionsQuery } = fixedActionsApi;
