import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { ISO_DATE, RU_DATE } from '../../core/constants/dateFormatting';
import { CalendarOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';

registerLocale('ru', ru);

interface DatePickerBaseProps {
  autoFocus?: boolean;
  showTime?: boolean;
  format?: string;
  value?: dayjs.Dayjs;
  style?: React.CSSProperties;
  disabledDate?: (date: dayjs.Dayjs) => boolean;
  onChange?: (date: dayjs.Dayjs | null) => void;
}

const DatePickerBase: React.FC<DatePickerBaseProps> = ({
  onChange,
  value,
  disabledDate,
  showTime,
  format = RU_DATE,
}) => {
  const [initDate, setInitDate] = useState<Date | null>(null);
  const dateFormat = format === RU_DATE ? 'dd.MM.yyyy' : 'dd.MM.yyyy HH:mm';
  const dateMaskFormat = format === RU_DATE ? '99.99.9999' : '99.99.9999 99:99';

  useEffect(() => {
    !value && setInitDate(null);
    value && setInitDate(value.toDate());
  }, [value]);

  const datePickerChange = (date: Date) => {
    const newDate = dayjs(date);
    if (newDate.format(ISO_DATE) !== 'Invalid Date') {
      onChange && onChange(newDate);
    } else {
      onChange && onChange(null);
    }
    setInitDate(date);
  };

  const isWeekday = (date: Date) => {
    if (disabledDate) {
      return !disabledDate(dayjs(date));
    }
    return true;
  };

  return (
    <DatePicker
      placeholderText=""
      showIcon
      isClearable
      showYearDropdown
      dateFormatCalendar="MMMM"
      scrollableYearDropdown
      dropdownMode="select"
      locale="ru"
      dateFormat={dateFormat}
      selected={initDate}
      filterDate={isWeekday}
      showTimeSelect={showTime}
      timeIntervals={10}
      autoComplete="off"
      timeCaption="Время"
      icon={<CalendarOutlined />}
      customInput={<InputMask mask={dateMaskFormat} />}
      onChange={datePickerChange}
    />
  );
};

export default DatePickerBase;
