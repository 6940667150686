import { FC, useState } from 'react';
import CustomTable from '../UI/Table';
import { ISearchContractHistoriesQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useGetContractHistoriesQuery } from '../../store/services/contracts/contracts';
import { RU_DATE, RU_DATE_TIME } from '../../core/constants/dateFormatting';
import {
  getContractStatusName,
  getContractValidityStatusName,
  getFullNameUser,
  getPosTableNum,
} from '../../core/utils';
import { IUser } from '../../core/types/entities';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';

interface IContractsHistoriesTable {
  id: string | undefined;
}

const ContractsHistoriesTable: FC<IContractsHistoriesTable> = ({ id }) => {
  const [searchAndPaginationParams, setSearchAndPaginationParams] = useState<ISearchContractHistoriesQuery>({
    contractId: id,
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const { data } = useGetContractHistoriesQuery(searchAndPaginationParams);
  const paginationCurrentPage = searchAndPaginationParams.page ? searchAndPaginationParams.page + 1 : 1;

  const contractHistories = data?.data.map((contractHistory, index) => ({
    ...contractHistory,
    posNum: getPosTableNum(
      index,
      searchAndPaginationParams.page || DEFAULT_PAGE,
      searchAndPaginationParams.size || DEFAULT_PAGE_SIZE
    ),
    changedBy: getFullNameUser(contractHistory.creator),
    key: contractHistory.id,
  }));

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Обновил/а',
      dataIndex: 'changedBy',
      key: 'changedBy',
    },
    {
      title: 'Дата обновления',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: dateAndTime => {
        return dayjs(dateAndTime).format(RU_DATE_TIME);
      },
    },
    {
      title: 'Дата от',
      dataIndex: 'fromDate',
      key: 'fromDate',
      align: 'center',
      render: fromDate => {
        if (fromDate) {
          return dayjs(fromDate).format(RU_DATE);
        }
      },
    },
    {
      title: 'Дата до',
      dataIndex: 'toDate',
      key: 'toDate',
      align: 'center',
      render: toDate => {
        if (toDate) {
          return dayjs(toDate).format(RU_DATE);
        }
      },
    },
    {
      title: 'Номер',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Статус договора',
      dataIndex: 'documentStatus',
      key: 'documentStatus',
      render: status => {
        return getContractStatusName(status);
      },
    },
    {
      title: 'Тип договора',
      dataIndex: ['type', 'name'],
      key: 'contractType',
    },
    {
      title: 'Статус валидности договора',
      dataIndex: 'validityStatus',
      key: 'validityStatus',
      render: validityStatus => {
        return getContractValidityStatusName(validityStatus);
      },
    },
    {
      title: 'Тип подписи',
      dataIndex: ['signSystem', 'name'],
      key: 'signSystem',
    },
    {
      title: 'Ответственный',
      dataIndex: 'responsibleUser',
      key: 'responsibleUser',
      render: (responsibleUser: IUser) => {
        return getFullNameUser(responsibleUser);
      },
    },
    {
      title: 'Старший преподаватель',
      dataIndex: 'seniorTeacher',
      key: 'seniorTeacher',
      render: (seniorTeacher: IUser) => {
        return getFullNameUser(seniorTeacher);
      },
    },
    {
      title: 'Функции контргента',
      dataIndex: ['agentFunction', 'name'],
      key: 'agentFunction',
    },
    {
      title: 'Налоговый статус',
      dataIndex: ['taxStatus', 'name'],
      key: 'taxStatus',
    },
    {
      title: 'Проект',
      dataIndex: ['project', 'name'],
      key: 'project',
    },
    {
      title: 'Направление проекта',
      dataIndex: ['projectDirection', 'name'],
      key: 'projectDirection',
    },
    {
      title: 'Спецификация проекта',
      dataIndex: ['projectSpecification', 'name'],
      key: 'projectSpecification',
    },
  ];
  const handlePaginationChange = (page: number, size: number) => {
    setSearchAndPaginationParams({
      ...searchAndPaginationParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      <CustomTable
        tableKey={'ContractsHistoriesTable'}
        dataSource={contractHistories}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: searchAndPaginationParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
    </>
  );
};

export default ContractsHistoriesTable;
