import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, List, Row, Select, Skeleton, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import CustomTable from '../UI/Table';
import { Template, TemplateVersion } from '../../core/types/entities';
import { useGetTemplatesQuery, useGetTemplateVersionsQuery } from '../../store/services/templates';
import { ISearchTemplatesPageQuery } from '../../core/types/query';
import moment from 'moment';
import { generateFileApiToForm, getFullNameUser, handleDownloadFile } from '../../core/utils';
import CreateTemplateForm from '../Forms/TemplateForms/CreateTemplateForm';
import { RolesEnum, TemplateList, TypeLessonGroupEnum } from '../../core/types/enums';
import ControlTemplateVersionForm from '../Forms/TemplateForms/ControlTemplateVersionForm';
import { TemplateVersionFormData } from '../../core/types/forms';
import { AnyObject } from 'antd/es/_util/type';
import dayjs from 'dayjs';
import { RU_DATE_TIME } from '../../core/constants/dateFormatting';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import { useAppSelector } from '../../core/hooks/redux';
import { useGetProjectsQuery } from '../../store/services/contracts/projects';
import { useGetProjectsDirectionsQuery } from '../../store/services/contracts/projects-directions';

interface TemplatesTableProps {
  type: TemplateList;
}

const { Option } = Select;

const TemplatesTable = ({ type }: TemplatesTableProps) => {
  const { role } = useAppSelector(state => state.authReducer);
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchTemplatesPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
    type,
  });
  const { data } = useGetTemplatesQuery(paginationAndSearchParams);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState<boolean>(false);
  const [showCreateTemplateVersionModal, setShowCreateTemplateVersionModal] = useState<boolean>(false);
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const selectedTemplate = useRef<Template | null>(null);
  const selectedTemplateVersion = useRef<TemplateVersionFormData | null>(null);
  const [nestedData, setNestedData] = useState<Record<string, readonly AnyObject[]>>({});
  const [expandedRowTemplateId, setExpandedRowTemplateId] = useState<number | null>(null);
  const templateVersionsQuery = useGetTemplateVersionsQuery(expandedRowTemplateId!!, {
    skip: !expandedRowTemplateId,
  });
  const [projectId, setProjectId] = useState<number | undefined>(undefined);
  const [projectDirectionId, setProjectDirectionId] = useState<number | undefined>(undefined);
  const { data: projects } = useGetProjectsQuery();
  const { data: projectsDirection } = useGetProjectsDirectionsQuery(projectId);

  const isManager = role === RolesEnum.MANAGER;
  const columns: ColumnsType = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 355,
    },
    {
      title: 'Дата загрузки',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '10%',
      align: 'center',
      render: (date: string) => moment(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Версия',
      dataIndex: 'lastVersion',
      key: 'lastVersion',
      width: '127px',
      render: (lastVersion: TemplateVersion) =>
        lastVersion ? (
          <Button type="link" onClick={() => handleDownloadFile(lastVersion.file)} style={{ padding: 0 }}>
            Версия {lastVersion.version}
          </Button>
        ) : (
          'Нет версий'
        ),
    },
    {
      title: 'Проект',
      dataIndex: 'project',
      key: 'project',
      render: project => project?.name,
    },
    {
      title: 'Направление',
      dataIndex: 'direction',
      key: 'direction',
      render: direction => direction?.name,
    },
    {
      title: 'Комментарий',
      dataIndex: 'comments',
      key: 'comments',
      width: '255px',
    },
    {
      title: 'ДЕЙСТВИЯ',
      dataIndex: 'actions',
      key: 'actions',
      width: '380px',
    },
  ];
  const managerColumns: ColumnsType = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 355,
    },
    {
      title: 'Дата загрузки',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '10%',
      align: 'center',
      render: (date: string) => moment(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Версия',
      dataIndex: 'lastVersion',
      key: 'lastVersion',
      width: '127px',
      render: (lastVersion: TemplateVersion) =>
        lastVersion ? (
          <Button type="link" onClick={() => handleDownloadFile(lastVersion.file)} style={{ padding: 0 }}>
            Версия {lastVersion.version}
          </Button>
        ) : (
          'Нет версий'
        ),
    },
    {
      title: 'Комментарий',
      dataIndex: 'comments',
      key: 'comments',
      width: '255px',
    },
  ];

  const handleExpand = async (expanded: boolean, record: TemplateVersion) => {
    setExpandedRowTemplateId(record.id);
  };

  useEffect(() => {
    if (!expandedRowTemplateId) return;
    if (templateVersionsQuery.isSuccess && expandedRowTemplateId) {
      setNestedData(state => ({
        ...state,
        [expandedRowTemplateId]: templateVersionsQuery.data?.data,
      }));
    }
  }, [templateVersionsQuery.isFetching]);

  const expandedRowRender = (record: TemplateVersion) => {
    const data = nestedData[record.id] as TemplateVersion[];

    return (
      <>
        {!data && templateVersionsQuery.isFetching && <Skeleton avatar title={false} loading={true} active />}
        {data &&
          data?.map(v => (
            <List.Item
              key={v.id}
              actions={
                !isManager
                  ? [
                      <Button
                        type={'link'}
                        style={{ padding: 0 }}
                        onClick={() => {
                          selectedTemplateVersion.current = {
                            id: v.id,
                            file: [generateFileApiToForm(v.file)],
                          };
                          setShowCreateTemplateVersionModal(true);
                        }}
                      >
                        Изменить версию
                      </Button>,
                    ]
                  : []
              }
            >
              <List.Item.Meta
                title={
                  <div>
                    Версия {v.version} - {getFullNameUser(v.creator)}
                  </div>
                }
                description={
                  <Button type={'link'} style={{ padding: 0 }} onClick={() => handleDownloadFile(v.file)}>
                    {v.file.originalFileName}
                  </Button>
                }
              />
              <div>
                <div>
                  <b>Дата создания:</b> {dayjs(v.createdAt).format(RU_DATE_TIME)}
                </div>
                <div>
                  <b>Дата изменения:</b> {dayjs(v.file.createdAt).format(RU_DATE_TIME)}
                </div>
              </div>
            </List.Item>
          ))}
      </>
    );
  };

  const contractsTypesData = data?.data?.map(template => ({
    ...template,
    key: template.id,
    actions: (
      <Space>
        <Button
          onClick={() => {
            selectedTemplate.current = template;
            setShowCreateTemplateModal(true);
          }}
        >
          Изменить шаблон
        </Button>
        <Button
          onClick={() => {
            selectedTemplateVersion.current = {
              templateId: template.id,
            };
            setShowCreateTemplateVersionModal(true);
          }}
        >
          Добавить версию
        </Button>
      </Space>
    ),
  }));

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      {!isManager ? (
        <Button
          onClick={() => {
            selectedTemplate.current = null;
            setShowCreateTemplateModal(true);
          }}
          type={'primary'}
          style={{ margin: '20px 0' }}
        >
          ДОБАВИТЬ
        </Button>
      ) : null}
      <Row gutter={[20, 20]} align={'bottom'}>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label="Проект" labelCol={{ span: 24 }}>
            <Select
              allowClear
              value={projectId}
              onChange={data => {
                setProjectDirectionId(undefined);
                setProjectId(data);
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  projectId: data,
                  directionId: undefined,
                });
              }}
            >
              <Select.OptGroup label={'Выберите проект'} />
              {!!projects &&
                projects?.map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        {projectId && (
          <Col xs={24} sm={24} md={6}>
            <Form.Item label="Направление" labelCol={{ span: 24 }}>
              <Select
                allowClear
                value={projectDirectionId}
                onChange={data => {
                  setProjectDirectionId(data);
                  setPaginationAndSearchParams({
                    ...paginationAndSearchParams,
                    directionId: data,
                  });
                }}
              >
                <Select.OptGroup label={'Выберите проект'} />
                {!!projectsDirection &&
                  projectsDirection?.map(p => (
                    <Option value={p.id} key={p.id}>
                      {p.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      <CustomTable
        tableKey={'TemplatesTable'}
        columns={!isManager ? columns : managerColumns}
        dataSource={contractsTypesData}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
        expandable={{
          expandedRowRender: record => <List>{expandedRowRender(record)}</List>,
          rowExpandable: record => !!record.lastVersion,
          onExpand: handleExpand,
        }}
      />
      <CreateTemplateForm
        type={type}
        setShowModal={setShowCreateTemplateModal}
        showModal={showCreateTemplateModal}
        selectedTemplate={selectedTemplate.current}
      />
      <ControlTemplateVersionForm
        showModal={showCreateTemplateVersionModal}
        setShowModal={setShowCreateTemplateVersionModal}
        selectedTemplateVersion={selectedTemplateVersion.current}
      />
    </>
  );
};

export default TemplatesTable;
