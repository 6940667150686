import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth/AuthSlice';
import tableReducer from './slices/table/TableSlice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from './services';
import { debounce } from 'lodash';

const KEY = 'redux';

export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export async function saveState(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    // Ignore
  }
}

const rootReducer = combineReducers({
  authReducer,
  tableReducer,
  [api.reducerPath]: api.reducer,
});

export const setupStore = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
});

setupStore.subscribe(
  debounce(() => {
    saveState({
      tableReducer: setupStore.getState().tableReducer,
    });
  }, 800)
);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof setupStore.dispatch;
setupListeners(setupStore.dispatch);
