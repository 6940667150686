import { api } from '../index';
import { IContractType } from '../../../core/types/entities';
export const contractsTypesApi = api.injectEndpoints({
  endpoints: builder => ({
    getContractsTypes: builder.query<IContractType[], void>({
      query: () => ({
        url: '/contracts/types',
      }),
      providesTags: ['contracts-types'],
    }),
    getContractType: builder.query<IContractType, number>({
      query: id => ({
        url: `/contracts/types/${id}`,
      }),
    }),
    addContractType: builder.mutation<IContractType, { name: string }>({
      query: data => ({
        url: '/contracts/types',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['contracts-types'],
    }),
    updateContractType: builder.mutation<IContractType, [number, { name: string }]>({
      query: ([id, data]) => ({
        url: `/contracts/types/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['contracts-types'],
    }),
    removeContractType: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['contracts-types'],
    }),
  }),
});

export const {
  useGetContractsTypesQuery,
  useGetContractTypeQuery,
  useAddContractTypeMutation,
  useUpdateContractTypeMutation,
  useRemoveContractTypeMutation,
} = contractsTypesApi;
