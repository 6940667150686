import { api } from '../index';
import { IServiceUnit } from '../../../core/types/entities';
export const serviceUnitsApi = api.injectEndpoints({
  endpoints: builder => ({
    getServiceUnits: builder.query<IServiceUnit[], void>({
      query: () => ({
        url: '/actions/units',
      }),
      providesTags: ['service-units'],
    }),
    getServiceUnit: builder.query<IServiceUnit, number>({
      query: id => ({
        url: `/actions/units/${id}`,
      }),
    }),
    addServiceUnit: builder.mutation<IServiceUnit, { name: string }>({
      query: data => ({
        url: '/actions/units',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['service-units'],
    }),
    updateServiceUnit: builder.mutation<IServiceUnit, [number, { name: string }]>({
      query: ([id, data]) => ({
        url: `/actions/units/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['service-units'],
    }),
    removeServiceUnit: builder.mutation<void, number>({
      query: id => ({
        url: `/actions/units/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['service-units'],
    }),
  }),
});

export const {
  useGetServiceUnitsQuery,
  useGetServiceUnitQuery,
  useAddServiceUnitMutation,
  useUpdateServiceUnitMutation,
  useRemoveServiceUnitMutation,
} = serviceUnitsApi;
