import { ChangeEvent, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { Button, Col, Dropdown, Form, Input, MenuProps, Modal, Row, Select, Tag } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { RolesEnum, UserStateEnum } from '../../core/types/enums';
import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import UserFormModal from '../Modals/UserFormModal';
import { IUser } from '../../core/types/entities';
import { ISearchUsersPageQuery } from '../../core/types/query';
import {
  useBanUserMutation,
  useGetUsersQuery,
  useResetUserPasswordMutation,
  useUnBanUserMutation,
} from '../../store/services/users';
import { toast } from 'react-toastify';
import { getFullNameUser, getPosTableNum, getUserRoleName } from '../../core/utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import TelegramLinks from '../UI/TelegramLinks';
import SwitchAccountModal from '../Modals/SwitchAccountModal';

const { confirm } = Modal;

const UsersTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined);
  const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] = useState(false);
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchUsersPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
    sort: 'last_name,asc',
    roles: `${RolesEnum.ADMIN}, ${RolesEnum.MANAGER}`,
  });
  const { data } = useGetUsersQuery(paginationAndSearchParams);
  const [banUser, banStates] = useBanUserMutation();
  const [unbanUser] = useUnBanUserMutation();
  const [resetPassword, resetPasswordStates] = useResetUserPasswordMutation();

  const users = data?.data?.map((user, index) => ({
    ...user,
    fullName: getFullNameUser(user),
    posNum: getPosTableNum(
      index,
      paginationAndSearchParams.page || DEFAULT_PAGE,
      paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
    ),
    key: user.id,
  }));
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;

  useEffect(() => {
    if (banStates.isError) {
      toast.error('Невозможно заблокировать администратора!');
    }
    if (resetPasswordStates.isSuccess) {
      toast.success('Новый пароль отправлен на почту');
    }
    if (resetPasswordStates.isError) {
      toast.error('Произошла ошибка при сбросе пароля');
    }
  }, [banStates.isError, resetPasswordStates.isSuccess, resetPasswordStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'РОЛЬ',
      dataIndex: 'role',
      key: 'role',
      render: (role: string, data: unknown) => {
        const user = data as IUser;
        return <Tag key={'role' + user.id}>{getUserRoleName(role)}</Tag>;
      },
    },
    {
      title: 'СТАТУС',
      dataIndex: 'state',
      key: 'state',
      render: (state: string, data: unknown) => {
        const user = data as IUser;
        return (
          <Tag color={state === UserStateEnum.CONFIRMED ? 'green' : 'red'} key={'state' + user.id}>
            {state === UserStateEnum.CONFIRMED ? 'Активный' : 'Заблокирован'}
          </Tag>
        );
      },
    },
    {
      title: 'ТЕЛЕГРАМ',
      dataIndex: 'telegram',
      key: 'telegram',
      render: (path: string) => <TelegramLinks path={path} />,
    },
    {
      title: 'Почта',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'ТЕЛЕФОН',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: text => <div style={{ minWidth: '150px' }}>{text}</div>,
    },
    {
      title: 'ДЕЙСТВИЯ',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (action: unknown, data: unknown) => {
        const user = data as IUser;
        return (
          <Dropdown menu={{ items: renderDropDownItems(user) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const renderDropDownItems = (userData: IUser) => {
    const items: MenuProps['items'] = [
      {
        key: 'drop-down-item-1',
        onClick: () => {
          const user = users?.find(user => user.id === userData.id);
          setSelectedUser(user);
          setIsModalOpen(true);
        },
        label: 'Редактировать',
      },
      {
        key: 'drop-down-item-2',
        onClick: () => {
          window.open(`/users/histories/${userData.id}`, '_blank');
        },
        label: 'История изменений',
      },
      {
        key: 'drop-down-item-3',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите сбросить пароль пользователю?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              resetPassword(userData.id);
            },
          });
        },
        label: 'Сбросить пароль',
      },
    ];

    if (userData.role !== RolesEnum.ADMIN) {
      items.push({
        key: 'drop-down-item-5',
        onClick: () => {
          setSelectedUser(userData);
          setIsSwitchAccountModalOpen(true);
        },
        label: 'Войти',
      });
    }

    if (userData.state === UserStateEnum.CONFIRMED && userData.role !== RolesEnum.ADMIN) {
      items.push({
        key: 'drop-down-item-4',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите заблокировать пользователя?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              banUser(userData.id);
            },
          });
        },
        label: 'Заблокировать',
        danger: true,
      });
    }

    if (userData.state === UserStateEnum.BANNED) {
      items.push({
        key: 'drop-down-item-4',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите разблокировать пользователя?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              unbanUser(userData.id);
            },
          });
        },
        label: 'Разблокировать',
      });
    }

    return items;
  };

  const handleSearch = debounce((value: ChangeEvent<HTMLInputElement>) => {
    const findText = value.target.value;
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: 0,
      query: findText,
    });
  }, 500);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(undefined);
  };

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <Button type={'primary'} onClick={() => setIsModalOpen(true)}>
            Создать пользователя
          </Button>
        </Col>
        <Col span={10}>
          <Form.Item label="Поиск по ФИО">
            <Input allowClear placeholder="Введите ФИО" onChange={handleSearch} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={'Роль'}>
            <Select
              onChange={curRole => {
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  roles: curRole !== 'all' ? curRole : `${RolesEnum.ADMIN}, ${RolesEnum.MANAGER}`,
                });
              }}
              defaultValue={'all'}
            >
              <Select.OptGroup label={'Роль пользователя'} />
              <Select.Option value={'all'}>Все</Select.Option>
              {Object.values(RolesEnum)
                .filter(role => role !== RolesEnum.PUBLIC && role !== RolesEnum.AGENT)
                .map(role => (
                  <Select.Option key={role}>{getUserRoleName(role)}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <CustomTable
        tableKey={'UsersTable'}
        dataSource={users}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <SwitchAccountModal
        open={isSwitchAccountModalOpen}
        setClose={() => setIsSwitchAccountModalOpen(false)}
        userId={selectedUser?.id}
      />
      <UserFormModal
        initialData={selectedUser}
        userId={selectedUser?.id}
        open={isModalOpen}
        setClose={handleCloseModal}
      />
    </>
  );
};

export default UsersTable;
