import { FC, useState } from 'react';
import { ISearchDealHistoriesQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import { RU_DATE } from '../../core/constants/dateFormatting';
import CustomTable from '../UI/Table';
import { useGetDealHistoriesQuery } from '../../store/services/deals';
import dayjs from 'dayjs';
import { getContractStatusName, getFullNameUser } from '../../core/utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';

interface IDealsHistoriesTable {
  id: string | undefined;
}

const DealsHistoriesTable: FC<IDealsHistoriesTable> = ({ id }) => {
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchDealHistoriesQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
    dealId: id,
  });
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const dealHistories = useGetDealHistoriesQuery(paginationAndSearchParams);

  const dealsData = dealHistories.data?.data?.map(deal => ({
    ...deal,
    changedBy: getFullNameUser(deal.creator),
    key: deal.id,
  }));

  const columns: ColumnsType = [
    {
      title: 'Соглашение №',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Обновил/а',
      dataIndex: 'changedBy',
      key: 'changedBy',
    },
    {
      title: 'Дата соглашения',
      dataIndex: 'fromDate',
      key: 'fromDate',
      align: 'center',
      render: date => date && dayjs(date).format(RU_DATE),
    },
    {
      title: 'Срок действия',
      dataIndex: 'toDate',
      key: 'toDate',
      align: 'center',
      render: date => date && dayjs(date).format(RU_DATE),
    },
    {
      title: 'Статус',
      dataIndex: 'documentStatus',
      key: 'documentStatus',
      render: status => {
        return getContractStatusName(status);
      },
    },
    {
      title: 'Комментарии',
      dataIndex: 'comments',
      key: 'comments',
    },
  ];
  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      <CustomTable
        tableKey={'DealsHistoriesTable'}
        dataSource={dealsData}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: dealHistories.data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
    </>
  );
};

export default DealsHistoriesTable;
