import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import { Button, Form, Input, Modal } from 'antd';
import {
  useAddTaxStatusMutation,
  useGetTaxStatusesQuery,
  useRemoveTaxStatusMutation,
  useUpdateTaxStatusMutation,
} from '../../store/services/contracts/tax-status';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ITaxStatus } from '../../core/types/entities';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';

const { confirm } = Modal;
const TaxStatusTable = () => {
  const { data } = useGetTaxStatusesQuery();
  const [addTaxStatus, addTaxStatusStates] = useAddTaxStatusMutation();
  const [updateTaxStatus, updateTaxStatusStates] = useUpdateTaxStatusMutation();
  const [removeTaxStatus, removeTaxStatusStates] = useRemoveTaxStatusMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedTaxStatus, setSelectedTaxStatus] = useState<ITaxStatus | undefined>(undefined);
  const { role } = useAppSelector(state => state.authReducer);

  const taxStatusesData = data?.map((status, index) => ({
    ...status,
    posNum: index + 1,
    key: status.id,
  }));

  useEffect(() => {
    if (addTaxStatusStates.isSuccess) {
      toast.success('Налоговый статус успешно добавлен!');
      handleCloseModal();
    }
    if (addTaxStatusStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (removeTaxStatusStates.isError) {
      toast.error('Произошла ошибка при удалении налогового статуса');
    }
  }, [addTaxStatusStates.isSuccess, addTaxStatusStates.isError, removeTaxStatusStates.isError]);

  useEffect(() => {
    if (updateTaxStatusStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateTaxStatusStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateTaxStatusStates.isSuccess, updateTaxStatusStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'СТАТУС',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'ДЕЙСТВИЯ',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const taxStatus = data as ITaxStatus;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              data-testid={`edit-tax-status-${taxStatus.id}`}
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue(taxStatus);
                setSelectedTaxStatus(taxStatus);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              data-testid={`delete-tax-status-${taxStatus.id}`}
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить налоговый статус?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveTaxStatus(taxStatus.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: { name: string }) => {
    if (selectedTaxStatus) {
      return updateTaxStatus([selectedTaxStatus.id, data]);
    }
    addTaxStatus(data);
  };

  const handleRemoveTaxStatus = (id: number) => {
    removeTaxStatus(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTaxStatus(undefined);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable tableKey={'TaxStatusTable'} columns={columns} dataSource={taxStatusesData} pagination={false} />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(selectedTaxStatus ? 'Редактировать' : 'Добавить') + ' налоговый статус'}
        okText={selectedTaxStatus ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addTaxStatusStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Статус'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Статус" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TaxStatusTable;
