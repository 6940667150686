import Title from 'antd/es/typography/Title';
import HomeworksByAgentTable from '../../components/Tables/HomeworksByAgentTable';

const UploadHomeworksByAgents = () => {
  return (
    <>
      <Title>Загруженные ДЗ по контрагенту</Title>
      <HomeworksByAgentTable />
    </>
  );
};

export default UploadHomeworksByAgents;
