export enum RolesEnum {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  AGENT = 'AGENT',
  PUBLIC = 'PUBLIC',
}

export enum UserStateEnum {
  CONFIRMED = 'CONFIRMED',
  BANNED = 'BANNED',
}

export enum ContractStatusesEnum {
  CREATED = 'CREATED',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
}

export type DealStatusesEnum = ContractStatusesEnum;

export enum ContractValidityStatusesEnum {
  VALID = 'VALID',
  COMPETED = 'COMPETED',
  TERMINATED = 'TERMINATED',
}

export enum FixedActionTypesEnum {
  COMMON = 'COMMON',
  INNO_HOMEWORKS_COUNT = 'INNO_HOMEWORKS_COUNT',
  INNO_LESSONS_HOURS = 'INNO_LESSONS_HOURS',
}

export enum TemplateList {
  'CONTRACT' = 'CONTRACT',
  'DEAL' = 'DEAL',
  'REQUEST' = 'REQUEST',
  'ACT' = 'ACT',
  'CERTIFICATE' = 'CERTIFICATE',
  'RECEIPT' = 'RECEIPT',
  'APPLICATION' = 'APPLICATION',
}

export enum ActStatus {
  'NOT_PAID' = 'NOT_PAID',
  'PAID' = 'PAID',
}

export enum CertificateTypeEnum {
  REGISTRATION = 'REGISTRATION',
  INCOME = 'INCOME',
}

export enum TableFiltersItemsEnum {
  FIND_BY_FIO = 'FIND_BY_FIO',
  FIND_BY_AGENT_FIO = 'FIND_BY_AGENT_FIO',
  RESPONSIBLE_USER = 'RESPONSIBLE_USER',
  DATE = 'DATE',
  FROM_DATE = 'FROM_DATE',
  FROM_PAYMENT_DATE = 'FROM_PAYMENT_DATE',
  TO_DATE = 'TO_DATE',
  TO_PAYMENT_DATE = 'TO_PAYMENT_DATE',
  DATE_WITHOUT_REQUEST = 'DATE_WITHOUT_REQUEST',
  PROJECT = 'PROJECT',
  PROJECT_DIRECTION = 'PROJECT_DIRECTION',
  PROJECT_SPECIFICATION = 'PROJECT_SPECIFICATION',
  SIGN_SYSTEM = 'SIGN_SYSTEM',
  TAX_STATUS = 'TAX_STATUS',
  CONTRACT = 'CONTRACT',
  REQUEST = 'REQUEST',
  ACT = 'ACTS',
  ROLE = 'ROLE',
  VALIDITY_STATUS = 'VALIDITY_STATUS',
  WITH_REQUEST = 'WITH_REQUEST',
  PAYMENT_STATUS = 'PAYMENT_STATUS',
}

export enum TypeLessonGroupEnum {
  ALL = '',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}
