import { ChangeEvent, useEffect, useState } from 'react';
import { ISearchDuplicatesHomeworks } from '../../core/types/query';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import { useDuplicatesInnoHomeworksQuery, useGetHomeworksQuery } from '../../store/services/homeworks';
import { useParams } from 'react-router-dom';
import { customFilterOption, getPosTableNum } from '../../core/utils';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { ISO_DATE, RU_DATE_TIME } from '../../core/constants/dateFormatting';
import { Col, Form, Input, Row, Select } from 'antd';
import DatePickerBase from '../UI/DatePickerBase';
import CustomTable from '../UI/Table';
import debounce from 'lodash/debounce';

const DuplicatesInnoHomeworksTable = () => {
  const { id: historyId } = useParams();

  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchDuplicatesHomeworks>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
    historyId: historyId,
  });
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const { data } = useDuplicatesInnoHomeworksQuery(paginationAndSearchParams);
  const { data: dataHomeworks } = useGetHomeworksQuery({
    sort: 'createdAt,desc',
    size: 99999,
  });
  const [defaultHistoryFileName, setDefaultHistoryFileName] = useState('');

  useEffect(() => {
    if (historyId) {
      const findHomework = dataHomeworks?.data?.find(homework => homework.id === +historyId);
      if (findHomework) {
        const homeworkLabel = `${dayjs(findHomework.createdAt).format(RU_DATE_TIME)} ${findHomework.fileName ?? ''}`;
        setDefaultHistoryFileName(homeworkLabel);
      }
    }
  }, [dataHomeworks?.data, historyId]);

  const duplicateInnoHomeworks = data?.data.map((homeWork, index) => ({
    ...homeWork,
    posNum: getPosTableNum(
      index,
      paginationAndSearchParams.page || DEFAULT_PAGE,
      paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
    ),
    key: homeWork.id,
  }));

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  const handleSearch = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const queryText = event.target.value;
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: 0,
      query: queryText,
    });
  }, 500);

  const columns: ColumnsType = [
    {
      title: 'Номер дубликата',
      dataIndex: 'duplicateNumber',
      key: 'duplicateNumber',
      align: 'center',
    },
    {
      title: 'Модуль',
      dataIndex: 'moduleName',
      key: 'moduleName',
      align: 'center',
    },
    {
      title: 'Направление',
      dataIndex: 'directionName',
      key: 'directionName',
      align: 'center',
    },
    {
      title: 'Unti ID',
      dataIndex: 'externalId',
      key: 'externalId',
      align: 'center',
    },
    {
      title: 'Группа',
      dataIndex: 'groupName',
      key: 'groupName',
      align: 'center',
    },
    {
      title: 'Группа тьютора',
      dataIndex: 'tutorGroupName',
      key: 'tutorGroupName',
      align: 'center',
    },
    {
      title: 'ФИО ученика',
      dataIndex: 'studentFullName',
      key: 'studentFullName',
      align: 'center',
    },
    {
      title: 'Задание',
      dataIndex: 'taskName',
      key: 'taskName',
      align: 'center',
    },
    {
      title: 'Дата/время выставления оценки',
      dataIndex: 'markDateTime',
      key: 'markDateTime',
      align: 'center',
      render: date => dayjs(date).format(RU_DATE_TIME),
    },
    {
      title: 'Оценка',
      dataIndex: 'mark',
      key: 'mark',
      align: 'center',
    },
  ];

  return (
    <>
      <Row gutter={[20, 0]}>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label="Поиск по ФИО ученика или учителю" labelCol={{ span: 24 }}>
            <Input allowClear placeholder="Поиск по ФИО ученика или учителю" onChange={handleSearch} />
          </Form.Item>
        </Col>
        {defaultHistoryFileName && (
          <Col xs={24} sm={24} md={6}>
            <Form.Item label="Поиск по истории загрузок" labelCol={{ span: 24 }}>
              <Select
                allowClear
                showSearch
                filterOption={customFilterOption}
                onChange={id =>
                  setPaginationAndSearchParams({
                    ...paginationAndSearchParams,
                    page: 0,
                    historyId: id,
                  })
                }
                defaultValue={defaultHistoryFileName}
              >
                <Select.OptGroup label={'Фильтр по истории загрузок'} />
                {dataHomeworks?.data?.map(homework => (
                  <Select.Option key={homework.id} value={homework.id}>
                    {`${dayjs(homework.createdAt).format(RU_DATE_TIME)} ${homework.fileName ?? ''}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={24} md={6}>
          <Form.Item label={'Дата выставления оценки от'} labelCol={{ span: 24 }}>
            <DatePickerBase
              style={{ width: '100%' }}
              onChange={date =>
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  fromDate: date?.format(ISO_DATE),
                })
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label={'Дата выставления оценки до'} labelCol={{ span: 24 }}>
            <DatePickerBase
              style={{ width: '100%' }}
              onChange={date =>
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  toDate: date?.format(ISO_DATE),
                })
              }
            />
          </Form.Item>
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          Всего: {data?.total}
        </Col>
      </Row>
      <CustomTable
        tableKey={'DuplicatesInnoHomeworksTable'}
        dataSource={duplicateInnoHomeworks ?? []}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
    </>
  );
};

export default DuplicatesInnoHomeworksTable;
