import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TableColumnState {
  showColumns: string[];
  orderedColumns: string[];
}

type IInitialState = Record<string, TableColumnState>;

const initialState: IInitialState = {};

interface SetNewColumnsToTableAction {
  tableKey: string;
  columns: string[];
}

interface HideColumnTableAction {
  tableKey: string;
  columnKey: string;
}

interface ShowColumnTableAction {
  tableKey: string;
  columnKey: string;
}

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setNewColumnsToTable: (state, { payload }: PayloadAction<SetNewColumnsToTableAction>) => {
      state[payload.tableKey] = {
        showColumns: payload.columns,
        orderedColumns: payload.columns,
      };
    },
    setNewOrderedColumnsToTable: (state, { payload }: PayloadAction<SetNewColumnsToTableAction>) => {
      state[payload.tableKey].orderedColumns = payload.columns;
    },
    setNewShowedColumnsToTable: (state, { payload }: PayloadAction<SetNewColumnsToTableAction>) => {
      state[payload.tableKey].showColumns = payload.columns;
    },
    showColumnTable: (state, { payload }: PayloadAction<ShowColumnTableAction>) => {
      state[payload.tableKey].showColumns.push(payload.columnKey);
    },
    hideColumnTable: (state, { payload }: PayloadAction<HideColumnTableAction>) => {
      state[payload.tableKey].showColumns = state[payload.tableKey].showColumns.filter(i => i !== payload.columnKey);
    },
  },
});

export const {
  setNewOrderedColumnsToTable,
  setNewShowedColumnsToTable,
  showColumnTable,
  hideColumnTable,
  setNewColumnsToTable,
} = tableSlice.actions;
export default tableSlice.reducer;
