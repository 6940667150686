import Title from 'antd/es/typography/Title';
import CertificatesTable from '../../components/Tables/CertificatesTable';
const CertificatesPage = () => {
  return (
    <div>
      <Title>Справки</Title>
      <CertificatesTable />
    </div>
  );
};

export default CertificatesPage;
