import { FC, Key, useEffect, useState } from 'react';
import { TemplateList } from '../../core/types/enums';
import { toast } from 'react-toastify';
import { useGetTemplatesQuery, useGetTemplateVersionsQuery } from '../../store/services/templates';
import { Template } from '../../core/types/entities';
import {
  getExceptionMessage,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
  isFetchBaseQueryError,
} from '../../core/utils';
import { useGenerateActsMutation } from '../../store/services/acts';
import DatePickerBase from '../UI/DatePickerBase';
import { Col, Form, Modal, Row, Select } from 'antd';
import { IException } from '../../core/types/response';

interface IContractsRequestsModal {
  open: boolean;
  setClose: () => void;
  documentType: TemplateList;
  ids: Key[];
}

const GenerateActsModal: FC<IContractsRequestsModal> = ({ open, setClose, ids, documentType }) => {
  const [form] = Form.useForm();
  const templates = useGetTemplatesQuery({
    size: 999,
    type: TemplateList.ACT,
  });
  const [generateActs, generateActsStates] = useGenerateActsMutation();
  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>(undefined);
  const templatesVersions = useGetTemplateVersionsQuery(selectedTemplate?.id || 0);

  useEffect(() => {
    if (generateActsStates.isSuccess) {
      toast.success('Акты успешно сформированы');
      handleCloseModal();
    }
    if (isFetchBaseQueryError(generateActsStates.error)) {
      toast.error(getExceptionMessage(generateActsStates.error.data as IException) || 'Ошибка при формировании актов');
    }
  }, [generateActsStates.isSuccess, generateActsStates.isError]);
  const handleGenerateActs = () => {
    const formData = form.getFieldsValue();
    const result = {
      ...formData,
      patternVersionId: formData.patternVersionId,
    };
    if (documentType === TemplateList.CONTRACT) {
      result['contractIds'] = ids as number[];
    }
    if (documentType === TemplateList.REQUEST) {
      result['requestIds'] = ids as number[];
    }
    generateActs(result);
  };

  const handleCloseModal = () => {
    setSelectedTemplate(undefined);
    form.resetFields();
    setClose();
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={'Сформировать акты'}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={() => form.submit()}
      onCancel={handleCloseModal}
      okText={'Сформировать'}
    >
      <Form form={form} layout={'vertical'} onFinish={handleGenerateActs}>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Дата'}
              key={'date'}
              name={'date'}
              getValueFromEvent={getValueFormDatePicker}
              getValueProps={getValueFormDatePickerProps}
            >
              <DatePickerBase
                style={{ width: '100%' }}
                disabledDate={date => {
                  const dayOfWeek = date.day();
                  return dayOfWeek === 0 || dayOfWeek === 6;
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Дата оплаты'}
              key={'paymentDate'}
              name={'paymentDate'}
              getValueFromEvent={getValueFormDatePicker}
              getValueProps={getValueFormDatePickerProps}
            >
              <DatePickerBase
                style={{ width: '100%' }}
                disabledDate={date => {
                  const dayOfWeek = date.day();
                  return dayOfWeek === 0 || dayOfWeek === 6;
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={'Шаблон'}>
          <Select
            onChange={templateId => {
              setSelectedTemplate(templates?.data?.data.find(template => template.id === templateId));
              form.resetFields(['patternVersionId']);
            }}
            allowClear={true}
            value={selectedTemplate?.id}
          >
            <Select.OptGroup label={'Шаблон'} />
            {templates.data?.data?.map(template => (
              <Select.Option key={template.id} value={template.id}>
                {template.name ? template.name : `Шаблон id ${template.id}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Версия шаблона'}
          key={'patternVersionId'}
          name={'patternVersionId'}
          help={selectedTemplate && templatesVersions.data?.data.length === 0 ? 'Нет доступных версий' : ''}
        >
          <Select disabled={templatesVersions.data?.data.length === 0}>
            <Select.OptGroup label={'Версия шаблона'} />
            {selectedTemplate &&
              templatesVersions.data?.data?.map(templateVersion => (
                <Select.Option key={templateVersion.id} value={templateVersion.id}>
                  Версия № {templateVersion.version}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GenerateActsModal;
