import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import { Button, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IContractType } from '../../core/types/entities';
import {
  useAddContractTypeMutation,
  useGetContractsTypesQuery,
  useRemoveContractTypeMutation,
  useUpdateContractTypeMutation,
} from '../../store/services/contracts/contracts-types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { RolesEnum } from '../../core/types/enums';
import { useAppSelector } from '../../core/hooks/redux';

const { confirm } = Modal;
const ContractTypesTable = () => {
  const { data } = useGetContractsTypesQuery();
  const [addContractType, addContractTypeStates] = useAddContractTypeMutation();
  const [updateContractType, updateContractTypeStates] = useUpdateContractTypeMutation();
  const [removeContractType, removeContractTypeStates] = useRemoveContractTypeMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedContractType, setSelectedContractType] = useState<IContractType | undefined>(undefined);
  const { role } = useAppSelector(state => state.authReducer);

  const contractsTypesData = data?.map((status, index) => ({
    ...status,
    posNum: index + 1,
    key: status.id,
  }));

  useEffect(() => {
    if (addContractTypeStates.isSuccess) {
      toast.success('Новый тип договора успешно добавлен!');
      setShowModal(false);
      form.resetFields();
    }
    if (addContractTypeStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (removeContractTypeStates.isError) {
      toast.error('Произошла ошибка при удалении типа договора');
    }
  }, [addContractTypeStates.isSuccess, addContractTypeStates.isError, removeContractTypeStates.isError]);

  useEffect(() => {
    if (updateContractTypeStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateContractTypeStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateContractTypeStates.isSuccess, updateContractTypeStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'ТИП ДОГОВОРА',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'ДЕЙСТВИЯ',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const contractType = data as IContractType;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              data-testid={`edit-contract-types-${contractType.id}`}
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue(contractType);
                setSelectedContractType(contractType);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              data-testid={`delete-contract-types-${contractType.id}`}
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить тип договора?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveContractType(contractType.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: { name: string }) => {
    if (selectedContractType) {
      return updateContractType([selectedContractType.id, data]);
    }
    addContractType(data);
  };

  const handleRemoveContractType = (id: number) => {
    removeContractType(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedContractType(undefined);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable
        tableKey={'ContractTypesTable'}
        columns={columns}
        dataSource={contractsTypesData}
        pagination={false}
      />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(selectedContractType ? 'Редактировать' : 'Добавить') + ' тип договора'}
        okText={selectedContractType ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addContractTypeStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Тип договора'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Тип договора" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ContractTypesTable;
