import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { toast } from 'react-toastify';
import { ColumnsType } from 'antd/lib/table';
import {
  useAddAgentFunctionMutation,
  useGetAgentFunctionsQuery,
  useRemoveAgentFunctionMutation,
  useUpdateAgentFunctionMutation,
} from '../../store/services/contracts/agent-functions';
import { IAgentFunction } from '../../core/types/entities';
import CustomTable from '../UI/Table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';

const { confirm } = Modal;

const AgentFunctionsTable = () => {
  const { data } = useGetAgentFunctionsQuery();
  const [addAgentFunction, addAgentFunctionStates] = useAddAgentFunctionMutation();
  const [updateAgentFunction, updateAgentFunctionStates] = useUpdateAgentFunctionMutation();
  const [removeAgentFunction, removeAgentFunctionStates] = useRemoveAgentFunctionMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedAgentFunction, setSelectedAgentFunction] = useState<IAgentFunction | undefined>(undefined);
  const { role } = useAppSelector(state => state.authReducer);

  const agentFunctionsData = data?.map((agentFunctions, index) => ({
    ...agentFunctions,
    posNum: index + 1,
    key: agentFunctions.id,
  }));

  useEffect(() => {
    if (addAgentFunctionStates.isSuccess) {
      toast.success('Новая функция контрагента успешно добавлена!');
      setShowModal(false);
      form.resetFields();
    }
    if (addAgentFunctionStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (removeAgentFunctionStates.isError) {
      toast.error('Произошла ошибка при удалении типа договора');
    }
  }, [addAgentFunctionStates.isSuccess, addAgentFunctionStates.isError, removeAgentFunctionStates.isError]);

  useEffect(() => {
    if (updateAgentFunctionStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateAgentFunctionStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateAgentFunctionStates.isSuccess, updateAgentFunctionStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'Функция контрагента',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const agentFunction = data as IAgentFunction;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              data-testid={`edit-agent-function-${agentFunction.id}`}
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue(agentFunction);
                setSelectedAgentFunction(agentFunction);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              data-testid={`delete-agent-function-${agentFunction.id}`}
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить функцию контрагента?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveContractType(agentFunction.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: { name: string }) => {
    if (selectedAgentFunction) {
      return updateAgentFunction([selectedAgentFunction.id, data]);
    }
    addAgentFunction(data);
  };

  const handleRemoveContractType = (id: number) => {
    removeAgentFunction(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAgentFunction(undefined);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable
        tableKey={'AgentFunctionsTable'}
        columns={columns}
        dataSource={agentFunctionsData}
        pagination={false}
      />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(selectedAgentFunction ? 'Редактировать' : 'Добавить') + ' функцию контрагента'}
        okText={selectedAgentFunction ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addAgentFunctionStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Функция контрагента'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Функция контрагента" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AgentFunctionsTable;
