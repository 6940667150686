import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import { Button, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IServiceArea } from '../../core/types/entities';
import {
  useAddServiceAreaMutation,
  useGetServiceAreasQuery,
  useRemoveServiceAreaMutation,
  useUpdateServiceAreaMutation,
} from '../../store/services/contracts/service-areas';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { RolesEnum } from '../../core/types/enums';
import { useAppSelector } from '../../core/hooks/redux';

const { confirm } = Modal;
const ServiceAreasTable = () => {
  const { data } = useGetServiceAreasQuery();
  const [addServiceArea, addServiceAreaStates] = useAddServiceAreaMutation();
  const [updateServiceArea, updateServiceAreaStates] = useUpdateServiceAreaMutation();
  const [removeServiceArea, removeServiceAreaStates] = useRemoveServiceAreaMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedServiceArea, setSelectedServiceArea] = useState<IServiceArea | undefined>(undefined);
  const { role } = useAppSelector(state => state.authReducer);

  const serviceAreasData = data?.map((status, index) => ({
    ...status,
    posNum: index + 1,
    key: status.id,
  }));

  useEffect(() => {
    if (addServiceAreaStates.isSuccess) {
      toast.success('Услуга успешно добавлена!');
      setShowModal(false);
      form.resetFields();
    }
    if (addServiceAreaStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (removeServiceAreaStates.isError) {
      toast.error('Произошла ошибка при удалении услуги');
    }
  }, [addServiceAreaStates.isSuccess, addServiceAreaStates.isError, removeServiceAreaStates.isError]);

  useEffect(() => {
    if (updateServiceAreaStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateServiceAreaStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateServiceAreaStates.isSuccess, updateServiceAreaStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'Услуга',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'ДЕЙСТВИЯ',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const serviceArea = data as IServiceArea;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              data-testid={`edit-service-area-${serviceArea.id}`}
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue(serviceArea);
                setSelectedServiceArea(serviceArea);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              data-testid={`delete-service-area-${serviceArea.id}`}
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить услугу?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveServiceArea(serviceArea.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: { name: string }) => {
    if (selectedServiceArea) {
      return updateServiceArea([selectedServiceArea.id, data]);
    }
    addServiceArea(data);
  };

  const handleRemoveServiceArea = (id: number) => {
    removeServiceArea(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedServiceArea(undefined);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable tableKey={'ServiceAreasTable'} columns={columns} dataSource={serviceAreasData} pagination={false} />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(selectedServiceArea ? 'Редактировать' : 'Добавить') + ' услугу'}
        okText={selectedServiceArea ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addServiceAreaStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Услуга'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Услуга" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ServiceAreasTable;
