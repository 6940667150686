import { api } from '../index';
import { IReceipt, ISearchPage } from '../../../core/types/entities';
import { ISearchReceiptsPageQuery } from '../../../core/types/query';
import { IReceiptCreate } from '../../../core/types/forms';

export const receiptsApi = api.injectEndpoints({
  endpoints: builder => ({
    getReceipts: builder.query<ISearchPage<IReceipt>, ISearchReceiptsPageQuery>({
      query: params => ({
        url: `/receipts`,
        params: params,
      }),
      providesTags: ['receipts'],
    }),
    getReceipt: builder.query<IReceipt, number>({
      query: id => ({
        url: `/receipts/${id}`,
      }),
    }),
    createReceipt: builder.mutation<IReceipt, IReceiptCreate>({
      query: data => ({
        url: `/receipts`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['receipts'],
    }),
    removeReceipt: builder.mutation<void, number>({
      query: id => ({
        url: `/receipts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['receipts'],
    }),
  }),
});

export const { useGetReceiptsQuery, useGetReceiptQuery, useCreateReceiptMutation, useRemoveReceiptMutation } =
  receiptsApi;
