import { FC, Key, useEffect, useState } from 'react';
import { Form, Modal, Select } from 'antd';
import { ContractStatusesEnum, TemplateList } from '../../core/types/enums';
import { useChangeContractStatusMutation } from '../../store/services/contracts/contracts';
import { toast } from 'react-toastify';
import { getContractStatusName, getExceptionMessage, isFetchBaseQueryError } from '../../core/utils';
import { useChangeDealsStatusMutation } from '../../store/services/deals';
import { IException } from '../../core/types/response';
import { IMassAction } from '../../core/types/forms';
import { ISearchContractPageQuery, ISearchDealsPageQuery } from '../../core/types/query';

interface IContractsStatusModal extends IMassAction {
  open: boolean;
  setClose: () => void;
  ids: Key[];
  type: TemplateList | undefined;
  queryParamsForm?: ISearchContractPageQuery | ISearchDealsPageQuery;
}

const DocsStatusModal: FC<IContractsStatusModal> = ({ open, setClose, ids, type, withParams, queryParamsForm }) => {
  const [selectedStatus, setSelectedStatus] = useState<ContractStatusesEnum | null>(null);
  const [changeContractsStatus, changeContractsStatusStates] = useChangeContractStatusMutation();
  const [changeDealsStatus, changeDealsStatusStates] = useChangeDealsStatusMutation();

  useEffect(() => {
    if (changeContractsStatusStates.isSuccess || changeDealsStatusStates.isSuccess) {
      toast.success('Успешно сохранено');
      handleCloseModal();
    }
    if (isFetchBaseQueryError(changeContractsStatusStates.error)) {
      toast.error(
        getExceptionMessage(changeContractsStatusStates.error.data as IException) || 'Произошла ошибка при сохранении'
      );
    }
    if (isFetchBaseQueryError(changeDealsStatusStates.error)) {
      toast.error(
        getExceptionMessage(changeDealsStatusStates.error.data as IException) || 'Произошла ошибка при сохранении'
      );
    }
  }, [
    changeContractsStatusStates.isSuccess,
    changeContractsStatusStates.isError,
    changeDealsStatusStates.isSuccess,
    changeDealsStatusStates.isError,
  ]);
  const handleSaveStatus = () => {
    const data = {
      withParams: withParams,
      entityIds: !withParams ? { ids } : undefined,
    };
    if (selectedStatus && type === TemplateList.CONTRACT) {
      changeContractsStatus([
        selectedStatus,
        {
          ...data,
          contractQueryParamsForm: withParams ? queryParamsForm : undefined,
        },
      ]);
    }
    if (selectedStatus && type === TemplateList.DEAL) {
      changeDealsStatus([
        selectedStatus,
        {
          ...data,
          dealQueryParamsForm: withParams ? queryParamsForm : undefined,
        },
      ]);
    }
  };

  const handleCloseModal = () => {
    setSelectedStatus(null);
    setClose();
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={'Статус'}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={handleSaveStatus}
      onCancel={handleCloseModal}
      okText={'Сохранить'}
    >
      <Form.Item label={'Статус документа'} labelCol={{ span: 24 }} style={{ margin: '30px 0' }}>
        <Select onChange={value => setSelectedStatus(value)} value={selectedStatus}>
          <Select.OptGroup label={'Статус документа'} />
          {Object.values(ContractStatusesEnum).map(contractStatus => (
            <Select.Option key={contractStatus}>{getContractStatusName(contractStatus)}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Modal>
  );
};

export default DocsStatusModal;
