import { CSSProperties, useState } from 'react';
import { ISearchActsPaymentsPageQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import { IActPayment } from '../../core/types/entities';
import CustomTable from '../UI/Table';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import { useGetActsPaymentsQuery } from '../../store/services/acts';
import TelegramLinks from '../UI/TelegramLinks';
import { formatTableColumnsBreakWord, handleDownloadActsPayments } from '../../core/utils';
import { TableFiltersItemsEnum } from '../../core/types/enums';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import AgentDetailsModal from '../Modals/AgentDetailsModal';
import { Button, Col } from 'antd';
import TableFilters from '../TableFilters';
import { useGetSizeTable } from '../../core/hooks/useGetSizeTable';

const ActsPaymentsTable = () => {
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchActsPaymentsPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const actsPayments = useGetActsPaymentsQuery(paginationAndSearchParams);
  const [selectedRequisites, setSelectedRequisites] = useState<string | undefined>(undefined);
  const [RequisitesModalOpen, setRequisitesModalOpen] = useState(false);
  const { isTableWiderThanMainElement } = useGetSizeTable();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { page, size, ...downloadActsPaymentsParams } = paginationAndSearchParams;

  const actsPaymentsData = actsPayments.data?.data?.map((actPayment, index) => ({
    ...actPayment,
    key: actPayment.actNumber + index,
  }));

  const wrapperStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    minWidth: '100px',
    whiteSpace: 'nowrap',
  };

  const columns: ColumnsType = [
    {
      title: 'Проект',
      dataIndex: 'projectName',
      key: 'projectName',
      render: text => <div style={wrapperStyles}>{text}</div>,
    },
    {
      title: 'Направление проекта',
      dataIndex: 'projectDirectionName',
      key: 'projectDirectionName',
      align: 'center',
      render: text => (text ? text : '-'),
    },
    {
      title: 'Ответственный',
      dataIndex: 'fullNameContractResponsibleUser',
      key: 'fullNameContractResponsibleUser',
    },
    {
      title: 'Контрагент',
      dataIndex: 'fullNameAgent',
      key: 'fullNameAgent',
      render: text => <div style={{ whiteSpace: 'nowrap' }}>{text}</div>,
    },
    {
      title: 'ТЕЛЕГРАМ',
      dataIndex: 'telegram',
      key: 'telegram',
      render: (path: string) => (
        <div style={wrapperStyles}>
          <TelegramLinks path={path} />
        </div>
      ),
    },
    {
      title: 'Реквизиты',
      dataIndex: 'requisites',
      key: 'requisites',
      align: 'center',
      render: (requisites: string) => {
        return (
          <Button
            onClick={() => {
              setSelectedRequisites(requisites);
              setRequisitesModalOpen(true);
            }}
            shape="circle"
          >
            <EyeOutlined />
          </Button>
        );
      },
    },
    {
      title: 'Налоговый статус',
      dataIndex: 'taxStatusName',
      key: 'taxStatusName',
    },
    {
      title: 'Дата оплаты',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
    },
    {
      title: 'Статус оплаты',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
    {
      title: 'Номер акта',
      dataIndex: 'actNumber',
      key: 'actNumber',
    },
    {
      title: 'Дата акта',
      dataIndex: 'actDate',
      key: 'actDate',
    },
    {
      title: 'Комментарии к акту',
      dataIndex: 'actComments',
      key: 'actComments',
    },
    {
      title: 'Номер договора',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      render: (_, data) => {
        const actPayment = data as IActPayment;
        if (actPayment) {
          return <Link to={`/contracts/${actPayment.contractId}`}>№ {actPayment?.contractNumber}</Link>;
        }
      },
    },
    {
      title: 'Дата договора',
      dataIndex: 'contractFromDate',
      key: 'contractFromDate',
    },
    {
      title: 'Система подписи',
      dataIndex: 'signSystemName',
      key: 'signSystemName',
    },
    {
      title: 'Услуга',
      dataIndex: 'actionName',
      key: 'actionName',
      render: (_, data) => {
        const actPayment = data as IActPayment;
        return (
          <div style={wrapperStyles}>
            {actPayment.fixedActionsPayments.map((fixedAction, index) => {
              const isBreakActionName = fixedAction.actionName.length >= 60;
              const fixedActionName = fixedAction.actionName;

              return (
                <div
                  key={fixedActionName + index}
                  style={{
                    whiteSpace: isBreakActionName ? 'normal' : 'nowrap',
                    wordBreak: isBreakActionName ? 'break-all' : 'normal',
                    inlineSize: isBreakActionName ? '400px' : 'auto',
                  }}
                >
                  {fixedActionName}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: 'Объем услуг',
      dataIndex: 'actionCount',
      key: 'actionCount',
      render: (_, data) => {
        const actPayment = data as IActPayment;
        return (
          <div style={wrapperStyles}>
            {actPayment.fixedActionsPayments.map((fixedAction, index) => (
              <div key={fixedAction.actionCount + index}>{fixedAction.actionCount}</div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Ед. изм.',
      dataIndex: 'actionUnit',
      key: 'actionUnit',
      render: (_, data) => {
        const actPayment = data as IActPayment;
        return (
          <div style={wrapperStyles}>
            {actPayment.fixedActionsPayments.map((fixedAction, index) => (
              <div key={fixedAction.actionUnit + index}>{fixedAction.actionUnit}</div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Ставка',
      dataIndex: 'actionRate',
      key: 'actionRate',
      render: (_, data) => {
        const actPayment = data as IActPayment;
        return (
          <div style={wrapperStyles}>
            {actPayment.fixedActionsPayments.map((fixedAction, index) => (
              <div key={fixedAction.actionRate + index}>{fixedAction.actionRate}</div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Сумма',
      dataIndex: 'actionSum',
      key: 'actionSum',
      render: (_, data) => {
        const actPayment = data as IActPayment;
        return (
          <div style={wrapperStyles}>
            {actPayment.fixedActionsPayments.map((fixedAction, index) => (
              <div key={fixedAction.actionSum + index}>{fixedAction.actionSum}</div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Сумма перевода',
      dataIndex: 'resultSum',
      key: 'resultSum',
    },
  ];

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      <TableFilters
        visibleRows={2}
        fields={[
          TableFiltersItemsEnum.FIND_BY_AGENT_FIO,
          TableFiltersItemsEnum.PROJECT,
          TableFiltersItemsEnum.PROJECT_DIRECTION,
          TableFiltersItemsEnum.RESPONSIBLE_USER,
          TableFiltersItemsEnum.FROM_PAYMENT_DATE,
          TableFiltersItemsEnum.TO_PAYMENT_DATE,
          TableFiltersItemsEnum.TAX_STATUS,
          TableFiltersItemsEnum.PAYMENT_STATUS,
        ]}
        params={paginationAndSearchParams}
        setParams={setPaginationAndSearchParams}
        footer={
          <Col>
            <Button
              type={'primary'}
              onClick={async () => {
                await handleDownloadActsPayments(downloadActsPaymentsParams);
              }}
            >
              Загрузить реестр оплат
            </Button>
          </Col>
        }
      />
      <CustomTable
        tableKey={'ActsPaymentsTable'}
        dataSource={actsPaymentsData}
        columns={formatTableColumnsBreakWord(columns, isTableWiderThanMainElement)}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: actsPayments.data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <AgentDetailsModal
        requisites={selectedRequisites}
        setClose={() => {
          setRequisitesModalOpen(false);
          setSelectedRequisites(undefined);
        }}
        open={RequisitesModalOpen}
      />
    </>
  );
};

export default ActsPaymentsTable;
