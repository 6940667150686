import { IRequest, Template } from '../../core/types/entities';
import { FC, useEffect, useState } from 'react';
import { useAddRequestMutation, useUpdateRequestMutation } from '../../store/services/requests';
import { IRequestFormCreate } from '../../core/types/forms';
import { useGetContractQuery, useGetContractsQuery } from '../../store/services/contracts/contracts';
import { useGetTemplatesQuery, useGetTemplateVersionsQuery } from '../../store/services/templates';
import { ContractStatusesEnum, TemplateList } from '../../core/types/enums';
import FileUpload from '../UI/FileUpload';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {
  getContractStatusName,
  getExceptionMessage,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
  isFetchBaseQueryError,
  normFile,
} from '../../core/utils';
import DatePickerBase from '../UI/DatePickerBase';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { IException } from '../../core/types/response';

interface IApplicationFormModal {
  open: boolean;
  setClose: () => void;
  initialData?: IRequest;
}

const RequestFormModal: FC<IApplicationFormModal> = ({ open, setClose, initialData }) => {
  const [form] = Form.useForm();
  const [createRequest, createRequestStates] = useAddRequestMutation();
  const [updateRequest, updateRequestStates] = useUpdateRequestMutation();
  const contracts = useGetContractsQuery({
    size: 999,
    withRequests: true,
  });
  const templates = useGetTemplatesQuery({
    size: 999,
    type: TemplateList.REQUEST,
  });
  const [selectedContractId, setSelectedContractId] = useState<string | undefined>(undefined);
  const selectedContract = useGetContractQuery(selectedContractId, { skip: !selectedContractId }).data;
  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>(undefined);
  const templatesVersions = useGetTemplateVersionsQuery(selectedTemplate?.id || 0);
  const isFileUploaded = !!Form.useWatch('fileName', form);
  const isSelectedContract = !!Form.useWatch('contractId', form);

  useEffect(() => {
    if (initialData && open) {
      form.setFieldsValue(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (createRequestStates.isSuccess) {
      toast.success('Заявка успешно создана');
      handleClose();
    }
    if (createRequestStates.isError) {
      toast.error('Ошибка при создании заявки');
    }
  }, [createRequestStates.isSuccess, createRequestStates.isError]);

  useEffect(() => {
    if (updateRequestStates.isSuccess) {
      toast.success('Изменения успешно сохранены');
      handleClose();
    }
    if (isFetchBaseQueryError(updateRequestStates.error)) {
      toast.error(getExceptionMessage(updateRequestStates.error.data as IException) || 'Ошибка при изменении заявки');
    }
  }, [updateRequestStates.isSuccess, updateRequestStates.isError]);

  const handleClose = () => {
    setClose();
    setSelectedTemplate(undefined);
    setSelectedContractId(undefined);
    form.resetFields();
  };

  const handleSubmit = () => {
    const formData = form.getFieldsValue();

    if (dayjs(formData.fromDate).isAfter(dayjs(formData.toDate))) {
      toast.error('Некорректно указана "Дата от" или "Дата до"');
      return;
    }

    if (!initialData) {
      const patternState = !!formData.patternVersionId;
      const result = {
        ...formData,
        fileName: formData.fileName ? formData.fileName[0].uid : null,
        withPattern: patternState,
      } as IRequestFormCreate;
      return createRequest(result);
    }
    if (initialData) {
      return updateRequest([initialData.id, formData]);
    }
    return;
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={!initialData ? 'Создать заявку' : 'Изменить заявку'}
      open={open}
      centered={true}
      okText={!initialData ? 'Создать' : 'Сохранить'}
      cancelText={'Отмена'}
      confirmLoading={createRequestStates.isLoading || updateRequestStates.isLoading}
      onOk={() => form.submit()}
      onCancel={() => handleClose()}
      afterOpenChange={() => form.getFieldInstance('date')?.focus()}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit}>
        <Row>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Дата заявки'}
              key={'date'}
              name={'date'}
              rules={[
                {
                  required: !initialData,
                  message: 'Поле "Дата заявки" обязательное!',
                },
              ]}
              getValueFromEvent={getValueFormDatePicker}
              getValueProps={getValueFormDatePickerProps}
            >
              <DatePickerBase
                style={{ width: '100%' }}
                disabledDate={date => {
                  const dayOfWeek = date.day();
                  return dayOfWeek === 0 || dayOfWeek === 6;
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Дата от'}
              key={'fromDate'}
              name={'fromDate'}
              rules={[
                {
                  required: !initialData,
                  message: 'Поле "Дата от" обязательное!',
                },
              ]}
              getValueFromEvent={getValueFormDatePicker}
              getValueProps={getValueFormDatePickerProps}
            >
              <DatePickerBase
                style={{ width: '100%' }}
                disabledDate={date => {
                  const dayOfWeek = date.day();
                  return dayOfWeek === 0 || dayOfWeek === 6;
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={'Дата до'}
              key={'toDate'}
              name={'toDate'}
              rules={[
                {
                  required: !initialData,
                  message: 'Поле "Дата до" обязательное!',
                },
              ]}
              getValueFromEvent={getValueFormDatePicker}
              getValueProps={getValueFormDatePickerProps}
            >
              <DatePickerBase
                style={{ width: '100%' }}
                disabledDate={date => {
                  const dayOfWeek = date.day();
                  return dayOfWeek === 0 || dayOfWeek === 6;
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={'Комментарии'} key={'comments'} name={'comments'}>
          <Input />
        </Form.Item>
        {!initialData && (
          <>
            <Form.Item label={'Загрузить файл'} name={'fileName'} getValueFromEvent={normFile} valuePropName="fileList">
              <FileUpload
                onChange={() => {
                  form.resetFields(['patternVersionId']);
                  setSelectedTemplate(undefined);
                }}
                accept={'*'}
                disabled={!!selectedTemplate}
              />
            </Form.Item>
            <Form.Item label={'Шаблон'}>
              <Select
                onChange={templateId => {
                  setSelectedTemplate(templates?.data?.data.find(template => template.id === templateId));
                  form.resetFields(['patternVersionId']);
                }}
                allowClear
                disabled={isFileUploaded}
                value={selectedTemplate?.id}
              >
                <Select.OptGroup label={'Шаблон'} />
                {templates.data?.data?.map(template => (
                  <Select.Option key={template.id} value={template.id}>
                    {template.name ? template.name : `Шаблон id ${template.id}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={'Версия шаблона'}
              key={'patternVersionId'}
              name={'patternVersionId'}
              help={selectedTemplate && templatesVersions.data?.data.length === 0 ? 'Нет доступных версий' : ''}
            >
              <Select disabled={templatesVersions.data?.data.length === 0} allowClear>
                <Select.OptGroup label={'Версия шаблона'} />
                {selectedTemplate &&
                  templatesVersions.data?.data?.map(templateVersion => (
                    <Select.Option key={templateVersion.id} value={templateVersion.id}>
                      Версия № {templateVersion.version}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={'Договор'}
              key={'contractId'}
              name={'contractId'}
              rules={[
                {
                  required: !initialData,
                  message: 'Поле "Договор" обязательное!',
                },
              ]}
            >
              <Select onChange={contractId => setSelectedContractId(contractId || undefined)} allowClear>
                <Select.OptGroup label={'Договор'} />
                {contracts.data?.data.map(contract => (
                  <Select.Option key={contract.id} value={contract.id}>
                    № {contract.number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {isSelectedContract && (
              <Form.Item
                label={'Зафиксированные услуги'}
                key={'fixedActionIds'}
                name={'fixedActionIds'}
                rules={[
                  {
                    required: !initialData,
                    message: 'Поле "Зафиксированные услуги" обязательное!',
                  },
                ]}
              >
                <Select mode={'multiple'} allowClear>
                  <Select.OptGroup label={'Зафиксированные услуги'} />
                  {selectedContract?.fixedActions.map(action => (
                    <Select.Option key={action.id} value={action.id}>
                      {action.action.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        )}
        {initialData && (
          <Form.Item
            label={'Статус договора'}
            name={'documentStatus'}
            labelCol={{ span: 24 }}
            style={{ margin: '30px 0' }}
          >
            <Select allowClear>
              <Select.OptGroup label={'Статус договора'} />
              {Object.values(ContractStatusesEnum).map(contractStatus => (
                <Select.Option key={contractStatus}>{getContractStatusName(contractStatus)}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default RequestFormModal;
