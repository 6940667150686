import ContractForm from '../../components/Forms/ContractForm';
import Title from 'antd/es/typography/Title';

const CreateContractPage = () => {
  return (
    <>
      <Title>Создать договор</Title>
      <ContractForm />
    </>
  );
};

export default CreateContractPage;
