import Title from 'antd/es/typography/Title';
import LessonGroupTable from '../../components/Tables/LessonGroupTable';

const Groups = () => {
  return (
    <>
      <Title>Группы</Title>
      <LessonGroupTable />
    </>
  );
};

export default Groups;
