import { Form, Input, Modal, Select } from 'antd';
import { IGroupControl } from '../../core/types/forms';
import { IGroup } from '../../core/types/entities';
import { useEffect } from 'react';
import { useGetUsersQuery } from '../../store/services/users';
import { RolesEnum, TypeLessonGroupEnum } from '../../core/types/enums';
import { useAddGroupMutation, useEditGroupMutation, useValidateGroupLinkMutation } from '../../store/services/groups';
import { toast } from 'react-toastify';
import {
  customFilterOption,
  getFullNameUser,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
} from '../../core/utils';
import { useGetContractsCoursesQuery } from '../../store/services/contracts/courses';
import DatePickerBase from '../UI/DatePickerBase';
import dayjs from 'dayjs';

const { Option } = Select;

interface LessonGroupControlModalProps {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedGroup?: IGroup;
}

const LessonGroupControlModal = ({ showModal, setShowModal, selectedGroup }: LessonGroupControlModalProps) => {
  const [form] = Form.useForm();
  const [addGroup, addGroupStates] = useAddGroupMutation();
  const [editGroup, editGroupStates] = useEditGroupMutation();
  const [validateGroupLink, validateGroupLinkStates] = useValidateGroupLinkMutation();
  const isEdit = !!selectedGroup?.id;
  const agents = useGetUsersQuery({
    roles: RolesEnum.AGENT,
    size: 999,
  });
  const { data: contractsCourses } = useGetContractsCoursesQuery();

  const handleClose = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleSubmitForm = async (data: IGroupControl) => {
    try {
      await validateGroupLink({ url: data.url }).unwrap();

      if (data.finishDate && dayjs(data.startDate).isAfter(dayjs(data.finishDate))) {
        toast.error('Дата "до" не может быть раньше даты "от"');
        return;
      }

      if (isEdit) {
        editGroup({
          ...data,
          id: selectedGroup?.id,
        });
        return;
      }
      addGroup(data);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.status === 403) {
        toast.error('Север отказал в доступе! Проверьте ссылку');
      } else {
        toast.error('Произошла ошибка! Проверьте ссылку!');
      }
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      form.setFieldsValue({ ...selectedGroup, courseId: selectedGroup?.course?.id });
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (addGroupStates.isSuccess) {
      toast.success('Группа успешно добавлена!');
      handleClose();
    }
    if (addGroupStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [addGroupStates.isSuccess, addGroupStates.isError]);

  useEffect(() => {
    if (editGroupStates.isSuccess) {
      toast.success('Группа успешно изменена!');
      handleClose();
    }
    if (editGroupStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [editGroupStates.isSuccess, editGroupStates.isError]);

  return (
    <Modal
      open={showModal}
      centered
      title={isEdit ? 'Изменить группу' : 'Создать группу'}
      okText={'Сохранить'}
      onCancel={() => handleClose()}
      onOk={() => form.submit()}
      confirmLoading={validateGroupLinkStates.isLoading || addGroupStates.isLoading || editGroupStates.isLoading}
      afterOpenChange={() => form.getFieldInstance('name')?.focus()}
    >
      <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
        <Form.Item
          label={'Название'}
          name={'name'}
          rules={[
            {
              required: true,
              message: 'Поле "Название" обязательное!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Ссылка на гугл таблицу'}
          name={'url'}
          rules={[
            {
              required: true,
              message: 'Поле "Ссылка" обязательное!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {!isEdit && (
          <Form.Item
            label={'Контрагент'}
            key={'agentId'}
            name={'agentId'}
            rules={[
              {
                required: true,
                message: 'Поле "Контрагент" обязательное!',
              },
            ]}
          >
            <Select showSearch filterOption={customFilterOption} allowClear>
              <Select.OptGroup label={'Контрагент'} />
              {agents?.data?.data.map(agent => (
                <Select.Option key={agent.id} value={agent.id}>
                  {getFullNameUser(agent)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={'Тип'}
          name={'groupType'}
          rules={[
            {
              required: true,
              message: 'Поле "Тип" обязательное!',
            },
          ]}
        >
          <Select>
            <Select.OptGroup label={'Тип'} />
            <Select.Option value={TypeLessonGroupEnum.ONLINE}>Онлайн</Select.Option>
            <Select.Option value={TypeLessonGroupEnum.OFFLINE}>Офлайн</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={'Курс'}
          name={'courseId'}
          rules={[
            {
              required: true,
              message: 'Поле "Курс" обязательное!',
            },
          ]}
        >
          <Select placeholder="Выберите курс" allowClear>
            <Select.OptGroup label={'Выберите курс'} />
            {!!contractsCourses &&
              contractsCourses?.map(contractCourse => (
                <Option value={contractCourse.id} key={contractCourse.id}>
                  {contractCourse.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={'Дата от'}
          key={'startDate'}
          name={'startDate'}
          getValueFromEvent={getValueFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
          rules={[
            {
              required: true,
              message: 'Поле "Дата от" обязательное!',
            },
          ]}
        >
          <DatePickerBase style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={'Дата до'}
          key={'finishDate'}
          name={'finishDate'}
          getValueFromEvent={getValueFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
        >
          <DatePickerBase style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LessonGroupControlModal;
