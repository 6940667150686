import { api } from '../index';
import { IServiceArea } from '../../../core/types/entities';
export const serviceAreasApi = api.injectEndpoints({
  endpoints: builder => ({
    getServiceAreas: builder.query<IServiceArea[], void>({
      query: () => ({
        url: '/actions',
      }),
      providesTags: ['service-areas'],
    }),
    getServiceArea: builder.query<IServiceArea, number>({
      query: id => ({
        url: `/actions/${id}`,
      }),
    }),
    addServiceArea: builder.mutation<IServiceArea, { name: string }>({
      query: data => ({
        url: '/actions',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['service-areas'],
    }),
    updateServiceArea: builder.mutation<IServiceArea, [number, { name: string }]>({
      query: ([id, data]) => ({
        url: `/actions/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['service-areas'],
    }),
    removeServiceArea: builder.mutation<void, number>({
      query: id => ({
        url: `/actions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['service-areas'],
    }),
  }),
});

export const {
  useGetServiceAreasQuery,
  useGetServiceAreaQuery,
  useAddServiceAreaMutation,
  useUpdateServiceAreaMutation,
  useRemoveServiceAreaMutation,
} = serviceAreasApi;
