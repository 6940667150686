import Title from 'antd/es/typography/Title';
import SignSystemsTable from '../../components/Tables/SignSystemsTable';

const SignSystems = () => {
  return (
    <div>
      <Title>Система подписи</Title>
      <SignSystemsTable />
    </div>
  );
};

export default SignSystems;
