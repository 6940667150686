import Title from 'antd/es/typography/Title';
import HomeworksTable from '../../components/Tables/HomeworksTable';

const Homeworks = () => {
  return (
    <>
      <Title>Загрузки таблиц ДЗ</Title>
      <HomeworksTable />
    </>
  );
};

export default Homeworks;
