import Title from 'antd/es/typography/Title';
import ContractTypesTable from '../../components/Tables/ContractTypesTable';

const ContractTypes = () => {
  return (
    <>
      <Title>Типы договоров</Title>
      <ContractTypesTable />
    </>
  );
};

export default ContractTypes;
