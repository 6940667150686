import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import { useGetProjectsQuery } from '../../../store/services/contracts/projects';
import { useGetProjectsDirectionsQuery } from '../../../store/services/contracts/projects-directions';
import { useGetProjectSpecificationsQuery } from '../../../store/services/contracts/projects-specifications';
import { useGetSignSystemsQuery } from '../../../store/services/contracts/sign-systems';
import { useGetTaxStatusesQuery } from '../../../store/services/contracts/tax-status';
import { useGetContractsTypesQuery } from '../../../store/services/contracts/contracts-types';
import { useGetAgentFunctionsQuery } from '../../../store/services/contracts/agent-functions';
import { useAddContractMutation } from '../../../store/services/contracts/contracts';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useGetUsersQuery } from '../../../store/services/users';
import { FixedActionTypesEnum, RolesEnum, TemplateList } from '../../../core/types/enums';
import { useAppSelector } from '../../../core/hooks/redux';
import { IContractForm } from '../../../core/types/forms';
import { useGetServiceAreasQuery } from '../../../store/services/contracts/service-areas';
import { useGetServiceUnitsQuery } from '../../../store/services/contracts/service-units';
import { useGetTemplatesQuery, useGetTemplateVersionsQuery } from '../../../store/services/templates';
import FileUpload from '../../UI/FileUpload';
import { Template } from '../../../core/types/entities';
import {
  customFilterOption,
  getExceptionMessage,
  getFixedActionTypeName,
  getFullNameUser,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
  isFetchBaseQueryError,
  normFile,
  validateTextForInvalidCharacters,
} from '../../../core/utils';
import { RadioChangeEvent } from 'antd/es/radio/interface';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import DatePickerBase from '../../UI/DatePickerBase';
import { IException } from '../../../core/types/response';
import Title from 'antd/es/typography/Title';

const { Option } = Select;

const ContractForm = () => {
  const [projectId, setProjectId] = useState<number | undefined>(undefined);
  const [projectDirectionId, setProjectDirectionId] = useState<number | undefined>(undefined);

  const { role, user } = useAppSelector(state => state.authReducer);
  const [form] = Form.useForm<IContractForm>();
  const [currentProjectId, setCurrentProjectId] = useState<number | undefined>(undefined);
  const agents = useGetUsersQuery({
    roles: RolesEnum.AGENT,
    size: 999,
  });
  const responsibleUsers = useGetUsersQuery({
    roles: `${RolesEnum.ADMIN}, ${RolesEnum.MANAGER}`,
    size: 999,
  });
  const contractsTypes = useGetContractsTypesQuery();
  const projects = useGetProjectsQuery();
  const projectsDirection = useGetProjectsDirectionsQuery(currentProjectId);
  const projectSpecifications = useGetProjectSpecificationsQuery(currentProjectId);
  const signSystems = useGetSignSystemsQuery();
  const taxStatuses = useGetTaxStatusesQuery();
  const agentFunctions = useGetAgentFunctionsQuery();
  const serviceAreas = useGetServiceAreasQuery();
  const serviceUnits = useGetServiceUnitsQuery();
  const templates = useGetTemplatesQuery({
    size: 999,
    type: TemplateList.CONTRACT,
    projectId: projectId,
    directionId: projectDirectionId,
  });
  const [addContract, addContractStates] = useAddContractMutation();
  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>(undefined);
  const templatesVersions = useGetTemplateVersionsQuery(selectedTemplate?.id || 0);
  const isContractFileUploaded = !!Form.useWatch('fileName', form);
  const [fromDate, toDate] = [Form.useWatch('fromDate', form), Form.useWatch('toDate', form)];
  const [createType, setCreateType] = useState('readyContract');
  const navigate = useNavigate();

  const { data: projectsFilters } = useGetProjectsQuery();
  const { data: projectsDirectionFilters } = useGetProjectsDirectionsQuery(projectId);

  useEffect(() => {
    if (addContractStates.isSuccess) {
      toast.success('Договор успешно создан');
      form.resetFields();
      setSelectedTemplate(undefined);
      navigate('/contracts/');
    }
    if (isFetchBaseQueryError(addContractStates.error)) {
      toast.error(
        getExceptionMessage(addContractStates.error.data as IException) || 'Произошла ошибка, повторите попытку'
      );
    }
  }, [addContractStates.isSuccess, addContractStates.isError]);

  useEffect(() => {
    if (fromDate && toDate) {
      const fromDateObj = dayjs(fromDate);
      const toDateObj = dayjs(toDate);

      if (toDateObj.isBefore(fromDateObj)) {
        toast.warning('Дата "до" не может быть раньше даты "от"');
        form.setFieldValue('fromDate', undefined);
        form.setFieldValue('toDate', undefined);
      }
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    form.resetFields(['projectDirectionId', 'projectSpecificationId']);
  }, [currentProjectId]);

  const handleSubmit = () => {
    const formData = form.getFieldsValue();
    const patternState = !!formData.patternVersionId;
    const result = {
      ...formData,
      withPattern: patternState,
      fileName: formData.fileName ? formData.fileName[0].uid : null,
      withRequests: formData.withRequests === undefined ? false : formData.withRequests,
    };

    if (user && role === RolesEnum.MANAGER) {
      result.responsibleUserId = user.id;
    }

    if (dayjs(result.fromDate).isAfter(dayjs(result.toDate))) {
      toast.error('Некорректно указана дата договора');
      return;
    }

    addContract(result);
  };

  const handleChangeCreateType = (e: RadioChangeEvent) => {
    setCreateType(e.target.value);
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout={'vertical'} style={{ maxWidth: '717px' }}>
      <Radio.Group defaultValue={createType} style={{ marginBottom: 10 }} onChange={handleChangeCreateType}>
        <Radio.Button value="readyContract">Готовый договор</Radio.Button>
        <Radio.Button value="withTemplate">По шаблону</Radio.Button>
      </Radio.Group>
      <Row gutter={[16, 0]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={'Дата от'}
            key={'fromDate'}
            name={'fromDate'}
            getValueFromEvent={getValueFormDatePicker}
            getValueProps={getValueFormDatePickerProps}
            rules={[
              {
                required: true,
                message: 'Поле "Дата от" обязательное!',
              },
            ]}
          >
            <DatePickerBase style={{ width: '100%' }} autoFocus />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={'Дата до'}
            key={'toDate'}
            name={'toDate'}
            getValueFromEvent={getValueFormDatePicker}
            getValueProps={getValueFormDatePickerProps}
            rules={[
              {
                required: true,
                message: 'Поле "Дата до" обязательное!',
              },
            ]}
          >
            <DatePickerBase style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={'Номер'}
        key={'number'}
        name={'number'}
        rules={[
          {
            required: true,
            message: 'Поле "Номер" обязательное!',
          },
          {
            validator: validateTextForInvalidCharacters,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Поле "Порядковый номер" обязательное!',
          },
        ]}
        label={'Порядковый номер'}
        key={'ordinalNumber'}
        name={'ordinalNumber'}
        normalize={value => Number(value)}
      >
        <Input />
      </Form.Item>
      <Form.Item label={'Комментарии'} key={'comments'} name={'comments'}>
        <Input />
      </Form.Item>
      {createType === 'readyContract' && (
        <Form.Item label={'Загрузить документ'} name={'fileName'} getValueFromEvent={normFile} valuePropName="fileList">
          <FileUpload
            onChange={() => {
              form.resetFields(['patternVersionId']);
              setSelectedTemplate(undefined);
            }}
            disabled={!!selectedTemplate}
            accept={'*'}
          />
        </Form.Item>
      )}
      {createType === 'withTemplate' && (
        <>
          <div style={{ marginTop: '40px', marginBottom: '20px' }}>
            <Title level={5}>Фильтры для шаблона</Title>
            <Row gutter={[20, 0]} align={'bottom'}>
              <Col xs={24} sm={24} md={10}>
                <Form.Item label="Фильтр по проекту">
                  <Select
                    allowClear
                    value={projectId}
                    onChange={data => {
                      setProjectId(data);
                      setProjectDirectionId(undefined);
                    }}
                  >
                    <Select.OptGroup label={'Выберите проект'} />
                    {!!projectsFilters &&
                      projectsFilters?.map(p => (
                        <Option value={p.id} key={p.id}>
                          {p.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              {projectId && (
                <Col xs={24} sm={24} md={10}>
                  <Form.Item label="Фильтр по направлению">
                    <Select
                      allowClear
                      value={projectDirectionId}
                      onChange={data => {
                        setProjectDirectionId(data);
                      }}
                    >
                      <Select.OptGroup label={'Выберите направление'} />
                      {!!projectsDirectionFilters &&
                        projectsDirectionFilters?.map(p => (
                          <Option value={p.id} key={p.id}>
                            {p.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </div>
          <Form.Item label={'Шаблон'}>
            <Select
              onChange={templateId => {
                setSelectedTemplate(templates?.data?.data.find(template => template.id === templateId));
                form.resetFields(['patternVersionId']);
              }}
              allowClear
              value={selectedTemplate?.id}
              disabled={isContractFileUploaded}
            >
              <Select.OptGroup label={'Шаблон'} />
              {templates.data?.data?.map(template => (
                <Select.Option key={template.id} value={template.id}>
                  {template.name ? template.name : `Шаблон id ${template.id}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'Версия шаблона'}
            key={'patternVersionId'}
            name={'patternVersionId'}
            help={selectedTemplate && templatesVersions.data?.data.length === 0 ? 'Нет доступных версий' : ''}
          >
            <Select disabled={templatesVersions.data?.data.length === 0} allowClear>
              <Select.OptGroup label={'Версия шаблона'} />
              {selectedTemplate &&
                templatesVersions.data?.data?.map(templateVersion => (
                  <Select.Option key={templateVersion.id} value={templateVersion.id}>
                    Версия № {templateVersion.version}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </>
      )}
      <Form.Item
        label={'Тип договора'}
        key={'typeId'}
        name={'typeId'}
        rules={[
          {
            required: true,
            message: 'Поле "Тип договора" обязательное!',
          },
        ]}
      >
        <Select allowClear>
          <Select.OptGroup label={'Тип договора'} />
          {contractsTypes.data?.map(contractsType => (
            <Select.Option key={contractsType.id} value={contractsType.id}>
              {contractsType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {role === RolesEnum.ADMIN.toString() && (
        <Form.Item
          label={'Ответственный'}
          key={'responsibleUserId'}
          name={'responsibleUserId'}
          rules={[
            {
              required: true,
              message: 'Поле "Ответственный" обязательное!',
            },
          ]}
        >
          <Select allowClear showSearch filterOption={customFilterOption}>
            <Select.OptGroup label={'Ответственный'} />
            {responsibleUsers.data?.data?.map(responsibleUser => (
              <Select.Option key={responsibleUser.id} value={responsibleUser.id}>
                {getFullNameUser(responsibleUser)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item label={'Старший преподаватель'} key={'seniorTeacherId'} name={'seniorTeacherId'}>
        <Select allowClear showSearch filterOption={customFilterOption}>
          <Select.OptGroup label={'Старший преподаватель'} />
          {agents.data?.data?.map(agent => (
            <Select.Option key={agent.id} value={agent.id}>
              {getFullNameUser(agent)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Контрагент'}
        key={'agentId'}
        name={'agentId'}
        rules={[
          {
            required: true,
            message: 'Поле "Контрагент" обязательное!',
          },
        ]}
      >
        <Select allowClear showSearch filterOption={customFilterOption}>
          <Select.OptGroup label={'Контрагент'} />
          {agents.data?.data?.map(agent => (
            <Select.Option key={agent.id} value={agent.id}>
              {getFullNameUser(agent)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Функции контрагента'}
        key={'agentFunctionId'}
        name={'agentFunctionId'}
        rules={[
          {
            required: true,
            message: 'Поле "Функции контрагента" обязательное!',
          },
        ]}
      >
        <Select allowClear>
          <Select.OptGroup label={'Функции контрагента'} />
          {agentFunctions.data?.map(agentFunction => (
            <Select.Option key={agentFunction.id} value={agentFunction.id}>
              {agentFunction.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Проект'}
        key={'projectId'}
        name={'projectId'}
        rules={[
          {
            required: true,
            message: 'Поле "Проект" обязательное!',
          },
        ]}
      >
        <Select onChange={projectId => setCurrentProjectId(projectId)} allowClear>
          <Select.OptGroup label={'Проект'} />
          {projects.data?.map(project => (
            <Select.Option key={project.id} value={project.id}>
              {project.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={'Направление проекта'} key={'projectDirectionId'} name={'projectDirectionId'}>
        <Select allowClear>
          <Select.OptGroup label={'Направление проекта'} />

          {projectsDirection.data?.map(projectDirection => (
            <Select.Option key={projectDirection.id} value={projectDirection.id}>
              {projectDirection.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={'Cпецификация проекта'} key={'projectSpecificationId'} name={'projectSpecificationId'}>
        <Select allowClear>
          <Select.OptGroup label={'Cпецификация проекта'} />

          {projectSpecifications.data?.map(projectSpecification => (
            <Select.Option key={projectSpecification.id} value={projectSpecification.id}>
              {projectSpecification.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Тип подписи'}
        key={'signSystemId'}
        name={'signSystemId'}
        rules={[
          {
            required: true,
            message: 'Поле "Тип подписи" обязательное!',
          },
        ]}
      >
        <Select allowClear>
          <Select.OptGroup label={'Тип подписи'} />

          {signSystems.data?.map(signSystem => (
            <Select.Option key={signSystem.id} value={signSystem.id}>
              {signSystem.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Налоговый статус'}
        key={'taxStatusId'}
        name={'taxStatusId'}
        rules={[
          {
            required: true,
            message: 'Поле "Налоговый статус" обязательное!',
          },
        ]}
      >
        <Select allowClear>
          <Select.OptGroup label={'Налоговый статус'} />

          {taxStatuses.data?.map(taxStatus => (
            <Select.Option key={taxStatus.id} value={taxStatus.id}>
              {taxStatus.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item initialValue={false} key={'withRequests'} name={'withRequests'}>
        <Radio.Group style={{ marginBottom: 10 }}>
          <Radio.Button value={false}>Только акты</Radio.Button>
          <Radio.Button value={true}>Акты с заявками</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Зафиксированные услуги">
        <Form.List name="fixedActionForms">
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <div key={field.key} style={{ margin: '0 0 20px 20px' }}>
                  <Form.Item
                    name={[field.name, 'rate']}
                    label="Ставка"
                    rules={[
                      {
                        required: true,
                        message: 'Поле "Ставка" обязательное!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name={[field.name, 'workCount']} label="Объем работ">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'type']}
                    label="Тип зафиксированной услуги"
                    rules={[
                      {
                        required: true,
                        message: 'Поле "Тип зафиксированной услуги" обязательное!',
                      },
                    ]}
                  >
                    <Select allowClear>
                      <Select.OptGroup label={'Тип фиксированной услуги'} />
                      {Object.values(FixedActionTypesEnum).map(actionType => (
                        <Select.Option key={actionType}>{getFixedActionTypeName(actionType)}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'actionId']}
                    label="Услуга"
                    rules={[
                      {
                        required: true,
                        message: 'Поле "Услуга" обязательное!',
                      },
                    ]}
                  >
                    <Select allowClear>
                      <Select.OptGroup label={'Услуга'} />
                      {serviceAreas.data?.map(serviceArea => (
                        <Select.Option key={serviceArea.id} value={serviceArea.id}>
                          {serviceArea.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'actionUnitId']}
                    label="Единица измерения"
                    rules={[
                      {
                        required: true,
                        message: 'Поле "Единица измерения" обязательное!',
                      },
                    ]}
                  >
                    <Select allowClear>
                      <Select.OptGroup label={'Единица измерения'} />
                      {serviceUnits.data?.map(serviceUnit => (
                        <Select.Option key={serviceUnit.id} value={serviceUnit.id}>
                          {serviceUnit.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button onClick={() => remove(field.name)} danger>
                    Удалить
                  </Button>
                </div>
              ))}
              <Button onClick={() => add()}>Добавить услугу</Button>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Button type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form>
  );
};

export default ContractForm;
