import { api } from '../index';
import { ISearchPageQuery } from '../../../core/types/query';
import { IGroup, ISearchPage } from '../../../core/types/entities';
import { IGroupControl } from '../../../core/types/forms';
import { Key } from 'react';

export const groupsApi = api.injectEndpoints({
  endpoints: builder => ({
    getGroups: builder.query<ISearchPage<IGroup>, ISearchPageQuery>({
      query: params => ({
        url: `/lessons/groups`,
        params: params,
      }),
      providesTags: ['lesson-groups'],
    }),
    getGroup: builder.query<IGroup, number>({
      query: id => ({
        url: `/lessons/groups/${id}`,
      }),
    }),
    addGroup: builder.mutation<IGroup, IGroupControl>({
      query: data => ({
        url: `/lessons/groups`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['lesson-groups'],
    }),
    editGroup: builder.mutation<IGroup, Omit<IGroupControl, 'agentId'>>({
      query: data => ({
        url: `/lessons/groups/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['lesson-groups'],
    }),
    validateGroupLink: builder.mutation<IGroup, Pick<IGroupControl, 'url'>>({
      query: data => ({
        url: `/lessons/validate`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['lesson-groups'],
    }),
    parseGroups: builder.mutation<void, { ids: Key[] }>({
      query: data => ({
        url: `/lessons/parse`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['lesson-groups'],
    }),
    removeGroup: builder.mutation<void, number>({
      query: id => ({
        url: `/lessons/groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['lesson-groups'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useRemoveGroupMutation,
  useAddGroupMutation,
  useEditGroupMutation,
  useValidateGroupLinkMutation,
  useParseGroupsMutation,
} = groupsApi;
