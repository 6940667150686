import { api } from '../index';
import { IRequest, IRequestHistories, ISearchPage } from '../../../core/types/entities';
import { ISearchRequestHistoriesPageQuery, ISearchRequestsPageQuery } from '../../../core/types/query';
import {
  IRequestFormCreate,
  IRequestFormUpdate,
  IGenerateRequestsForm,
  IUploadFilesToEntity,
} from '../../../core/types/forms';

export const requestsApi = api.injectEndpoints({
  endpoints: builder => ({
    getRequests: builder.query<ISearchPage<IRequest>, ISearchRequestsPageQuery>({
      query: params => ({
        url: `/requests`,
        params: params,
      }),
      providesTags: ['requests'],
    }),
    getRequest: builder.query<IRequest, number>({
      query: id => ({
        url: `/requests/${id}`,
      }),
    }),
    getRequestHistories: builder.query<ISearchPage<IRequestHistories>, ISearchRequestHistoriesPageQuery>({
      query: params => ({
        url: `/requests/histories`,
        params: params,
      }),
    }),
    addRequest: builder.mutation<IRequest, IRequestFormCreate>({
      query: applicationData => ({
        url: `/requests`,
        method: 'POST',
        body: applicationData,
      }),
      invalidatesTags: ['requests'],
    }),
    updateRequest: builder.mutation<IRequest, [number, IRequestFormUpdate]>({
      query: ([id, applicationData]) => ({
        url: `/requests/${id}`,
        method: 'PATCH',
        body: applicationData,
      }),
      invalidatesTags: ['requests'],
    }),
    uploadFilesToRequest: builder.mutation<IRequest, [number, IUploadFilesToEntity]>({
      query: ([id, uploadData]) => ({
        url: `/requests/${id}/files`,
        method: 'POST',
        body: uploadData,
      }),
      invalidatesTags: ['requests'],
    }),
    generateRequests: builder.mutation<IRequest[], IGenerateRequestsForm>({
      query: generateFormData => ({
        url: `/requests/generate`,
        method: 'POST',
        body: generateFormData,
      }),
      invalidatesTags: ['requests'],
    }),
    removeRequest: builder.mutation<void, number>({
      query: id => ({
        url: `/requests/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['requests'],
    }),
    regenerateRequestDocuments: builder.mutation<void, number>({
      query: id => ({
        url: `/requests/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['requests'],
    }),
  }),
});

export const {
  useGetRequestsQuery,
  useGetRequestQuery,
  useGetRequestHistoriesQuery,
  useAddRequestMutation,
  useUpdateRequestMutation,
  useUploadFilesToRequestMutation,
  useGenerateRequestsMutation,
  useRemoveRequestMutation,
  useRegenerateRequestDocumentsMutation,
} = requestsApi;
