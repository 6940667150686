import { api } from '../index';
import { ISignSystem } from '../../../core/types/entities';

export const signSystemsApi = api.injectEndpoints({
  endpoints: builder => ({
    getSignSystems: builder.query<ISignSystem[], void>({
      query: () => `/contracts/sign-systems`,
      providesTags: ['sign-systems'],
    }),
    getSignSystem: builder.query<ISignSystem, number>({
      query: id => ({
        url: `/contracts/sign-systems/${id}`,
      }),
    }),
    addSignSystem: builder.mutation<ISignSystem, { name: string }>({
      query: data => ({
        url: '/contracts/sign-systems',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['sign-systems'],
    }),
    updateSignSystem: builder.mutation<ISignSystem, [number, { name: string }]>({
      query: ([id, data]) => ({
        url: `/contracts/sign-systems/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['sign-systems'],
    }),
    removeSignSystem: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/sign-systems/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['sign-systems'],
    }),
  }),
});

export const {
  useGetSignSystemsQuery,
  useAddSignSystemMutation,
  useUpdateSignSystemMutation,
  useRemoveSignSystemMutation,
  useGetSignSystemQuery,
} = signSystemsApi;
