import React, { useEffect, useState } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import AppRouter, { profileConfig } from './AppRouter';
import { useAppDispatch, useAppSelector } from '../core/hooks/redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout, setUser } from '../store/slices/auth/AuthSlice';
import Loader from './Loader';
import { useValidateTokenQuery } from '../store/services/auth';

const { Content, Footer, Sider } = Layout;
const BaseLayout: React.FC = () => {
  const { role, user, accessToken } = useAppSelector(state => state.authReducer);

  const { isLoading, isSuccess, isError } = useValidateTokenQuery(accessToken as string);
  const isTouchable = 'ontouchstart' in window || navigator.maxTouchPoints;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [siderColapsed, setSiderColapsed] = useState<boolean>(!!localStorage.getItem('siderCollapsed'));
  const siderWidth = '300px';

  const menuItems = profileConfig[role]
    .filter(route => route.showInMenu === true)
    .map(route => ({
      label: route.label,
      path: route.path,
      key: route.key,
      icon: route.icon,
      children: route.children,
    }));

  const handleCollapse = (collapsed: boolean) => {
    localStorage.setItem('siderCollapsed', collapsed ? 'true' : '');
    setSiderColapsed(collapsed);
  };

  const handleMenuItemClick: MenuProps['onClick'] = e => {
    if (e.key === 'logout') {
      return dispatch(logout());
    }
    navigate(e.key);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser());
    }
    if (isError) {
      dispatch(logout());
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout hasSider>
      {role !== 'PUBLIC' && (
        <Sider
          style={{
            overflow: (!siderColapsed && 'auto') || 'initial',
            height: '100vh',
            position: 'fixed',
            zIndex: '10',
            padding: 0,
            left: 0,
            top: 0,
            bottom: 0,
            background: '#220D4B',
          }}
          onCollapse={handleCollapse}
          collapsible={!isTouchable}
          collapsed={isTouchable ? true : siderColapsed}
          width={siderWidth}
        >
          <Menu
            onClick={handleMenuItemClick}
            style={{
              fontSize: '16px',
              maxHeight: '100vh',
              overflowY: 'auto',
              paddingBottom: '70px',
            }}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[location.pathname]}
            items={[
              ...menuItems,
              {
                type: 'divider',
                style: {
                  backgroundColor: 'white',
                  margin: '10px 5px',
                },
              },
              {
                label: 'Выход',
                key: 'logout',
                danger: true,
                icon: <LogoutOutlined />,
              },
            ]}
          />
        </Sider>
      )}
      <Layout
        id={'content-layout'}
        style={{ paddingLeft: !siderColapsed && user && !isTouchable ? siderWidth : '80px' }}
      >
        <Content
          style={{
            margin: '24px 16px 0',
            overflow: 'initial',
            minHeight: '100vh',
          }}
        >
          <AppRouter />
        </Content>
        <Footer style={{ textAlign: 'center', background: 'none' }}>
          Maxima Docs ©2023 Created by <a href="https://t.me/neelmachine">@neelmachine</a>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
