import Title from 'antd/es/typography/Title';
import { useParams } from 'react-router-dom';
import ActsHistoriesTable from '../../components/Tables/ActsHistoriesTable';
const ActHistoriesPage = () => {
  const { id } = useParams();
  return (
    <>
      <Title>История изменения акта</Title>
      <ActsHistoriesTable id={id} />
    </>
  );
};

export default ActHistoriesPage;
