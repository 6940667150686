import { api } from '../index';
import { IAgentCreateFormData } from '../../../core/types/forms';
import { IAgentHistory, IAgentInfos, IFieldsExcludeForAgent, ISearchPage } from '../../../core/types/entities';
import {
  ISearchAgentsPageQuery,
  ISearchAgentsHistoriesPageQuery,
  IAgentInfoUpdateQuery,
} from '../../../core/types/query';

export const agentsInfosApi = api.injectEndpoints({
  endpoints: builder => ({
    getAgents: builder.query<ISearchPage<IAgentInfos>, ISearchAgentsPageQuery>({
      query: ({ page, size, query, sort }) => ({
        url: '/users/agent-infos',
        params: { page, size, query, sort },
      }),
      providesTags: ['agent-infos'],
    }),
    getAgentInfos: builder.query<IAgentInfos, number | undefined>({
      query: id => ({
        url: `/users/agent-infos/${id}`,
      }),
    }),
    getAgentHistories: builder.query<ISearchPage<IAgentHistory>, ISearchAgentsHistoriesPageQuery>({
      query: ({ page, size, agentId }) => ({
        url: '/users/agent-infos/histories',
        params: { page, size, agentId: agentId },
      }),
    }),
    registerAgent: builder.mutation<IAgentInfos, IAgentCreateFormData>({
      query: agentData => ({
        url: `/users/agent-infos`,
        method: 'POST',
        body: agentData,
      }),
      invalidatesTags: ['agent-infos'],
    }),
    updateAgentInfos: builder.mutation<IAgentInfos, IAgentInfoUpdateQuery>({
      query: ({ id, data }) => ({
        url: `/users/agent-infos/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['agent-infos'],
    }),
    getFieldsToExcludeForAgent: builder.query<IFieldsExcludeForAgent[], void>({
      query: () => ({
        url: `/fields/to-exclude-for-agent`,
      }),
    }),
  }),
});

export const {
  useGetAgentsQuery,
  useGetAgentInfosQuery,
  useGetAgentHistoriesQuery,
  useRegisterAgentMutation,
  useUpdateAgentInfosMutation,
  useGetFieldsToExcludeForAgentQuery,
} = agentsInfosApi;
