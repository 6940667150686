import Title from 'antd/es/typography/Title';
import { useParams } from 'react-router-dom';
import AgentsHistoriesTable from '../../../components/Tables/AgentsHistoriesTable';
const AgentsHistoriesPage = () => {
  const { id } = useParams();
  return (
    <>
      <Title>История изменения доп. информации контрагента</Title>
      <AgentsHistoriesTable id={id} />
    </>
  );
};

export default AgentsHistoriesPage;
