import UsersTable from '../../components/Tables/UsersTable';
import Title from 'antd/es/typography/Title';

const UsersPage = () => {
  return (
    <>
      <Title>Пользователи</Title>
      <UsersTable />
    </>
  );
};

export default UsersPage;
