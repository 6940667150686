import { api } from '../index';
import { IProject } from '../../../core/types/entities';

export const projectsApi = api.injectEndpoints({
  endpoints: builder => ({
    getProjects: builder.query<IProject[], void>({
      query: () => `/contracts/projects`,
      providesTags: ['projects'],
    }),
    getProject: builder.query<IProject, number>({
      query: id => ({
        url: `/contracts/projects/${id}`,
      }),
    }),
    addProject: builder.mutation<IProject, { name: string }>({
      query: data => ({
        url: '/contracts/projects',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['projects'],
    }),
    updateProject: builder.mutation<IProject, [number, { name: string }]>({
      query: ([id, data]) => ({
        url: `/contracts/projects/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['projects'],
    }),
    removeProject: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/projects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['projects'],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useRemoveProjectMutation,
  useGetProjectQuery,
} = projectsApi;
