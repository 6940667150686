import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { apiUrl } from '../../core/http';
import { logout, setUserByLogin } from '../slices/auth/AuthSlice';
import { getUserDataFromToken } from '../../core/utils';
import { ILogin } from './auth';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();
export const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: (headers, { endpoint }) => {
    if (localStorage.getItem('accessToken') && endpoint !== 'validateToken' && endpoint !== 'refresh') {
      headers.set('Authorization', `Bearer ${localStorage.getItem('accessToken')}`);
    }
    return headers;
  },
});

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  const refreshToken = localStorage.getItem('refreshToken');

  if (
    refreshToken &&
    ((result.error && result.error.status === 401) || (result.error && result.error.status === 406))
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = (await baseQuery(
          {
            url: '/auth/refresh',
            method: 'POST',
            params: {
              refreshToken,
            },
          },
          api,
          extraOptions
        )) as { data: ILogin };
        if (refreshResult.data) {
          localStorage.setItem('accessToken', refreshResult.data.accessToken.token as string);
          localStorage.setItem('refreshToken', refreshResult.data.refreshToken.token as string);
          api.dispatch(setUserByLogin(getUserDataFromToken(refreshResult.data as ILogin)));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'auth',
    'agent-functions',
    'projects-directions',
    'users',
    'tax-status',
    'service-areas',
    'service-units',
    'contracts-types',
    'projects',
    'projects-specifications',
    'sign-systems',
    'contract',
    'contracts',
    'contractsHistories',
    'agent-infos',
    'template',
    'template-version',
    'acts',
    'requests',
    'lesson-groups',
    'homeworks',
    'certificates',
    'receipts',
    'deals',
    'meetings',
    'contracts-courses',
  ],
  endpoints: () => ({}),
});
