import Title from 'antd/es/typography/Title';
import { Tabs, TabsProps } from 'antd';
import TemplatesTable from '../../components/Tables/TemplatesTable';
import { TemplateList } from '../../core/types/enums';

const Templates = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Договоры',
      children: <TemplatesTable type={TemplateList.CONTRACT} />,
    },
    {
      key: '2',
      label: 'Заявки',
      children: <TemplatesTable type={TemplateList.REQUEST} />,
    },
    {
      key: '3',
      label: 'Акты',
      children: <TemplatesTable type={TemplateList.ACT} />,
    },
    {
      key: '4',
      label: 'Доп. соглашения',
      children: <TemplatesTable type={TemplateList.DEAL} />,
    },
  ];

  return (
    <>
      <Title>Шаблоны</Title>
      <Tabs defaultActiveKey="1" items={items} />
    </>
  );
};

export default Templates;
