import { api } from '../index';
import { IContractType } from '../../../core/types/entities';

interface ProjectsDirections {
  id: number;
  name: string;
}

export const projectsDirectionsApi = api.injectEndpoints({
  endpoints: builder => ({
    getProjectsDirections: builder.query<ProjectsDirections[], number | void | null | undefined>({
      query: projectId => `/contracts/projects/directions${projectId ? '?projectId=' + projectId : ''}`,
      providesTags: ['projects-directions'],
    }),
    getProjectsDirection: builder.query<IContractType, number>({
      query: id => ({
        url: `/contracts/projects/directions/${id}`,
      }),
    }),
    addProjectsDirection: builder.mutation<IContractType, { name: string; projectId: string | number }>({
      query: data => ({
        url: '/contracts/projects/directions',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['projects-directions'],
    }),
    updateProjectsDirection: builder.mutation<IContractType, [number, { name: string }]>({
      query: ([id, data]) => ({
        url: `/contracts/projects/directions/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['projects-directions'],
    }),
    removeProjectsDirection: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/projects/directions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['projects-directions'],
    }),
  }),
});

export const {
  useGetProjectsDirectionsQuery,
  useAddProjectsDirectionMutation,
  useUpdateProjectsDirectionMutation,
  useRemoveProjectsDirectionMutation,
  useGetProjectsDirectionQuery,
} = projectsDirectionsApi;
