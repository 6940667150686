import { FC, useRef } from 'react';
import { IAgentInfos } from '../../core/types/entities';
import { Col, Modal, Row } from 'antd';
import { toast } from 'react-toastify';
import { getFullNameUser } from '../../core/utils';
import dayjs from 'dayjs';
import { RU_DATE } from '../../core/constants/dateFormatting';

interface IAgentDetailsModal {
  open: boolean;
  setClose: () => void;
  agentInfos?: IAgentInfos | undefined;
  requisites?: string;
}

const AgentDetailsModal: FC<IAgentDetailsModal> = ({ open, setClose, agentInfos, requisites }) => {
  const textToCopyRef = useRef<HTMLDivElement | null>(null);
  const parseHtmlString = (inputString: string) => {
    const splitByBr = inputString
      .split('<br />')
      .map(row => {
        const parsedRow = row.replace('<b>', '').split('</b>');
        return {
          label: parsedRow[0],
          text: parsedRow[1],
        };
      })
      .filter(item => item.label && item.text);

    return splitByBr;
  };

  const copyToClipboard = () => {
    if (textToCopyRef.current) {
      const textToCopy = textToCopyRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      toast.info('Скопировано в буфер обмена');
    }
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={'Реквизиты'}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={copyToClipboard}
      onCancel={setClose}
      okText={'Скопировать'}
    >
      <div ref={textToCopyRef}>
        {requisites && !agentInfos && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            {parseHtmlString(requisites).map((row, index) => (
              <div key={'requisites_' + index}>
                <b>{row.label}</b> {row.text}
              </div>
            ))}
          </div>
        )}
        {agentInfos && !requisites && (
          <Row gutter={[10, 10]} style={{ marginTop: '20px' }}>
            <Col xs={24} data-testid="info-col">
              <strong>ФИО:</strong> {getFullNameUser(agentInfos?.agent)}
            </Col>
            <Col xs={24} data-testid="info-col">
              <strong>Паспорт:</strong> серия {agentInfos?.passportSeries} № {agentInfos?.passportNumber}
            </Col>
            <Col xs={24} data-testid="info-col">
              <strong>Выдан:</strong> {agentInfos?.passportReleasedBy},{' '}
              {dayjs(agentInfos?.passportReleasedAt).format(RU_DATE)}
            </Col>
            <Col xs={24} data-testid="info-col">
              <strong>ИНН:</strong> {agentInfos?.inn}
            </Col>
            <Col xs={24} data-testid="info-col">
              <strong>Адрес регистрации:</strong> {agentInfos?.registrationAddress}
            </Col>
            {agentInfos?.residentialAddress && (
              <Col xs={24} data-testid="info-col">
                <strong>Адрес проживания:</strong> {agentInfos?.residentialAddress}
              </Col>
            )}
            <Col xs={24} data-testid="info-col">
              <strong>Р/счет:</strong> {agentInfos?.paymentAccount}
            </Col>
            {agentInfos?.ogrnip && (
              <Col xs={24} data-testid="info-col">
                <strong>ОГРНИП:</strong> {agentInfos?.ogrnip}
              </Col>
            )}
            <Col xs={24} data-testid="info-col">
              <strong>Банк получателя:</strong> {agentInfos?.recipientBank}
            </Col>
            <Col xs={24} data-testid="info-col">
              <strong>Корр. счет:</strong> {agentInfos?.corrAccount}
            </Col>
            <Col xs={24} data-testid="info-col">
              <strong>БИК:</strong> {agentInfos?.bik}
            </Col>
            {agentInfos?.bankInn && (
              <Col xs={24} data-testid="info-col">
                <strong>ИНН банка:</strong> {agentInfos?.bankInn}
              </Col>
            )}
            {agentInfos?.bankKpp && (
              <Col xs={24} data-testid="info-col">
                <strong>КПП:</strong> {agentInfos?.bankKpp}
              </Col>
            )}
          </Row>
        )}
      </div>
    </Modal>
  );
};

export default AgentDetailsModal;
