import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import { useParams } from 'react-router-dom';
import { useGetHomeworksQuery, useInnoHomeworksSearchQuery } from '../../store/services/homeworks';
import { customFilterOption, getFullNameUser, getPosTableNum } from '../../core/utils';
import { Col, Form, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { ISO_DATE, RU_DATE_TIME } from '../../core/constants/dateFormatting';
import { useGetAgentsQuery } from '../../store/services/users/agents-infos';
import { ISearchHomeworksByAgent } from '../../core/types/query';
import DatePickerBase from '../UI/DatePickerBase';

const HomeworksByAgentTable = () => {
  const { id: defaultAgentId } = useParams();

  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchHomeworksByAgent>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
    agentId: defaultAgentId,
  });
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const { data } = useInnoHomeworksSearchQuery(paginationAndSearchParams);
  const { data: dataHomeworks } = useGetHomeworksQuery({
    sort: 'createdAt,desc',
    size: 99999,
  });
  const { data: dataAgents } = useGetAgentsQuery({ size: 99999 });
  const [defaultAgentName, setDefaultAgentName] = useState('');

  useEffect(() => {
    if (defaultAgentId) {
      const findAgentName = dataAgents?.data?.find(agent => agent.agent.id === +defaultAgentId);
      setDefaultAgentName(getFullNameUser(findAgentName?.agent));
    }
  }, [dataAgents?.data, defaultAgentId]);

  const homeWorksByAgent = data?.data.map((homeWork, index) => ({
    ...homeWork,
    posNum: getPosTableNum(
      index,
      paginationAndSearchParams.page || DEFAULT_PAGE,
      paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
    ),
    key: homeWork.id,
  }));

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  const columns: ColumnsType = [
    {
      title: 'Модуль',
      dataIndex: 'moduleName',
      key: 'moduleName',
      align: 'center',
    },
    {
      title: 'Направление',
      dataIndex: 'directionName',
      key: 'directionName',
      align: 'center',
    },
    {
      title: 'Unti ID',
      dataIndex: 'externalId',
      key: 'externalId',
      align: 'center',
    },
    {
      title: 'Группа',
      dataIndex: 'groupName',
      key: 'groupName',
      align: 'center',
    },
    {
      title: 'Группа тьютора',
      dataIndex: 'tutorGroupName',
      key: 'tutorGroupName',
      align: 'center',
    },
    {
      title: 'ФИО ученика',
      dataIndex: 'studentFullName',
      key: 'studentFullName',
      align: 'center',
    },
    {
      title: 'Задание',
      dataIndex: 'taskName',
      key: 'taskName',
      align: 'center',
    },
    {
      title: 'Преподаватель',
      dataIndex: ['teacher'],
      key: 'teacher',
      align: 'center',
      render: data => getFullNameUser(data),
    },
    {
      title: 'Дата/время выставления оценки',
      dataIndex: 'markDateTime',
      key: 'markDateTime',
      align: 'center',
      render: date => dayjs(date).format(RU_DATE_TIME),
    },
    {
      title: 'Оценка',
      dataIndex: 'mark',
      key: 'mark',
      align: 'center',
    },
  ];

  return (
    <>
      <Row gutter={[20, 0]}>
        {defaultAgentName && (
          <Col xs={24} sm={24} md={6}>
            <Form.Item label="Фильтр по контрагенту" labelCol={{ span: 24 }}>
              <Select
                allowClear
                showSearch
                filterOption={customFilterOption}
                onChange={id =>
                  setPaginationAndSearchParams({
                    ...paginationAndSearchParams,
                    page: 0,
                    agentId: id,
                  })
                }
                defaultValue={defaultAgentName}
              >
                <Select.OptGroup label={'Фильтр по контрагенту'} />
                {dataAgents?.data?.map(agent => (
                  <Select.Option key={agent.agent.id} value={agent.agent.id}>
                    {getFullNameUser(agent.agent)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col xs={24} sm={24} md={6}>
          <Form.Item label="Поиск по истории загрузок" labelCol={{ span: 24 }}>
            <Select
              allowClear
              showSearch
              filterOption={customFilterOption}
              onChange={id =>
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  historyId: id,
                })
              }
            >
              <Select.OptGroup label={'Фильтр по истории загрузок'} />
              {dataHomeworks?.data?.map(homework => (
                <Select.Option key={homework.id} value={homework.id}>
                  {`${dayjs(homework.createdAt).format(RU_DATE_TIME)} ${homework.fileName ?? ''}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label={'Дата выставления оценки от'} labelCol={{ span: 24 }}>
            <DatePickerBase
              style={{ width: '100%' }}
              onChange={date =>
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  fromDate: date?.format(ISO_DATE),
                })
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label={'Дата выставления оценки до'} labelCol={{ span: 24 }}>
            <DatePickerBase
              style={{ width: '100%' }}
              onChange={date =>
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  toDate: date?.format(ISO_DATE),
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Col
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        Всего: {data?.total}
      </Col>
      <CustomTable
        tableKey={'HomeworksByAgentTable'}
        dataSource={homeWorksByAgent ?? []}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
    </>
  );
};

export default HomeworksByAgentTable;
