import { api } from '../index';
export const fileUploadApi = api.injectEndpoints({
  endpoints: builder => ({
    uploadFile: builder.mutation<string, FormData>({
      query: formData => ({
        url: '/files',
        method: 'POST',
        body: formData,
      }),
    }),
    getFile: builder.query<void, string | undefined>({
      query: name => ({
        url: `/files/${name}`,
      }),
    }),
  }),
});

export const { useUploadFileMutation, useGetFileQuery } = fileUploadApi;
