import jwt_decode from 'jwt-decode';
import { ILogin } from '../../store/services/auth';
import { IFileInfo, IUser } from '../types/entities';
import FileService from '../../services/file.service';
import { toast } from 'react-toastify';
import { apiUrl } from '../http';
import { ISO_DATE, ISO_DATE_TIME } from '../constants/dateFormatting';
import dayjs from 'dayjs';
import {
  ActStatus,
  CertificateTypeEnum,
  ContractStatusesEnum,
  ContractValidityStatusesEnum,
  FixedActionTypesEnum,
  RolesEnum,
  TemplateList,
} from '../types/enums';
import { RcFile } from 'antd/es/upload';
import { IException } from '../types/response';

import {
  actsStatusTranslations,
  certificateTypeTranslations,
  contractStatusTranslations,
  contractValidityStatusTranslations,
  documentTypeTranslations,
  errorTranslations,
  fixedActionTypesTranslations,
  userRoleTranslations,
} from '../constants/translations';
import { slugify } from 'transliteration';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { ISearchActsPaymentsPageQuery } from '../types/query';
import { ColumnsType } from 'antd/lib/table';
import { ReactNode } from 'react';

interface UserByToken {
  sub: string;
  authorities: RolesEnum[];
  uid: number;
}

export const getUserDataFromToken = (tokens: ILogin) => {
  const user: UserByToken = jwt_decode(tokens.accessToken.token);
  const role = user.authorities[0];
  const email = user.sub;
  const id = user.uid;

  return {
    user: {
      email,
      id,
    },
    role,
    accessToken: tokens.accessToken.token,
    refreshToken: tokens.refreshToken.token,
  };
};

export const getUserDataFromAccessToken = (token: string) => {
  const user: UserByToken = jwt_decode(token);
  const role = user.authorities[0];
  const email = user.sub;
  const id = user.uid;

  return {
    user: {
      email,
      id,
    },
    role,
  };
};

export const handleDownloadFile = async (fileInfo: IFileInfo) => {
  try {
    const file = await FileService.getFile(fileInfo.storageFileName);
    const blob = new Blob([file.data], { type: fileInfo.type });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileInfo?.customFileName ? fileInfo.customFileName : fileInfo.originalFileName;
    a.click();
    URL.revokeObjectURL(url);
  } catch (e) {
    toast.error('Возникла ошибка при загрузке файла');
  }
};

export const handleDownloadActsPayments = async (params: Omit<ISearchActsPaymentsPageQuery, 'page' | 'size'>) => {
  try {
    const file = await FileService.getActsPayments(params);
    const blob = new Blob([file.data]);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'reestr-oplat.xlsx';
    a.click();
    URL.revokeObjectURL(url);
  } catch (e) {
    toast.error('Возникла ошибка при загрузке файла');
  }
};

export const normFile = (e: RcFile[] | RcFile) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && [e];
};

export const generateFileUrl = (file?: IFileInfo) => {
  if (file?.url) {
    return `${apiUrl}/${file.url}`;
  }
  return '';
};

export const generateFileApiToForm = (file: IFileInfo) => {
  return {
    name: file.originalFileName,
    status: 'done',
    thumbUrl: '',
    uid: file.storageFileName,
    url: generateFileUrl(file),
  };
};

export const customFilterOption = (
  inputValue: string,
  option:
    | {
        children?: string;
      }
    | undefined
): boolean => {
  return (String(option?.children)?.toLowerCase() || '').includes(inputValue.toLowerCase());
};

export const getValueFormDatePicker = (e: ReturnType<typeof dayjs>) => e?.format(ISO_DATE);

export const getValueWithTimeFormDatePicker = (e: ReturnType<typeof dayjs>) => e?.format(ISO_DATE_TIME);

export const getValueFormDatePickerProps = (value?: ReturnType<typeof dayjs>) => ({
  value: value ? dayjs(value) : undefined,
});

export const getFullNameUser = (user: IUser | undefined) =>
  user ? `${user.lastName} ${user.firstName} ${user.patronymic ?? ''}` : '';

export const getContractStatusName = (status: ContractStatusesEnum | string) => {
  return contractStatusTranslations[status] || null;
};

export const getContractValidityStatusName = (status: ContractValidityStatusesEnum | string) => {
  return contractValidityStatusTranslations[status] || null;
};

export const getUserRoleName = (role: RolesEnum | string) => {
  return userRoleTranslations[role] || null;
};

export const getCertificateTypeName = (type: CertificateTypeEnum | string) => {
  return certificateTypeTranslations[type] || null;
};

export const getActsStatusName = (status: ActStatus | string) => {
  return actsStatusTranslations[status] || null;
};

export const getDocumentTypeName = (type: TemplateList | string) => {
  return documentTypeTranslations[type] || null;
};

export const getExceptionMessage = (error: IException) => {
  return errorTranslations[error.exceptionMessage] || null;
};

export const getFixedActionTypeName = (type: FixedActionTypesEnum | string) => {
  return fixedActionTypesTranslations[type] || null;
};

export const translit = (str: string) =>
  slugify(str, {
    separator: '_',
    trim: true,
  }).toLowerCase();

export const copyToClipboard = (link: string, message?: string) => {
  navigator.clipboard.writeText(link).then(() => toast(message || 'Успешно скопированно в буфер обмена!'));
};

export const openInNewTab = (url: string) => {
  if (typeof window !== 'undefined') {
    window.open(url, '_blank');
  }
};

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
  return typeof error === 'object' && error != null && 'status' in error;
};

export const getPosTableNum = (index: number, page: number, size: number) => {
  return page * size + index + 1;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const validateTextForInvalidCharacters = (rule: any, value: string, callback: (message?: string) => void) => {
  if (/[:*?"<>|]/.test(value)) {
    callback('Текст не должен содержать : * ? " < > |');
  } else {
    callback();
  }
};

export const formatFileName = (fileName: string, customSubFileName: string) => {
  const hashTableFormatFileName = {
    '.docx': true,
    '.pdf': true,
  };

  for (const formatFileKey in hashTableFormatFileName) {
    if (fileName.includes(formatFileKey) && customSubFileName) {
      return `${fileName.slice(0, -formatFileKey.length)} ${customSubFileName}${formatFileKey}`;
    }
  }

  return fileName;
};

export const formatTableColumnsBreakWord = (columns: ColumnsType, isTableWiderThanMainElement: boolean) => {
  if (!isTableWiderThanMainElement) {
    return columns;
  } else {
    return columns.map(column => {
      if (typeof column.title === 'string' && /\s/.test(column.title) && column.title.length > 14) {
        column.title = <span style={{ whiteSpace: 'normal' }}>{column.title}</span>;
        if (!column.render) {
          column.render = data => <div style={{ wordBreak: 'break-all' }}>{data}</div>;
        } else {
          const copyFunction = column.render.bind(this);
          column.render = (data, record, index) => (
            <div style={{ wordBreak: 'break-all' }}>{copyFunction(data, record, index) as ReactNode}</div>
          );
        }
      }
      return column;
    });
  }
};
