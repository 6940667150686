import Title from 'antd/es/typography/Title';
import ProjectsTable from '../../components/Tables/ProjectsTable';

const Projects = () => {
  return (
    <div>
      <Title>Проекты</Title>
      <ProjectsTable />
    </div>
  );
};

export default Projects;
