import { Button, Modal, Table, TableProps, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import './styles.scss';
import { useAppDispatch, useAppSelector } from '../../../core/hooks/redux';
import {
  setNewColumnsToTable,
  setNewOrderedColumnsToTable,
  setNewShowedColumnsToTable,
} from '../../../store/slices/table/TableSlice';
import DndColumnsControl from './components/DndColumnsControl';
import { SettingOutlined } from '@ant-design/icons';
// eslint-disable-next-line  import/no-named-as-default
import styled from 'styled-components';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
interface CustomTableProps extends TableProps<any> {
  tableKey: string;
}

const TableWrapper = styled.div`
  position: relative;
`;

const SettingButton = styled.div`
  position: absolute;
  right: 0;
  top: -35px;
`;

const CustomTable: FC<CustomTableProps> = ({ tableKey, columns, ...restProps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prevState, setPrevState] = useState<{ orderedColumns: string[]; showedColumns: string[] } | null>(null);
  const dispatch = useAppDispatch();
  const orderedColumns = useAppSelector(state => state.tableReducer[tableKey]?.orderedColumns);
  const showedColumns = useAppSelector(state => state.tableReducer[tableKey]?.showColumns);

  useEffect(() => {
    if (orderedColumns && showedColumns) {
      setPrevState({
        orderedColumns: orderedColumns,
        showedColumns: showedColumns,
      });
    }
    if (columns && !orderedColumns && !showedColumns) {
      dispatch(
        setNewColumnsToTable({
          tableKey,
          columns: columns.map(i => i.key) as string[],
        })
      );
    }
  }, []);

  const restorePreviousState = () => {
    if (prevState) {
      dispatch(
        setNewOrderedColumnsToTable({
          tableKey,
          columns: prevState.orderedColumns,
        })
      );
      dispatch(
        setNewShowedColumnsToTable({
          tableKey,
          columns: prevState.showedColumns,
        })
      );
    }
  };

  if (!orderedColumns || !showedColumns) {
    return;
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setPrevState({
      orderedColumns: orderedColumns,
      showedColumns: showedColumns,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    restorePreviousState();
  };

  return (
    <TableWrapper style={{ marginTop: 45 }}>
      <SettingButton>
        <Tooltip title="Управление колонками">
          <Button type="primary" shape="circle" onClick={showModal} icon={<SettingOutlined />} />
        </Tooltip>
      </SettingButton>
      <Table
        rootClassName={'table'}
        rowClassName={'table__row'}
        bordered={true}
        scroll={{ x: true }}
        columns={orderedColumns
          .map(c => columns!.find(i => i.key === c)!)
          .filter(c => {
            if (c) {
              return showedColumns.includes(c.key as string);
            }
          })}
        {...restProps}
      />
      <Modal title="Управление колонками таблицы" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <DndColumnsControl tableColumns={columns!} tableKey={tableKey} />
      </Modal>
    </TableWrapper>
  );
};

export default CustomTable;
