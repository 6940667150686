import { FC, useState } from 'react';
import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import { useGetContractsQuery } from '../../store/services/contracts/contracts';
import { useGetRequestsQuery } from '../../store/services/requests';
import { useGetActsQuery } from '../../store/services/acts';
import { IAct, IContract, IDeal, IFileInfo, IRequest, ISearchPage } from '../../core/types/entities';
import dayjs from 'dayjs';
import { RU_DATE } from '../../core/constants/dateFormatting';
import FileTypeButtonGroup from '../FileTypeButtonGroup';
import { Button } from 'antd';
import DocsUploadModal from '../Modals/DocsUploadModal';
import { getDocumentTypeName } from '../../core/utils';
import { TemplateList } from '../../core/types/enums';
import { useGetDealsQuery } from '../../store/services/deals';

interface IDoc {
  id: number;
  docType: TemplateList;
  number: number;
  wordFile: IFileInfo | null;
  pdfFile: IFileInfo | null;
  signedFile: IFileInfo | null;
  fromDate: string;
}

interface IAgentProfileDocTable {
  agentId: string | undefined;
}

const AgentProfileDocTable: FC<IAgentProfileDocTable> = ({ agentId }) => {
  const contracts = mapDocuments(
    useGetContractsQuery({ agentId: agentId }).data as ISearchPage<IContract>,
    TemplateList.CONTRACT
  );
  const requests = mapDocuments(
    useGetRequestsQuery({ agentId: agentId }).data as ISearchPage<IRequest>,
    TemplateList.REQUEST
  );
  const acts = mapDocuments(useGetActsQuery({ agentId: agentId }).data as ISearchPage<IAct>, TemplateList.ACT);
  const deals = mapDocuments(useGetDealsQuery({ agentId: agentId }).data as ISearchPage<IDeal>, TemplateList.DEAL);
  const [isOpenUploadDocsModal, setIsOpenUploadDocsModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<IDoc | null>(null);

  function mapDocuments(data: ISearchPage<IContract | IAct | IRequest | IDeal>, docType: string) {
    return (data?.data ?? []).map(document => ({
      key: docType + document.id,
      id: document.id,
      docType: docType,
      number: document.number,
      wordFile: document.wordFile,
      pdfFile: document.pdfFile,
      signedFile: document.signedFile,
      fromDate: dayjs('fromDate' in document ? document.fromDate : document.date).format(RU_DATE),
    }));
  }

  const docsData = [...contracts, ...deals, ...requests, ...acts];

  const columns: ColumnsType = [
    {
      title: 'Сформированный документ',
      dataIndex: 'generatedDoc',
      key: 'generatedDoc',
      render: (_, data) => {
        const doc = data as IDoc;
        return (
          <>
            <b>{getDocumentTypeName(doc.docType)} </b>№ {doc.number} <b>от</b> {doc.fromDate}
          </>
        );
      },
    },
    {
      title: 'Скачать Документ',
      dataIndex: 'downloadDoc',
      key: 'downloadDoc',
      align: 'center',
      render: (_, data) => {
        const doc = data as IDoc;
        if (doc.wordFile || doc.pdfFile) {
          return <FileTypeButtonGroup items={[doc.pdfFile as IFileInfo, doc.wordFile as IFileInfo]} />;
        }
      },
    },
    {
      title: 'Подписанный документ',
      dataIndex: 'signedDoc',
      key: 'signedDoc',
      align: 'center',
      render: (_, data) => {
        const doc = data as IDoc;
        if (doc.signedFile) {
          return <FileTypeButtonGroup items={[doc.signedFile]} />;
        }
        return (
          <Button
            onClick={() => handleOpenUploadDocsModal(doc)}
            type={'text'}
            style={{
              color: 'rgb(69, 43, 121)',
              textDecoration: 'underline',
            }}
          >
            <span style={{ textDecoration: 'underline' }}>Загрузить</span>
          </Button>
        );
      },
    },
  ];

  const handleOpenUploadDocsModal = (doc: IDoc) => {
    setIsOpenUploadDocsModal(true);
    setSelectedDoc(doc);
  };
  const handleCloseUploadDocsModal = () => {
    setIsOpenUploadDocsModal(false);
    setSelectedDoc(null);
  };

  return (
    <>
      <CustomTable tableKey={'AgentProfileDocTable'} dataSource={docsData} columns={columns} pagination={false} />
      <DocsUploadModal
        open={isOpenUploadDocsModal}
        setClose={handleCloseUploadDocsModal}
        id={selectedDoc?.id}
        type={selectedDoc?.docType}
      />
    </>
  );
};

export default AgentProfileDocTable;
