import Title from 'antd/es/typography/Title';
import ProjectSpecificationsTable from '../../components/Tables/ProjectSpecificationsTable';

const ProjectSpecifications = () => {
  return (
    <div>
      <Title>Спецификации</Title>
      <ProjectSpecificationsTable />
    </div>
  );
};

export default ProjectSpecifications;
