import Title from 'antd/es/typography/Title';
import ReceiptsTable from '../../components/Tables/ReceiptsTable';

const ReceiptsPage = () => {
  return (
    <div>
      <Title>Чеки</Title>
      <ReceiptsTable />
    </div>
  );
};

export default ReceiptsPage;
