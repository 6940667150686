import Title from 'antd/es/typography/Title';
import TaxStatusTable from '../../components/Tables/TaxStatusTable';

const TaxStatus = () => {
  return (
    <>
      <Title>Налоговый статус</Title>
      <TaxStatusTable />
    </>
  );
};

export default TaxStatus;
