import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { toast } from 'react-toastify';
import { ColumnsType } from 'antd/lib/table';
import { IProjectDirection } from '../../core/types/entities';
import CustomTable from '../UI/Table';
import {
  useAddProjectsDirectionMutation,
  useGetProjectsDirectionsQuery,
  useRemoveProjectsDirectionMutation,
  useUpdateProjectsDirectionMutation,
} from '../../store/services/contracts/projects-directions';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useGetProjectsQuery } from '../../store/services/contracts/projects';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';

const { Option } = Select;
const { confirm } = Modal;

const ProjectDirectionsTable = () => {
  const { data: projects } = useGetProjectsQuery();
  const { data } = useGetProjectsDirectionsQuery();
  const [addProjectsDirection, addProjectsDirectionStates] = useAddProjectsDirectionMutation();
  const [updateProjectsDirection, updateProjectsDirectionStates] = useUpdateProjectsDirectionMutation();
  const [removeProjectsDirection, removeProjectsDirectionStates] = useRemoveProjectsDirectionMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedProjectDirection, setSelectedProjectDirection] = useState<IProjectDirection | undefined>(undefined);
  const { role } = useAppSelector(state => state.authReducer);

  const projectsDirectionsData = data?.map((projectsDirections, index) => ({
    ...projectsDirections,
    posNum: index + 1,
    key: projectsDirections.id,
  }));

  useEffect(() => {
    if (addProjectsDirectionStates.isSuccess) {
      toast.success('Новое направление добавлено!');
      handleCloseModal();
    }
    if (addProjectsDirectionStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (removeProjectsDirectionStates.isError) {
      toast.error('Произошла ошибка при удалении направления');
    }
  }, [addProjectsDirectionStates.isSuccess, addProjectsDirectionStates.isError, removeProjectsDirectionStates.isError]);

  useEffect(() => {
    if (updateProjectsDirectionStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateProjectsDirectionStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateProjectsDirectionStates.isSuccess, updateProjectsDirectionStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'Направление',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const projectDirection = data as IProjectDirection;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              data-testid={`edit-project-direction-${projectDirection.id}`}
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue({
                  name: projectDirection.name,
                  // projectId: projectDirection.project.id,
                });
                setSelectedProjectDirection(projectDirection);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              data-testid={`delete-project-direction-${projectDirection.id}`}
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить направление?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveContractType(projectDirection.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: { name: string; projectId: string | number }) => {
    if (selectedProjectDirection) {
      return updateProjectsDirection([selectedProjectDirection.id, data]);
    }
    return addProjectsDirection(data);
  };

  const handleRemoveContractType = (id: number) => {
    removeProjectsDirection(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProjectDirection(undefined);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable
        tableKey={'ProjectDirectionsTable'}
        columns={columns}
        dataSource={projectsDirectionsData}
        pagination={false}
      />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(selectedProjectDirection ? 'Редактировать' : 'Добавить') + ' направление'}
        okText={selectedProjectDirection ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addProjectsDirectionStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Направление'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Направление" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          {!selectedProjectDirection && (
            <Form.Item
              label={'Проект'}
              key={'projectId'}
              name={'projectId'}
              rules={[
                {
                  required: true,
                  message: 'Поле "Проект" обязательное!',
                },
              ]}
            >
              <Select placeholder="Выберите проект" allowClear>
                <Select.OptGroup label={'Выберите проект'} />
                {!!projects &&
                  projects?.map(p => (
                    <Option value={p.id} key={p.id}>
                      {p.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ProjectDirectionsTable;
