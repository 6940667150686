import { FC, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useGetFakeAuthDataMutation } from '../../store/services/users';
import { IFakeAuthDataResponse } from '../../core/types/response';
import { toast } from 'react-toastify';
import { logout, setUser } from '../../store/slices/auth/AuthSlice';
import { useLoginMutation } from '../../store/services/auth';
import { useAppDispatch } from '../../core/hooks/redux';
import { api } from '../../store/services';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

interface ISwitchAccountModal {
  open: boolean;
  setClose: () => void;
  userId: number | undefined;
}

const SwitchAccountModal: FC<ISwitchAccountModal> = ({ open, setClose, userId }) => {
  const [login, { isSuccess, isError }] = useLoginMutation();
  const [getFakeAuthData] = useGetFakeAuthDataMutation();
  const [fakeAuthData, setFakeAuthData] = useState<IFakeAuthDataResponse | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getFakeData();
  }, [open]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser());
    }
  }, [isSuccess, isError]);
  const getFakeData = async () => {
    if (!userId || !open) {
      return;
    }

    try {
      const response = await getFakeAuthData(userId);
      if ('data' in response) {
        setFakeAuthData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSwitchAccount = async () => {
    if (!fakeAuthData) {
      return;
    }
    logout();
    navigate('/');
    dispatch(api.util.resetApiState());
    await login(fakeAuthData);
    dispatch(setUser());
  };

  const handleClose = () => {
    setFakeAuthData(null);
    setClose();
  };

  const copyToClipboard = () => {
    const agentDetails = `
      Логин: ${fakeAuthData?.login}
      Пароль: ${fakeAuthData?.password}
    `;
    navigator.clipboard.writeText(agentDetails);
    toast.info('Скопировано в буфер обмена');
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={'Войти от имени пользователя'}
      open={open}
      centered={true}
      onCancel={() => handleClose()}
      footer={[
        <Button key="cancel-button" onClick={handleClose}>
          Закрыть
        </Button>,
        <Button key="сlipboard-button" onClick={copyToClipboard}>
          Скопировать
        </Button>,
        <Button key="ok-button" type="primary" onClick={handleSwitchAccount}>
          Войти
        </Button>,
      ]}
    >
      <Row
        gutter={[20, 20]}
        style={{
          marginTop: '30px',
          marginBottom: '30px',
        }}
      >
        <Col span={12}>
          <Title level={5} style={{ textTransform: 'uppercase' }}>
            Логин для входа
          </Title>
          <Text copyable={true}>{fakeAuthData?.login}</Text>
        </Col>
        <Col span={12}>
          <Title level={5} style={{ textTransform: 'uppercase' }}>
            Пароль для входа
          </Title>
          <Text copyable={true}>{fakeAuthData?.password}</Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default SwitchAccountModal;
