import { FC, useEffect } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { getExceptionMessage, isFetchBaseQueryError, normFile } from '../../core/utils';
import FileUpload from '../UI/FileUpload';
import { useUploadFilesToContractMutation } from '../../store/services/contracts/contracts';
import { useUploadFilesToActMutation } from '../../store/services/acts';
import { useUploadFilesToRequestMutation } from '../../store/services/requests';
import { toast } from 'react-toastify';
import { TemplateList } from '../../core/types/enums';
import { useUploadFilesToDealMutation } from '../../store/services/deals';
import { IException } from '../../core/types/response';

interface IDocsUploadModal {
  open: boolean;
  setClose: () => void;
  id: number | undefined;
  type: TemplateList | undefined;
}

const DocsUploadModal: FC<IDocsUploadModal> = ({ open, setClose, id, type }) => {
  const [form] = Form.useForm();
  const [uploadFilesToContract, uploadFilesToContractState] = useUploadFilesToContractMutation();
  const [uploadFilesToAct, uploadFilesToActState] = useUploadFilesToActMutation();
  const [uploadFilesToRequest, uploadFilesToRequestState] = useUploadFilesToRequestMutation();
  const [uploadFilesToDeal, uploadFilesToRequestDeal] = useUploadFilesToDealMutation();
  const isEmptyWordFile = !Form.useWatch('wordFileName', form);
  const isEmptySignedFile = !Form.useWatch('signedFileName', form);

  const requestStates = [
    uploadFilesToContractState,
    uploadFilesToActState,
    uploadFilesToRequestState,
    uploadFilesToRequestDeal,
  ];
  const isError = requestStates.some(state => state.isError);
  const isSuccess = requestStates.some(state => state.isSuccess);
  const handleCloseModal = () => {
    setClose();
    form.resetFields();
  };

  useEffect(() => {
    const reqState = requestStates.find(state => state.isError && state.error);
    if (reqState && isFetchBaseQueryError(reqState.error)) {
      toast.error(getExceptionMessage(reqState.error.data as IException) || 'Произошла ошибка при загрузке');
      reqState.reset();
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      const reqState = requestStates.find(state => state.isSuccess);
      toast.success('Успешно загружено');
      handleCloseModal();
      reqState?.reset();
    }
  }, [isSuccess]);

  const handleUploadFiles = () => {
    const data = form.getFieldsValue();
    const formData = {
      wordFileName: data.wordFileName?.[0].uid,
      signedFileName: data.signedFileName?.[0].uid,
    };

    if (!formData.wordFileName && !formData.signedFileName) {
      return;
    }
    if (type === TemplateList.CONTRACT) {
      uploadFilesToContract([id!, formData]);
    }
    if (type === TemplateList.ACT) {
      uploadFilesToAct([id!, formData]);
    }
    if (type === TemplateList.REQUEST) {
      uploadFilesToRequest([id!, formData]);
    }
    if (type === TemplateList.DEAL) {
      uploadFilesToDeal([id!, formData]);
    }
  };

  return (
    <Modal
      title={'Загрузить документы'}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={() => form.submit()}
      onCancel={handleCloseModal}
      okText={'Загрузить'}
      okButtonProps={{ disabled: isEmptyWordFile && isEmptySignedFile }}
    >
      <Form form={form} layout={'vertical'} onFinish={handleUploadFiles} style={{ paddingTop: '20px' }}>
        <Row gutter={[20, 20]}>
          {/*<Col span={12}>*/}
          {/*  <Form.Item label={'Договор'} getValueFromEvent={normFile} valuePropName={'fileList'} name={'wordFileName'}>*/}
          {/*    <FileUpload onChange={() => {}} accept={'*'} />*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
          <Col span={12}>
            <Form.Item
              label={'Подписанный документ'}
              getValueFromEvent={normFile}
              valuePropName={'fileList'}
              name={'signedFileName'}
            >
              <FileUpload onChange={() => {}} accept={'pdf'} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DocsUploadModal;
