import Title from 'antd/es/typography/Title';
import { useParams } from 'react-router-dom';
import UsersHistoriesTable from '../../../components/Tables/UsersHistoriesTable';
const UsersHistoriesPage = () => {
  const { id } = useParams();
  return (
    <>
      <Title>История изменения пользователя</Title>
      <UsersHistoriesTable id={id} />
    </>
  );
};

export default UsersHistoriesPage;
