import CustomTable from '../UI/Table';
import { useEffect, useState } from 'react';
import { ISearchCertificatesPageQuery } from '../../core/types/query';
import { useGetReceiptsQuery, useRemoveReceiptMutation } from '../../store/services/receipts';
import { ColumnsType } from 'antd/lib/table';
import { Button, Col, Dropdown, MenuProps, Modal, Row } from 'antd';
import { getCertificateTypeName, getFullNameUser, getPosTableNum } from '../../core/utils';
import { IContract, IFileInfo, IUser } from '../../core/types/entities';
import { SettingOutlined } from '@ant-design/icons';
import { RolesEnum, TableFiltersItemsEnum } from '../../core/types/enums';
import { useAppSelector } from '../../core/hooks/redux';
import { toast } from 'react-toastify';
import FileTypeButtonGroup from '../FileTypeButtonGroup';
import ReceiptControlModal from '../Modals/ReceiptControlModal';
import { Link } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import TableFilters from '../TableFilters';

const { confirm } = Modal;
const ReceiptsTable = () => {
  const { role } = useAppSelector(state => state.authReducer);
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchCertificatesPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const [isCreateFormModalOpen, setIsCreateFormModalOpen] = useState(false);
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const { data } = useGetReceiptsQuery(paginationAndSearchParams);
  const [removeCertificate, removeCertificateStates] = useRemoveReceiptMutation();

  const receiptsData = data?.data?.map((receipt, index) => ({
    ...receipt,
    posNum: getPosTableNum(
      index,
      paginationAndSearchParams.page || DEFAULT_PAGE,
      paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
    ),
    key: receipt.id,
  }));

  useEffect(() => {
    if (removeCertificateStates.isSuccess) {
      toast.success('Справка успешно удалена');
    }
    if (removeCertificateStates.isError) {
      toast.error('Возникла проблема при удалении справки');
    }
  }, [removeCertificateStates.isError, removeCertificateStates.isSuccess]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      render: type => {
        return getCertificateTypeName(type);
      },
    },
    {
      title: 'Акт №',
      dataIndex: ['act', 'number'],
      key: 'actNumber',
    },
    {
      title: 'Контрагент',
      dataIndex: 'agent',
      key: 'agent',
      render: (data: IUser) => <Link to={`/agents/${data?.id}`}>{getFullNameUser(data)}</Link>,
    },
    {
      title: 'Файл',
      dataIndex: 'file',
      key: 'file',
      render: (file: IFileInfo) => {
        return <FileTypeButtonGroup items={[file]} />;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, data: unknown) => {
        const contract = data as IContract;
        return (
          <Dropdown menu={{ items: renderDropDownItems(contract.id) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const renderDropDownItems = (id: number) => {
    const items: MenuProps['items'] = [
      {
        key: 'drop-down-item-1',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить справку?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              removeCertificate(id);
            },
          });
        },
        label: 'Удалить',
        danger: true,
      },
    ];
    if (role === RolesEnum.ADMIN.toString()) {
      return items;
    }
  };

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Row gutter={[20, 0]} style={{ marginBottom: '20px' }}>
          <Col span={24}>
            <Button type={'primary'} onClick={() => setIsCreateFormModalOpen(true)}>
              Создать чек
            </Button>
          </Col>
        </Row>
      )}
      <TableFilters
        visibleRows={2}
        columns={3}
        fields={[
          TableFiltersItemsEnum.FIND_BY_AGENT_FIO,
          TableFiltersItemsEnum.CONTRACT,
          TableFiltersItemsEnum.ACT,
          TableFiltersItemsEnum.FROM_DATE,
          TableFiltersItemsEnum.TO_DATE,
        ]}
        params={paginationAndSearchParams}
        setParams={setPaginationAndSearchParams}
      />
      <CustomTable
        tableKey={'ReceiptsTable'}
        dataSource={receiptsData}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total ?? 0,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <ReceiptControlModal open={isCreateFormModalOpen} setClose={() => setIsCreateFormModalOpen(false)} />
    </>
  );
};

export default ReceiptsTable;
