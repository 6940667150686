import { CSSProperties, useEffect, useState } from 'react';
import ru_RU from 'antd/es/date-picker/locale/ru_RU';
import { Button, Checkbox, DatePicker, Form, FormProps, Input, Radio, Rate, Select, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { RateProps } from 'antd/lib/rate';
import { DatePickerProps } from 'antd/lib/date-picker';

const { Option } = Select;

export interface IField {
  name: string;
  label: string;
  type:
    | 'text'
    | 'email'
    | 'textarea'
    | 'password'
    | 'number'
    | 'checkbox'
    | 'checkboxgroup'
    | 'buttongroup'
    | 'select'
    | 'radio'
    | 'switch'
    | 'rate'
    | 'datepicker'
    | 'rangedatepicker';
  required?: boolean;
  rows?: number;
  options?: string[];
  rateProps?: RateProps;
  datePickerProps?: DatePickerProps;
  className?: string;
  style?: CSSProperties;
}

interface ISubmitButtonProps {
  text?: string;
  style?: CSSProperties;
  className?: string;
}

export interface IFormBuilderProps<T> extends Omit<FormProps, 'initialValues'> {
  fields: IField[];
  onSubmit: (values: T) => void;
  initialValues?: T;
  submitButtonProps?: ISubmitButtonProps;
  checkFormUpdate?: boolean;
}

type KeyOf<T> = Extract<keyof T, string>;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const FormBuilder = <T extends Record<string, any>>({
  fields,
  onSubmit,
  initialValues,
  checkFormUpdate,
  submitButtonProps,
  ...otherFormProps
}: IFormBuilderProps<T>) => {
  const [form] = Form.useForm();
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [changedValues, setChangedValues] = useState<Partial<T>>({});

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      formUpdate();
    }
  }, []);

  const formUpdate = () => {
    if (checkFormUpdate && initialValues) {
      const currentValues = form.getFieldsValue();
      const changedFields: Partial<T> = {};

      for (const key in currentValues) {
        if (currentValues[key as KeyOf<T>] !== initialValues[key as KeyOf<T>]) {
          changedFields[key as KeyOf<T>] = currentValues[key as KeyOf<T>];
        }
      }
      setChangedValues(changedFields);
      setIsFormUpdated(Object.keys(changedFields).length > 0);
    }
  };

  const onFinish = () => {
    if (isFormUpdated) {
      onSubmit(changedValues as T);
    } else {
      onSubmit(form.getFieldsValue() as T);
    }
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    form.setFieldsValue({ [name]: checked });
  };

  const handleRadioChange = (name: string, value: string) => {
    form.setFieldsValue({ [name]: value });
  };

  const handleSwitchChange: SwitchChangeEventHandler = checked => {
    const fieldName = fields.find(field => field.type === 'switch')?.name;
    if (fieldName) {
      form.setFieldsValue({ [fieldName]: checked });
      formUpdate();
    }
  };

  const renderFields = () => {
    return fields.map(field => (
      <Form.Item
        className={field.className && field.className}
        valuePropName={field.type === 'switch' ? 'checked' : undefined}
        style={field.style && field.style}
        key={field.name}
        label={field.label}
        name={field.name}
        rules={[
          {
            required: field.required || false,
            message: `${field.label} обязательное поле`,
          },
        ]}
      >
        {getFieldComponent(field)}
      </Form.Item>
    ));
  };

  const getFieldComponent = (field: IField) => {
    switch (field.type) {
      case 'checkbox':
        return (
          <Checkbox
            checked={form.getFieldValue(field.name)}
            onChange={(e: CheckboxChangeEvent) => handleCheckboxChange(field.name, e.target.checked)}
          >
            {field.label}
          </Checkbox>
        );
      case 'checkboxgroup':
        return <Checkbox.Group options={field.options}>{field.label}</Checkbox.Group>;
      case 'select':
        return (
          <Select onChange={formUpdate}>
            {field.options?.map(option => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );
      case 'radio':
        return (
          <Radio.Group onChange={(e: RadioChangeEvent) => handleRadioChange(field.name, e.target.value)}>
            {field.options?.map(option => (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        );
      case 'buttongroup':
        return (
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            onChange={(e: RadioChangeEvent) => handleRadioChange(field.name, e.target.value)}
          >
            {field.options?.map(option => (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        );
      case 'switch':
        return <Switch checked={form.getFieldValue(field.name)} onChange={handleSwitchChange} />;
      case 'rate':
        return <Rate {...field.rateProps} onChange={formUpdate} />;
      case 'datepicker':
        return <DatePicker {...field.datePickerProps} onChange={formUpdate} locale={ru_RU} />;
      case 'rangedatepicker':
        return <DatePicker.RangePicker onChange={formUpdate} locale={ru_RU} />;
      case 'textarea':
        return <Input.TextArea rows={field.rows} />;
      case 'password':
        return <Input.Password />;
      default:
        return <Input type={field.type} />;
    }
  };

  const getSubmitButtonText = () => {
    if (!submitButtonProps?.text && checkFormUpdate && isFormUpdated) {
      return 'Сохранить';
    }
    if (submitButtonProps?.text) {
      return submitButtonProps?.text;
    }
    return 'Отправить';
  };

  return (
    <Form {...otherFormProps} form={form} onFinish={onFinish} onChange={formUpdate}>
      {renderFields()}
      <Form.Item className={submitButtonProps?.className && submitButtonProps.className}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={checkFormUpdate && !isFormUpdated}
          style={submitButtonProps?.style && submitButtonProps.style}
        >
          {getSubmitButtonText()}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormBuilder;
