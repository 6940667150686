import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import CustomTable from '../UI/Table';
import moment from 'moment';
import { useAddMeetingMutation, useGetMeetingsQuery } from '../../store/services/meetings';
import { CopyOutlined, EditOutlined, LoginOutlined } from '@ant-design/icons';
import { ISO_DATE_TIME } from '../../core/constants/dateFormatting';
import PlanMeetingModalForm from '../Modals/PlanMeetingModalForm';
import { portalUrl } from '../../core/constants';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';
import { copyToClipboard, openInNewTab } from '../../core/utils';
import { Meeting } from '../../core/types/entities';

const MeetingsTable = () => {
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState({
    page: 0,
    size: 20,
  });
  const { role } = useAppSelector(state => state.authReducer);
  const { data } = useGetMeetingsQuery(paginationAndSearchParams);
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const [addMeeting, { isError: isErrorCreateMeeting, isSuccess: isSuccessCreateMeeting, data: createMeetingData }] =
    useAddMeetingMutation();
  const [open, setOpen] = useState(false);
  const contractsTypesData = data?.data?.map(template => ({
    ...template,
    key: template.id,
    actions: '',
  }));
  const selectedMeeting = useRef<Meeting>();

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  const startMeeting = () => {
    addMeeting({ dateTime: moment().format(ISO_DATE_TIME) });
  };

  useEffect(() => {
    if (isSuccessCreateMeeting) {
      copyToClipboard(
        `${portalUrl}/additionally/meetings/call/${createMeetingData?.uuid}`,
        'Ссылка на видеоконференцию успешно скопированна'
      );
    }
  }, [isErrorCreateMeeting, isSuccessCreateMeeting]);

  const copyMeetingUrl = (uuid: string) => {
    copyToClipboard(`${portalUrl}/additionally/meetings/call/${uuid}`);
  };

  const enterToMeeting = (uuid: string) => {
    openInNewTab(`${portalUrl}/additionally/meetings/call/${uuid}`);
  };

  const columns: ColumnsType = [
    {
      title: 'Название конференции',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Дата начала',
      dataIndex: 'startDateTime',
      key: 'startDateTime',
      width: 355,
      render: (date: string) => moment(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Пароль модератора',
      dataIndex: 'moderatorPassword',
      key: 'moderatorPassword',
      render: (moderatorPassword: string) => (
        <Button type={'link'} onClick={() => copyToClipboard(moderatorPassword)}>
          {moderatorPassword}
        </Button>
      ),
    },
    {
      title: 'ДЕЙСТВИЯ',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: string, record: unknown) => (
        <Space>
          <Tooltip title="Скопировать ссылку">
            <Button
              type="dashed"
              shape="circle"
              icon={<CopyOutlined />}
              onClick={() => copyMeetingUrl((record as Meeting).uuid)}
            />
          </Tooltip>
          <Tooltip title="Перейти к видеоконференции">
            <Button
              type="dashed"
              shape="circle"
              icon={<LoginOutlined />}
              onClick={() => enterToMeeting((record as Meeting).uuid)}
            />
          </Tooltip>
          <Tooltip title="Изменить видеоконференцию">
            <Button
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                selectedMeeting.current = record as Meeting;
                setOpen(true);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
        {role === RolesEnum.AGENT && (
          <Col>
            <Button onClick={startMeeting} type={'primary'}>
              Начать видеоконференцию
            </Button>
          </Col>
        )}
        <Col>
          <Button onClick={() => setOpen(true)} type={'primary'}>
            Запланировать видеоконференцию
          </Button>
        </Col>
      </Row>
      <CustomTable
        tableKey={'MeetingsTable'}
        columns={columns}
        dataSource={contractsTypesData}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <PlanMeetingModalForm
        open={open}
        setClose={() => {
          setOpen(false);
          selectedMeeting.current = undefined;
        }}
        meeting={selectedMeeting.current}
      />
    </>
  );
};

export default MeetingsTable;
