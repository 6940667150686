import Title from 'antd/es/typography/Title';
import CourseFaqTable from '../../components/Tables/CourseFaqTable';

const Course = () => {
  return (
    <div>
      <Title>Курсы</Title>
      <CourseFaqTable />
    </div>
  );
};

export default Course;
