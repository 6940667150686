import { api } from '../index';
import {
  IAct,
  IActCertificate,
  IActCreate,
  IActEdit,
  IActHistories,
  IActPayment,
  ISearchPage,
} from '../../../core/types/entities';
import {
  ISearchActHistoriesQuery,
  ISearchActsCertificatesPageQuery,
  ISearchActsPageQuery,
  ISearchActsPaymentsPageQuery,
} from '../../../core/types/query';
import { IGenerateActsForm, IMassActionActs, IUploadFilesToEntity } from '../../../core/types/forms';
import { ActStatus } from '../../../core/types/enums';

export const actsApi = api.injectEndpoints({
  endpoints: builder => ({
    getActs: builder.query<ISearchPage<IAct>, ISearchActsPageQuery>({
      query: params => ({
        url: `/acts`,
        params: params,
      }),
      providesTags: ['acts'],
    }),
    getAct: builder.query<IAct, number>({
      query: id => ({
        url: `/acts/${id}`,
      }),
    }),
    addAct: builder.mutation<IAct, IActCreate>({
      query: data => ({
        url: `/acts`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['acts'],
    }),
    uploadFilesToAct: builder.mutation<IAct, [number, IUploadFilesToEntity]>({
      query: ([id, uploadData]) => ({
        url: `/acts/${id}/files`,
        method: 'POST',
        body: uploadData,
      }),
      invalidatesTags: ['acts'],
    }),
    editAct: builder.mutation<IAct, IActEdit>({
      query: data => ({
        url: `/acts/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['acts'],
    }),
    changePaymentsStatusActs: builder.mutation<void, [ActStatus, IMassActionActs]>({
      query: ([status, data]) => ({
        url: `/acts/statuses?status=${status}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['acts'],
    }),
    changePaymentsDateActs: builder.mutation<void, [string, IMassActionActs]>({
      query: ([date, data]) => ({
        url: `/acts/payments?date=${date}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['acts'],
    }),
    removeAct: builder.mutation<void, number>({
      query: id => ({
        url: `/acts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['acts'],
    }),
    generateActs: builder.mutation<IAct[], IGenerateActsForm>({
      query: generateFormData => ({
        url: `/acts/generate`,
        method: 'POST',
        body: generateFormData,
      }),
      invalidatesTags: ['requests'],
    }),
    getActHistories: builder.query<ISearchPage<IActHistories>, ISearchActHistoriesQuery>({
      query: params => ({
        url: `/acts/histories`,
        params,
      }),
    }),
    getActsPayments: builder.query<ISearchPage<IActPayment>, ISearchActsPaymentsPageQuery>({
      query: params => ({
        url: `/acts/payments`,
        params: params,
      }),
    }),
    getActsCertificates: builder.query<ISearchPage<IActCertificate>, ISearchActsCertificatesPageQuery>({
      query: params => ({
        url: `/acts/certificates`,
        params: params,
      }),
    }),
    regenerateActDocuments: builder.mutation<void, number>({
      query: id => ({
        url: `/acts/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['acts'],
    }),
  }),
});

export const {
  useGetActsQuery,
  useGetActQuery,
  useAddActMutation,
  useEditActMutation,
  useChangePaymentsStatusActsMutation,
  useChangePaymentsDateActsMutation,
  useUploadFilesToActMutation,
  useGenerateActsMutation,
  useGetActHistoriesQuery,
  useRemoveActMutation,
  useGetActsPaymentsQuery,
  useGetActsCertificatesQuery,
  useRegenerateActDocumentsMutation,
} = actsApi;
