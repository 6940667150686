import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Input, MenuProps, Modal, Row, Tag } from 'antd';
import { EyeOutlined, SettingOutlined } from '@ant-design/icons';
import { UserStateEnum } from '../../core/types/enums';
import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import { IAgentInfos, IFieldsExcludeForAgent } from '../../core/types/entities';
import { useBanUserMutation, useResetUserPasswordMutation, useUnBanUserMutation } from '../../store/services/users';
import { toast } from 'react-toastify';
import AgentFormModal from '../Modals/AgentFormModal';
import { useGetAgentsQuery, useGetFieldsToExcludeForAgentQuery } from '../../store/services/users/agents-infos';
import AgentDetailsModal from '../Modals/AgentDetailsModal';
import debounce from 'lodash/debounce';
import { ISearchAgentsPageQuery } from '../../core/types/query';
import { getFullNameUser, getPosTableNum } from '../../core/utils';
import { Link } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import SwitchAccountModal from '../Modals/SwitchAccountModal';
import TelegramLinks from '../UI/TelegramLinks';
import dayjs from 'dayjs';
import { RU_DATE } from '../../core/constants/dateFormatting';
import AgentExcludedFieldsModal from '../Modals/AgentExcludedFieldsModal';

const { confirm } = Modal;

export const formattedFieldsToExcludeForAgent = (fields: IFieldsExcludeForAgent[]) =>
  fields.length
    ? fields.map(field => {
        return {
          label: field.description,
          value: field.id,
        };
      })
    : [];

const AgentsTable = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isExcludedFieldsModal, setIsExcludedFieldsModal] = useState(false);
  const [isFormDetailsOpen, setIsFormDetailsOpen] = useState(false);
  const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<IAgentInfos | undefined>(undefined);
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchAgentsPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
    sort: 'u.lastName,asc',
  });
  const { data } = useGetAgentsQuery(paginationAndSearchParams);
  const [banUser, banStates] = useBanUserMutation();
  const [unbanUser] = useUnBanUserMutation();
  const [resetPassword, resetPasswordStates] = useResetUserPasswordMutation();
  const { data: fieldsToExcludeForAgent } = useGetFieldsToExcludeForAgentQuery();
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;

  const agents = data?.data?.map((agentInfo, index) => {
    const agent = agentInfo.agent;

    return {
      ...agentInfo,
      fullName: <Link to={`/agents/${agentInfo.agent?.id}`}>{getFullNameUser(agentInfo.agent)}</Link>,
      posNum: getPosTableNum(
        index,
        paginationAndSearchParams.page || DEFAULT_PAGE,
        paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
      ),
      key: agent.id,
    };
  });

  useEffect(() => {
    if (banStates.isError) {
      toast.error('Невозможно заблокировать администратора!');
    }
    if (resetPasswordStates.isSuccess) {
      toast.success('Новый пароль отправлен на почту');
    }
    if (resetPasswordStates.isError) {
      toast.error('Произошла ошибка при сбросе пароля');
    }
  }, [banStates.isError, resetPasswordStates.isSuccess, resetPasswordStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'СТАТУС',
      dataIndex: ['agent', 'state'],
      key: 'state',
      render: (state: string, data: unknown) => {
        const agentInfo = data as IAgentInfos;
        return (
          <Tag color={state === UserStateEnum.CONFIRMED ? 'green' : 'red'} key={'state' + agentInfo.agent.id}>
            {state === UserStateEnum.CONFIRMED ? 'Активный' : 'Заблокирован'}
          </Tag>
        );
      },
    },
    {
      title: 'ТЕЛЕГРАМ',
      dataIndex: ['agent', 'telegram'],
      key: 'telegram',
      width: 250,
      render: (path: string) => <TelegramLinks path={path} />,
    },
    {
      title: 'ПОЧТА',
      dataIndex: ['agent', 'email'],
      key: 'email',
    },
    {
      title: 'ТЕЛЕФОН',
      dataIndex: ['agent', 'phoneNumber'],
      key: 'phoneNumber',
      render: text => <div style={{ minWidth: '150px' }}>{text}</div>,
    },
    {
      title: 'Дата рождения',
      dataIndex: ['agent', 'birthDate'],
      key: 'birthDate',
      align: 'center',
      render: (date: string) => date && dayjs(date ?? '').format(RU_DATE),
    },
    {
      title: 'ГРАЖДАНСТВО',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      key: 'inn',
    },
    {
      title: 'Налоговый статус',
      dataIndex: ['taxStatus', 'name'],
      key: 'taxStatusId',
    },
    {
      title: 'КОММЕНТАРИЙ',
      dataIndex: ['agent', 'comments'],
      key: 'comments',
    },
    {
      title: 'Реквизиты',
      dataIndex: 'id',
      key: 'requisites',
      align: 'center',
      render: (id: number) => {
        return (
          <Button
            onClick={() => {
              const agent = data?.data?.find(agent => agent.id === id);
              setSelectedAgent(agent);
              setIsFormDetailsOpen(true);
            }}
            shape="circle"
          >
            <EyeOutlined />
          </Button>
        );
      },
    },
    {
      title: 'ДЕЙСТВИЯ',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (action: unknown, data: unknown) => {
        const agentInfos = data as IAgentInfos;
        return (
          <Dropdown menu={{ items: renderDropDownItems(agentInfos.id, agentInfos.agent.state) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];
  const renderDropDownItems = (id: number, state: UserStateEnum | string) => {
    const agent = data?.data?.find(agent => agent.id === id);

    const confirmAndExecute = (title: string, onOkAction: () => void) => {
      confirm({
        title,
        centered: true,
        cancelText: 'Отмена',
        okText: 'Подтвердить',
        onOk: onOkAction,
      });
    };

    const items: MenuProps['items'] = [
      {
        key: 'drop-down-item-1',
        onClick: () => {
          setSelectedAgent(agent);
          setIsFormModalOpen(true);
        },
        label: 'Редактировать',
      },
      {
        key: 'drop-down-item-2',
        onClick: () => {
          window.open(`/users/histories/${agent?.agent.id}`, '_blank');
        },
        label: 'История изменений данных',
      },
      {
        key: 'drop-down-item-3',
        onClick: () => {
          window.open(`/users/agents-infos/histories/${agent?.agent.id}`, '_blank');
        },
        label: 'История изменений доп. информации',
      },
      {
        key: 'drop-down-item-4',
        onClick: () => {
          confirmAndExecute('Вы точно хотите сбросить пароль пользователю?', () => {
            resetPassword(agent?.agent.id);
          });
        },
        label: 'Сбросить пароль',
      },
      {
        key: 'drop-down-item-5',
        onClick: () => {
          setSelectedAgent(agent);
          setIsSwitchAccountModalOpen(true);
        },
        label: 'Войти',
      },
      {
        key: 'drop-down-item-7',
        onClick: () => {
          window.open(`/users/upload-homework-agent/${agent?.agent.id}`);
        },
        label: 'Загруженные ДЗ',
      },
      {
        key: 'drop-down-item-8',
        onClick: () => {
          setSelectedAgent(agent);
          setIsExcludedFieldsModal(true);
        },
        label: 'Редактировать исключаемые поля',
      },
    ];

    if (state === UserStateEnum.CONFIRMED) {
      items.push({
        label: 'Заблокировать',
        key: 'drop-down-item-6',
        onClick: () => {
          confirmAndExecute('Вы точно хотите заблокировать контрагента?', () => {
            banUser(agent?.agent.id);
          });
        },
        danger: true,
      });
    } else {
      items.push({
        key: 'drop-down-item-6',
        onClick: () => {
          confirmAndExecute('Вы точно хотите разблокировать контрагента?', () => {
            unbanUser(agent?.agent.id);
          });
        },
        label: 'Разблокировать',
      });
    }

    return items;
  };

  const handleSearch = debounce((value: ChangeEvent<HTMLInputElement>) => {
    const findText = value.target.value;
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: 0,
      query: findText,
    });
  }, 500);

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
    setSelectedAgent(undefined);
  };

  const handleCloseInfosModal = () => {
    setIsFormDetailsOpen(false);
    setSelectedAgent(undefined);
  };

  const handleCloseExcludedFieldsModal = () => {
    setIsExcludedFieldsModal(false);
    setSelectedAgent(undefined);
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <Button type={'primary'} onClick={() => setIsFormModalOpen(true)}>
            Создать контрагента
          </Button>
        </Col>
        <Col span={10}>
          <Form.Item label="Поиск по ФИО">
            <Input allowClear placeholder="Введите ФИО" onChange={handleSearch} />
          </Form.Item>
        </Col>
      </Row>
      <CustomTable
        tableKey={'AgentsTable'}
        dataSource={agents}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <AgentFormModal initialData={selectedAgent} open={isFormModalOpen} setClose={handleCloseFormModal} />
      <AgentDetailsModal agentInfos={selectedAgent} setClose={handleCloseInfosModal} open={isFormDetailsOpen} />
      <AgentExcludedFieldsModal
        selectedAgent={selectedAgent}
        handleCloseExcludedFieldsModal={handleCloseExcludedFieldsModal}
        isExcludedFieldsModal={isExcludedFieldsModal}
        fieldsToExcludeForAgent={fieldsToExcludeForAgent}
      />
      <SwitchAccountModal
        open={isSwitchAccountModalOpen}
        setClose={() => setIsSwitchAccountModalOpen(false)}
        userId={selectedAgent?.agent.id}
      />
    </>
  );
};

export default AgentsTable;
