import Title from 'antd/es/typography/Title';
import DuplicatesInnoHomeworksTable from '../../components/Tables/DuplicatesInnoHomeworksTable';

const DuplicatesUploadedHomeworksInnopolis = () => {
  return (
    <>
      <Title>Дубликаты загруженных ДЗ Иннополис</Title>
      <DuplicatesInnoHomeworksTable />
    </>
  );
};

export default DuplicatesUploadedHomeworksInnopolis;
