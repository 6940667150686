import Title from 'antd/es/typography/Title';
import ServiceAreasTable from '../../components/Tables/ServiceAreasTable';

const TaxStatus = () => {
  return (
    <>
      <Title>Услуги</Title>
      <ServiceAreasTable />
    </>
  );
};

export default TaxStatus;
