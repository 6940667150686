import Title from 'antd/es/typography/Title';
import AgentFunctionsTable from '../../components/Tables/AgentFunctionsTable';

const AgentFunctions = () => {
  return (
    <div>
      <Title>Функции контрагентов</Title>
      <AgentFunctionsTable />
    </div>
  );
};

export default AgentFunctions;
