import { api } from '../index';
import { IContractType } from '../../../core/types/entities';

interface AgentFunctions {
  id: number;
  name: string;
}

export const agentFunctionsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAgentFunctions: builder.query<AgentFunctions[], void>({
      query: () => `/contracts/agent-functions`,
      providesTags: ['agent-functions'],
    }),
    getAgentFunction: builder.query<IContractType, number>({
      query: id => ({
        url: `/contracts/agent-functions/${id}`,
      }),
    }),
    addAgentFunction: builder.mutation<IContractType, { name: string }>({
      query: data => ({
        url: '/contracts/agent-functions',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['agent-functions'],
    }),
    updateAgentFunction: builder.mutation<IContractType, [number, { name: string }]>({
      query: ([id, data]) => ({
        url: `/contracts/agent-functions/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['agent-functions'],
    }),
    removeAgentFunction: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/agent-functions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['agent-functions'],
    }),
  }),
});

export const {
  useGetAgentFunctionsQuery,
  useAddAgentFunctionMutation,
  useUpdateAgentFunctionMutation,
  useRemoveAgentFunctionMutation,
  useGetAgentFunctionQuery,
} = agentFunctionsApi;
