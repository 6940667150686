import { Button, Col, Collapse, Form, Input, Modal, Row, Segmented, Select } from 'antd';
import { useGetUsersQuery } from '../../../store/services/users';
import { ActStatus, RolesEnum, TemplateList } from '../../../core/types/enums';
import { useGetContractsQuery } from '../../../store/services/contracts/contracts';
import { useGetTemplatesQuery, useGetTemplateVersionsQuery } from '../../../store/services/templates';
import { useEffect, useState } from 'react';
import { IAct, IActForm, Template } from '../../../core/types/entities';
import {
  customFilterOption,
  getExceptionMessage,
  getFixedActionTypeName,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
  isFetchBaseQueryError,
  normFile,
  validateTextForInvalidCharacters,
} from '../../../core/utils';
import FileUpload from '../../UI/FileUpload';
import { useAddActMutation, useEditActMutation } from '../../../store/services/acts';
import { toast } from 'react-toastify';
import { useGetRequestsQuery } from '../../../store/services/requests';
import { useGetFixedActionsQuery } from '../../../store/services/fixed-actions';
import DatePickerBase from '../../UI/DatePickerBase';
import { IException } from '../../../core/types/response';

const { TextArea } = Input;

interface CreateTemplateFormProps {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedAct?: IAct;
}

interface IStep {
  key: 'contract' | 'request';
  title: string;
}

const steps: IStep[] = [
  {
    key: 'contract',
    title: 'Договор',
  },
  {
    key: 'request',
    title: 'Заявка',
  },
];

const ControlActForm = ({ showModal, setShowModal, selectedAct }: CreateTemplateFormProps) => {
  const [form] = Form.useForm();
  const agentId = Form.useWatch('agentId', form);
  const contractId = Form.useWatch('contractId', form);
  const requestId = Form.useWatch('requestId', form);
  const [addAct, addActStates] = useAddActMutation();
  const [editAct, editActStates] = useEditActMutation();
  const agents = useGetUsersQuery({
    roles: RolesEnum.AGENT,
    size: 999,
  });
  const contracts = useGetContractsQuery(
    {
      size: 999,
      agentId: agentId,
    },
    { skip: !agentId }
  );
  const requests = useGetRequestsQuery(
    {
      size: 999,
      agentId: agentId,
    },
    { skip: !agentId }
  );
  const fixedActions = useGetFixedActionsQuery(
    {
      requestId,
      contractId,
    },
    {
      skip: !contractId && !requestId,
    }
  ).data;

  const templates = useGetTemplatesQuery({
    size: 999,
    type: TemplateList.ACT,
  });
  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>(undefined);
  const templatesVersions = useGetTemplateVersionsQuery(selectedTemplate?.id || 0);
  const isEdit = !!selectedAct?.id;
  const isFileUploaded = !!Form.useWatch('fileName', form);
  const [currentStep, setCurrentStep] = useState<string | undefined>('contract');

  const handleSubmitForm = (data: IActForm) => {
    if (isEdit) {
      editAct({
        ...data,
        id: selectedAct?.id,
      });
      return;
    }

    if (data.fixedActionForms?.length === 0) {
      return toast.error('Отсутствуют зафиксированные услуги!');
    }

    const workCountError = data.fixedActionForms.find(fixedAction => !fixedAction.workCount);

    if (workCountError) {
      return toast.error('Вы не указали объем работ в зафиксированных услугах!');
    }

    const withPattern = !!data.patternVersionId;
    addAct({
      ...data,
      withPattern,
      fileName: data.fileName?.[0].uid,
    });
  };

  useEffect(() => {
    const fixedActionsForm = fixedActions?.map(fixedAction => ({
      actionId: fixedAction.id,
      workCount: fixedAction.workCount,
    }));
    form.setFieldValue('fixedActionForms', fixedActionsForm);
  }, [requestId, contractId, fixedActions]);

  useEffect(() => {
    form.resetFields();
    if (selectedAct) {
      form.setFieldsValue(selectedAct);
    }
  }, [selectedAct]);

  useEffect(() => {
    if (addActStates.isSuccess) {
      toast.success('Акт успешно добавлен!');
      setShowModal(false);
      form.resetFields();
    }
    if (isFetchBaseQueryError(addActStates.error)) {
      toast.error(getExceptionMessage(addActStates.error.data as IException) || 'Произошла ошибка, повторите попытку');
    }
  }, [addActStates.isSuccess, addActStates.isError]);

  useEffect(() => {
    if (editActStates.isSuccess) {
      toast.success('Акт успешно изменен!');
      setShowModal(false);
      form.resetFields();
    }

    if (isFetchBaseQueryError(editActStates.error)) {
      toast.error(getExceptionMessage(editActStates.error.data as IException) || 'Произошла ошибка, повторите попытку');
    }
  }, [editActStates.isSuccess, editActStates.isError]);

  return (
    <Modal
      open={showModal}
      centered
      title={isEdit ? 'Изменить акт' : 'Создать акт'}
      okText={'Сохранить'}
      onCancel={() => {
        setShowModal(false);
        setCurrentStep('contract');
        form.resetFields();
      }}
      onOk={() => form.submit()}
      afterOpenChange={() => {
        if (isEdit) {
          return form.getFieldInstance('number')?.focus();
        }
        form.getFieldInstance('date')?.focus();
      }}
      // confirmLoading={addActStates.isLoading}
    >
      {!isEdit && (
        <Segmented
          block
          value={steps.find(step => step.key === currentStep)?.title}
          options={steps.map(step => step.title)}
          onChange={title => setCurrentStep(steps.find(step => step.title === title)?.key)}
          style={{ margin: '20px 0' }}
        />
      )}
      <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
        {isEdit && (
          <Form.Item
            label={'Номер акта'}
            name={'number'}
            rules={[
              {
                validator: validateTextForInvalidCharacters,
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label={'Дата акта'}
          name={'date'}
          getValueFromEvent={getValueFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
          rules={[
            {
              required: !isEdit,
              message: 'Поле "Дата акта" обязательное!',
            },
          ]}
        >
          <DatePickerBase
            disabledDate={date => {
              const dayOfWeek = date.day();
              return dayOfWeek === 0 || dayOfWeek === 6;
            }}
          />
        </Form.Item>
        <Form.Item
          label={'Дата оплаты акта'}
          name={'paymentDate'}
          getValueFromEvent={getValueFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
        >
          <DatePickerBase
            disabledDate={date => {
              const dayOfWeek = date.day();
              return dayOfWeek === 0 || dayOfWeek === 6;
            }}
          />
        </Form.Item>
        {isEdit && (
          <Form.Item label={'Статус акта'} name={'status'}>
            <Select>
              <Select.OptGroup label={'Статус акта'} />
              <Select.Option value={ActStatus.NOT_PAID}>Не оплачен</Select.Option>
              <Select.Option value={ActStatus.PAID}>Оплачен</Select.Option>
            </Select>
          </Form.Item>
        )}
        {!isEdit && (
          <Form.Item
            label={'Контрагент'}
            key={'agentId'}
            name={'agentId'}
            rules={[
              {
                required: true,
                message: 'Поле "Контрагент" обязательное!',
              },
            ]}
          >
            <Select
              showSearch
              filterOption={customFilterOption}
              allowClear
              onChange={() => form.resetFields(['contractId', 'requestId'])}
            >
              <Select.OptGroup label={'Контрагент'} />
              {agents.data?.data?.map(agent => (
                <Select.Option key={agent.id} value={agent.id}>
                  {`${agent.lastName} ${agent.firstName} ${agent.patronymic ?? ''}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!isEdit && currentStep === 'contract' && (
          <Form.Item
            label={'Договор'}
            key={'contractId'}
            name={'contractId'}
            rules={[
              {
                required: true,
                message: 'Поле "Договор" обязательное!',
              },
            ]}
          >
            <Select onChange={() => form.resetFields(['requestId'])} allowClear disabled={!agentId}>
              <Select.OptGroup label={'Договор'} />
              {contracts?.data?.data.map(contract => (
                <Select.Option key={contract.id} value={contract.id}>
                  № {contract.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!isEdit && currentStep === 'request' && (
          <Form.Item
            label={'Заявка'}
            key={'requestId'}
            name={'requestId'}
            rules={[
              {
                required: true,
                message: 'Поле "Заявка" обязательное!',
              },
            ]}
          >
            <Select
              onChange={() => {
                form.resetFields(['contractId']);
              }}
              allowClear
              disabled={!agentId}
            >
              <Select.OptGroup label={'Заявка'} />
              {requests.data?.data.map(request => (
                <Select.Option key={request.id} value={request.id}>
                  № {request.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!isEdit && (
          <Collapse
            size={'small'}
            style={{ marginBottom: '20px' }}
            defaultActiveKey={1}
            items={[
              {
                key: 1,
                label: 'Зафиксированные услуги',
                children:
                  fixedActions?.length === 0 || (!contractId && !requestId) ? (
                    'Нет доступных зафиксированных услуг'
                  ) : (
                    <Form.List name={'fixedActionForms'}>
                      {(fields, { remove }) => (
                        <>
                          {fields.map(field => {
                            const fixedActionIdFieldName = ['fixedActionForms', field.name, 'actionId'];
                            const fixedActionId = form.getFieldValue(fixedActionIdFieldName);
                            const fixedAction = fixedActions?.find(fixedAction => fixedAction.id === fixedActionId);
                            return (
                              <Row gutter={[20, 10]} key={field.key}>
                                <Col span={24}>
                                  <b>Услуга:</b> {fixedAction?.action.name}
                                </Col>
                                <Col span={24}>
                                  <b>Тип:</b> {fixedAction?.type && getFixedActionTypeName(fixedAction.type)}
                                </Col>
                                <Col span={24}>
                                  <b>Ставка:</b> {fixedAction?.rate}
                                </Col>
                                <Col span={24}>
                                  <b>Единица измерения:</b> {fixedAction?.unit.name}
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    whiteSpace: 'nowrap',
                                    gap: '5px',
                                  }}
                                >
                                  <b style={{ marginBottom: '20px' }}>Объем работ:</b>
                                  <Form.Item name={[field.name, 'workCount']}>
                                    <Input type={'number'} />
                                  </Form.Item>
                                </Col>
                                <Col
                                  span={24}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: '10px',
                                  }}
                                >
                                  <Button onClick={() => remove(field.name)} danger>
                                    Удалить
                                  </Button>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      )}
                    </Form.List>
                  ),
              },
            ]}
          />
        )}
        {!isEdit && (
          <Form.Item label={'Шаблон'}>
            <Select
              onChange={templateId => {
                setSelectedTemplate(templates?.data?.data.find(template => template.id === templateId));
                form.resetFields(['patternVersionId']);
              }}
              allowClear={true}
              value={selectedTemplate?.id}
              disabled={isFileUploaded}
            >
              <Select.OptGroup label={'Шаблон'} />
              {templates.data?.data?.map(template => (
                <Select.Option key={template.id} value={template.id}>
                  {template.name ? template.name : `Шаблон id ${template.id}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {!isEdit && (
          <Form.Item
            label={'Версия шаблона'}
            key={'patternVersionId'}
            name={'patternVersionId'}
            help={selectedTemplate && templatesVersions.data?.data.length === 0 ? 'Нет доступных версий' : ''}
          >
            <Select disabled={templatesVersions.data?.data.length === 0}>
              <Select.OptGroup label={'Версия шаблона'} />
              {selectedTemplate &&
                templatesVersions.data?.data?.map(templateVersion => (
                  <Select.Option key={templateVersion.id} value={templateVersion.id}>
                    Версия № {templateVersion.version}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
        {!isEdit && (
          <Form.Item
            label={'Файл готового акта'}
            name={'fileName'}
            getValueFromEvent={normFile}
            valuePropName="fileList"
          >
            <FileUpload onChange={() => {}} accept={'*'} disabled={!!selectedTemplate} />
          </Form.Item>
        )}
        <Form.Item label={'Комментарии к акту'} name={'comments'}>
          <TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ControlActForm;
