import { useEffect } from 'react';
import { Form, Modal } from 'antd';
import { useAddTemplateVersionMutation, useEditTemplateVersionMutation } from '../../../store/services/templates';
import { toast } from 'react-toastify';
import FileUpload from '../../UI/FileUpload';
import { TemplateVersionFormData } from '../../../core/types/forms';
import { normFile } from '../../../core/utils';

interface CreateTemplateFormProps {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedTemplateVersion: TemplateVersionFormData | null;
}

const ControlTemplateVersionForm = ({ showModal, selectedTemplateVersion, setShowModal }: CreateTemplateFormProps) => {
  const [form] = Form.useForm();
  const [addTemplateVersion, addTemplateVersionStates] = useAddTemplateVersionMutation();
  const [editTemplateVersion, editTemplateVersionStates] = useEditTemplateVersionMutation();

  const handleSubmitForm = (data: TemplateVersionFormData) => {
    if (!data.file) {
      return;
    }

    if (selectedTemplateVersion?.id) {
      editTemplateVersion({
        id: selectedTemplateVersion.id,
        fileName: data.file[0].uid,
      });
      return;
    }
    addTemplateVersion({
      templateId: selectedTemplateVersion?.templateId,
      fileName: data.file[0].uid,
    });
  };

  useEffect(() => {
    form.resetFields();
    if (selectedTemplateVersion) {
      form.setFieldsValue(selectedTemplateVersion);
    }
  }, [selectedTemplateVersion]);

  useEffect(() => {
    if (addTemplateVersionStates.isSuccess) {
      toast.success('Версия шаблона успешно добавлена!');
      setShowModal(false);
      form.resetFields();
    }
    if (addTemplateVersionStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [addTemplateVersionStates.isSuccess, addTemplateVersionStates.isError]);

  useEffect(() => {
    if (editTemplateVersionStates.isSuccess) {
      toast.success('Версия шаблона успешно изменена!');
      setShowModal(false);
      form.resetFields();
    }
    if (editTemplateVersionStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [editTemplateVersionStates.isSuccess, editTemplateVersionStates.isError]);

  return (
    <Modal
      open={showModal}
      centered
      title={selectedTemplateVersion?.id ? 'Изменить версию шаблона' : 'Создать версию шаблона'}
      okText={'Сохранить'}
      onCancel={() => setShowModal(false)}
      onOk={() => form.submit()}
      confirmLoading={addTemplateVersionStates.isLoading}
    >
      <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
        <Form.Item label={'Файл версии'} name={'file'} getValueFromEvent={normFile} valuePropName="fileList">
          <FileUpload onChange={() => {}} accept={'*'} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ControlTemplateVersionForm;
