import React, { FC } from 'react';
import { Button, Typography } from 'antd';
import { IFileInfo } from '../../core/types/entities';
import { FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import { handleDownloadFile } from '../../core/utils';

interface IFileTypeButtonGroup {
  items: IFileInfo[];
}

const { Text } = Typography;
const FileTypeButtonGroup: FC<IFileTypeButtonGroup> = ({ items }) => {
  const renderIcon = (item: IFileInfo) => {
    if (item?.originalFileName.includes('doc')) {
      return <FileWordOutlined style={{ fontSize: '20px' }} />;
    }
    if (item?.originalFileName.includes('pdf')) {
      return <FilePdfOutlined style={{ fontSize: '20px' }} />;
    }
  };

  return (
    <>
      {items.length > 0 ? (
        items.map(item => {
          if (!item) {
            return;
          }
          return (
            <React.Fragment key={item?.id}>
              <Button
                shape="circle"
                style={{ color: '#452B79' }}
                type={'text'}
                onClick={() => handleDownloadFile(item)}
              >
                {renderIcon(item)}
              </Button>
            </React.Fragment>
          );
        })
      ) : (
        <Text>-</Text>
      )}
    </>
  );
};

export default FileTypeButtonGroup;
