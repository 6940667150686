import { api } from '../index';
import { IUserCreateFormData, IUserUpdate } from '../../../core/types/forms';
import { ISearchPage, IUser, IUserHistory } from '../../../core/types/entities';
import { ISearchUsersHistoriesPageQuery, ISearchUsersPageQuery } from '../../../core/types/query';
import { IFakeAuthDataResponse } from '../../../core/types/response';

export const usersApi = api.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query<ISearchPage<IUser>, ISearchUsersPageQuery>({
      query: ({ page, size, query, roles, sort }) => ({
        url: '/users',
        params: { page, size, query, roles, sort },
      }),
      providesTags: ['users'],
    }),
    getUserHistories: builder.query<ISearchPage<IUserHistory>, ISearchUsersHistoriesPageQuery>({
      query: ({ page, size, userId }) => ({
        url: '/users/histories',
        params: { page, size, userId: userId },
      }),
    }),
    registerUser: builder.mutation<void, IUserCreateFormData>({
      query: userData => ({
        url: `/users`,
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ['users'],
    }),
    updateUser: builder.mutation<void, IUserUpdate>({
      query: ({ id, data }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['users', 'agent-infos'],
    }),
    banUser: builder.mutation<void, number | undefined>({
      query: id => ({
        url: `/users/${id}/ban`,
        method: 'POST',
      }),
      invalidatesTags: ['users', 'agent-infos'],
    }),
    unBanUser: builder.mutation<void, number | undefined>({
      query: id => ({
        url: `/users/${id}/unban`,
        method: 'POST',
      }),
      invalidatesTags: ['users', 'agent-infos'],
    }),
    getFakeAuthData: builder.mutation<IFakeAuthDataResponse, number | undefined>({
      query: id => ({
        url: `/users/${id}/fake`,
        method: 'POST',
      }),
    }),
    resetUserPassword: builder.mutation<void, number | undefined>({
      query: id => ({
        url: `/users/reset`,
        method: 'POST',
        params: {
          userId: id,
        },
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserHistoriesQuery,
  useRegisterUserMutation,
  useUpdateUserMutation,
  useBanUserMutation,
  useUnBanUserMutation,
  useGetFakeAuthDataMutation,
  useResetUserPasswordMutation,
} = usersApi;
