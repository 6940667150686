import { api } from '../index';
import { ITaxStatus } from '../../../core/types/entities';
export const taxStatusApi = api.injectEndpoints({
  endpoints: builder => ({
    getTaxStatuses: builder.query<ITaxStatus[], void>({
      query: () => ({
        url: '/contracts/tax-status',
      }),
      providesTags: ['tax-status'],
    }),
    getTaxStatus: builder.query<ITaxStatus, number>({
      query: id => ({
        url: `/contracts/tax-status/${id}`,
      }),
    }),
    addTaxStatus: builder.mutation<ITaxStatus, { name: string }>({
      query: data => ({
        url: '/contracts/tax-status',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['tax-status'],
    }),
    updateTaxStatus: builder.mutation<ITaxStatus, [number, { name: string }]>({
      query: ([id, data]) => ({
        url: `/contracts/tax-status/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['tax-status'],
    }),
    removeTaxStatus: builder.mutation<void, number>({
      query: id => ({
        url: `/contracts/tax-status/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['tax-status'],
    }),
  }),
});

export const {
  useGetTaxStatusesQuery,
  useGetTaxStatusQuery,
  useAddTaxStatusMutation,
  useUpdateTaxStatusMutation,
  useRemoveTaxStatusMutation,
} = taxStatusApi;
