import { FC, useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { IUserCreateFormData, IUserUpdateFormData } from '../../core/types/forms';
import { useRegisterUserMutation, useUpdateUserMutation } from '../../store/services/users';
import { toast } from 'react-toastify';
import { RolesEnum } from '../../core/types/enums';
import { getValueFormDatePicker, getValueFormDatePickerProps, translit } from '../../core/utils';
import DatePickerBase from '../UI/DatePickerBase';

interface IUserFormModal {
  open: boolean;
  setClose: () => void;
  initialData?: IUserUpdateFormData;
  userId?: number;
}

const UserFormModal: FC<IUserFormModal> = ({ open, setClose, initialData, userId }) => {
  const [form] = Form.useForm();
  const [registerUser, registerStates] = useRegisterUserMutation();
  const [updateUser, updateStates] = useUpdateUserMutation();
  const handleClose = () => {
    setClose();
    form.resetFields();
  };

  const lastName = Form.useWatch('lastName', form);
  const firstName = Form.useWatch('firstName', form);
  const login = Form.useWatch('login', form);

  const generateLogin = () => {
    if (!lastName && !firstName) {
      toast.error('Для генерации поля "Фамилия" и "Имя" должны быть заполнены');
      return;
    }
    form.setFieldsValue({ login: translit(`${lastName.trim()} ${firstName.trim()}`) });
  };

  useEffect(() => {
    if (open && initialData) {
      form.setFieldsValue({
        ...initialData,
        telegram: initialData.telegram?.split(','),
      });
    }
  }, [open, initialData, form]);

  useEffect(() => {
    if (registerStates.isSuccess) {
      toast.success('Пользователь успешно создан');
      handleClose();
    }
    if (registerStates.isError) {
      toast.error('Ошибка, проверьте поля на корректность');
    }
  }, [registerStates.isSuccess, registerStates.isError]);

  useEffect(() => {
    if (updateStates.isSuccess) {
      toast.success('Пользователь успешно изменен');
      handleClose();
    }
    if (updateStates.isError) {
      toast.error('Ошибка, проверьте поля на корректность');
    }
  }, [updateStates.isSuccess, updateStates.isError]);

  const handleSubmit = (data: IUserUpdateFormData | IUserCreateFormData) => {
    const telegramString = (Array.isArray(data.telegram) && data.telegram.join(',')) || '';

    if (!initialData) {
      const registerUserForm = data as IUserCreateFormData;
      return registerUser({
        ...registerUserForm,
        telegram: telegramString,
      });
    }

    if (initialData && userId) {
      return updateUser({
        id: userId,
        data: {
          ...data,
          telegram: telegramString,
        },
      });
    }

    return;
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={!initialData ? 'Создать пользователя' : 'Изменить пользователя'}
      open={open}
      centered={true}
      okText={!initialData ? 'Создать' : 'Сохранить'}
      cancelText={'Отмена'}
      confirmLoading={registerStates.isLoading || updateStates.isLoading}
      onOk={() => form.submit()}
      onCancel={() => handleClose()}
      afterOpenChange={() => form.getFieldInstance('email')?.focus()}
    >
      <Form form={form} layout={'vertical'} onFinish={handleSubmit}>
        <Form.Item label={'Email'} key={'email'} name={'email'}>
          <Input />
        </Form.Item>
        {!initialData && (
          <Form.Item label={'Пароль'} key={'password'} name={'password'}>
            <Input.Password />
          </Form.Item>
        )}
        <Form.Item
          label={'Телеграм'}
          key={'telegram'}
          name={'telegram'}
          rules={[
            {
              required: true,
              message: 'Поле "Телеграм" обязательное!',
            },
            {
              validator: (_, value) => {
                if (value && !(/^https:\/\/t\.me\//i.test(value) || /^@/i.test(value))) {
                  return Promise.reject('Ошибка валидации, поле должно содержать ссылку на аккаунт или тег');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Select mode={'tags'} open={false} />
        </Form.Item>
        <Form.Item
          label={'Фамилия'}
          key={'lastName'}
          name={'lastName'}
          rules={[
            {
              required: true,
              message: 'Поле "Фамилия" обязательное!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Имя'}
          key={'firstName'}
          name={'firstName'}
          rules={[
            {
              required: true,
              message: 'Поле "Имя" обязательное!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Логин'}
          name={'login'}
          key={'login'}
          rules={[
            {
              required: true,
              message: 'Поле "Логин" обязательное!',
            },
          ]}
        >
          <Row gutter={[20, 20]}>
            <Col xs={24} md={16}>
              <Input value={login} key={lastName + firstName} />
            </Col>
            <Col span={8}>
              <Button type={'primary'} onClick={generateLogin}>
                Сгенерировать
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label={'Отчество'} key={'patronymic'} name={'patronymic'}>
          <Input />
        </Form.Item>
        <Form.Item
          label={'Номер телефона'}
          key={'phoneNumber'}
          name={'phoneNumber'}
          rules={[
            {
              required: true,
              message: 'Поле "Номер телефона" обязательное!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'Комментарии'} key={'comments'} name={'comments'}>
          <Input />
        </Form.Item>
        {!initialData && (
          <Form.Item
            label={'Роль пользователя'}
            key={'role'}
            name={'role'}
            rules={[
              {
                required: true,
                message: 'Поле "Роль пользователя" обязательное!',
              },
            ]}
          >
            <Select allowClear>
              <Select.OptGroup label={'Роль пользователя'} />
              {Object.values(RolesEnum)
                .filter(role => role !== RolesEnum.PUBLIC.toString() && role !== RolesEnum.AGENT.toString())
                .map(role => (
                  <Select.Option key={role}>
                    {role === RolesEnum.ADMIN && 'Администратор'}
                    {role === RolesEnum.MANAGER && 'Куратор'}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label="Дата рождения"
          name="birthDate"
          getValueFromEvent={getValueFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
        >
          <DatePickerBase />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserFormModal;
