import { api } from '../index';
import { ISearchPageQuery } from '../../../core/types/query';
import { DuplicatesInnoHomeworks, IHomework, InnoHomeWork, ISearchPage } from '../../../core/types/entities';

export const homeworksApi = api.injectEndpoints({
  endpoints: builder => ({
    getHomeworks: builder.query<ISearchPage<IHomework>, ISearchPageQuery>({
      query: params => ({
        url: `/inno-homeworks`,
        params: params,
      }),
      providesTags: ['homeworks'],
    }),
    saveHomework: builder.mutation<void, FormData>({
      query: data => ({
        url: `/inno-homeworks`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['homeworks'],
    }),
    innoHomeworksSearch: builder.query<ISearchPage<InnoHomeWork>, ISearchPageQuery>({
      query: params => ({
        url: '/inno-homeworks/search',
        params: params,
      }),
      providesTags: ['homeworks'],
    }),
    duplicatesInnoHomeworks: builder.query<ISearchPage<DuplicatesInnoHomeworks>, ISearchPageQuery>({
      query: params => ({
        url: '/inno-homeworks/duplicates',
        params: params,
      }),
      providesTags: ['homeworks'],
    }),
  }),
});

export const {
  useGetHomeworksQuery,
  useSaveHomeworkMutation,
  useInnoHomeworksSearchQuery,
  useDuplicatesInnoHomeworksQuery,
} = homeworksApi;
