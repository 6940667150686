import {
  useGetContractsQuery,
  useRegenerateContractDocumentsMutation,
  useRemoveContractMutation,
} from '../../store/services/contracts/contracts';
import CustomTable from '../UI/Table';
import { Key, useEffect, useState } from 'react';
import { ISearchContractPageQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import { Button, Col, Dropdown, MenuProps, Modal, Row } from 'antd';
import { RolesEnum, TableFiltersItemsEnum, TemplateList } from '../../core/types/enums';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { IContract, IUser } from '../../core/types/entities';
import { SettingOutlined } from '@ant-design/icons';
import DocsStatusModal from '../Modals/DocsStatusModal';
import { useAppSelector } from '../../core/hooks/redux';
import { toast } from 'react-toastify';
import GenerateRequestsModal from '../Modals/GenerateRequestsModal';
import { RU_DATE } from '../../core/constants/dateFormatting';
import GenerateActsModal from '../Modals/GenerateActsModal';
import { formatTableColumnsBreakWord, getContractStatusName, getFullNameUser, getPosTableNum } from '../../core/utils';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import TableFilters from '../TableFilters';
import { useGetSizeTable } from '../../core/hooks/useGetSizeTable';

const { confirm } = Modal;

const ContractsTable = () => {
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchContractPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const { data } = useGetContractsQuery(paginationAndSearchParams);
  const [removeContract, removeContractStates] = useRemoveContractMutation();
  const [regenerateContractDocuments, regenerateContractDocumentsStates] = useRegenerateContractDocumentsMutation();
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isMassSelect, setIsMassSelect] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isRequestsModalOpen, setIsRequestsModalOpen] = useState(false);
  const [isActsModalOpen, setIsActsModalOpen] = useState(false);
  const isSelectedContracts = selectedRowKeys.length > 0;
  const { role, user } = useAppSelector(state => state.authReducer);
  const { isTableWiderThanMainElement } = useGetSizeTable();

  useEffect(() => {
    if (regenerateContractDocumentsStates.isSuccess) {
      toast.success('Документы договора успешно перегенерированы');
    }
    if (regenerateContractDocumentsStates.isError) {
      toast.error('Возникла проблема при перегенерировании документов договора');
    }
  }, [regenerateContractDocumentsStates.isSuccess, regenerateContractDocumentsStates.isError]);

  useEffect(() => {
    if (removeContractStates.isSuccess) {
      toast.success('Договор успешно удален');
    }
    if (removeContractStates.isError) {
      toast.error('Возникла проблема при удалении договора');
    }
  }, [removeContractStates.isSuccess, removeContractStates.isError]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [paginationAndSearchParams]);

  const contracts = data?.data?.map((contract, index) => ({
    ...contract,
    posNum: getPosTableNum(
      index,
      paginationAndSearchParams.page || DEFAULT_PAGE,
      paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
    ),
    key: contract.id,
  }));

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Номер договора',
      dataIndex: 'number',
      key: 'number',
      render: (number, data) => {
        const contract = data as IContract;
        return <Link to={`/contracts/${contract?.id}`}>№ {number}</Link>;
      },
    },
    {
      title: '#',
      dataIndex: 'ordinalNumber',
      key: 'ordinalNumber',
      align: 'center',
    },
    {
      title: 'Дата договора',
      dataIndex: 'fromDate',
      key: 'fromDate',
      align: 'center',
      render: date => dayjs(date).format(RU_DATE),
    },
    {
      title: 'Срок действия',
      dataIndex: 'toDate',
      key: 'toDate',
      align: 'center',
      render: date => dayjs(date).format(RU_DATE),
    },
    {
      title: 'С заявкой',
      dataIndex: 'withRequests',
      key: 'withRequests',
      render: data => (data ? 'Да' : 'Нет'),
    },
    {
      title: 'Статус',
      dataIndex: 'documentStatus',
      key: 'documentStatus',
      render: status => {
        return getContractStatusName(status);
      },
    },
    {
      title: 'Ответственный',
      dataIndex: 'responsibleUser',
      key: 'responsibleUser',
      render: data => {
        const responsibleUser = data as IUser;
        return getFullNameUser(responsibleUser);
      },
    },
    {
      title: 'Контрагент',
      dataIndex: 'agent',
      key: 'agent',
      render: (data: IUser) => (
        <Link style={{ whiteSpace: 'nowrap' }} to={`/agents/${data?.id}`}>
          {getFullNameUser(data)}
        </Link>
      ),
    },
    {
      title: 'Старший педагог',
      dataIndex: 'seniorTeacher',
      key: 'seniorTeacher',
      render: data => {
        const seniorTeacher = data as IUser;
        return <div style={{ whiteSpace: 'nowrap' }}>{getFullNameUser(seniorTeacher)}</div>;
      },
    },
    {
      title: 'Налоговый статус',
      dataIndex: ['taxStatus', 'name'],
      key: 'taxStatus',
    },
    {
      title: 'Функция',
      dataIndex: ['agentFunction', 'name'],
      key: 'agentFunction',
    },
    {
      title: 'Проект',
      dataIndex: ['project', 'name'],
      key: 'project',
    },
    {
      title: 'Направление',
      dataIndex: ['projectDirection', 'name'],
      key: 'projectDirection',
    },
    {
      title: 'Спецификация',
      dataIndex: ['projectSpecification', 'name'],
      key: 'projectSpecification',
    },
    {
      title: 'Система подписи',
      dataIndex: ['signSystem', 'name'],
      key: 'signSystem',
    },
    {
      title: 'Комментарий',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, data: unknown) => {
        const contract = data as IContract;
        if (role !== RolesEnum.MANAGER) {
          return (
            <Dropdown menu={{ items: renderDropDownItems(contract.id) }}>
              <SettingOutlined style={{ fontSize: '20px' }} />
            </Dropdown>
          );
        } else if (role == RolesEnum.MANAGER && contract.creator.id === user?.id) {
          return (
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'drop-down-item-2',
                    onClick: () => {
                      confirm({
                        title: 'Вы точно хотите удалить договор?',
                        centered: true,
                        cancelText: 'Отмена',
                        okText: 'Подтвердить',
                        onOk() {
                          removeContract(contract.id);
                        },
                      });
                    },
                    label: 'Удалить',
                    danger: true,
                  },
                ],
              }}
            >
              <SettingOutlined style={{ fontSize: '20px' }} />
            </Dropdown>
          );
        }
      },
    },
  ];

  const renderDropDownItems = (id: number) => {
    const items: MenuProps['items'] = [
      {
        key: 'drop-down-item-1',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите перегенерировать документы договора?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              regenerateContractDocuments(id);
            },
          });
        },
        label: 'Перегенерировать документы договора',
      },
    ];

    if (role === RolesEnum.ADMIN.toString()) {
      items.push({
        key: 'drop-down-item-2',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить договор?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              removeContract(id);
            },
          });
        },
        label: 'Удалить',
        danger: true,
      });
    }
    return items;
  };
  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };
  const onSelectAll = (selected: boolean, selectedRows: Key[], changeRows: IContract[]) => {
    if (!isMassSelect && selectedRowKeys.length === contracts?.length) {
      return setIsMassSelect(true);
    }
    if (selected) {
      const newSelectedRowKeys = changeRows.map((row: IContract) => row.id);
      setSelectedRowKeys(prevState => [...prevState, ...newSelectedRowKeys]);
    } else {
      setSelectedRowKeys([]);
    }

    setIsMassSelect(selected);
  };

  const onSelect = (contract: IContract) => {
    const isSelected = selectedRowKeys.includes(contract.id);
    if (isMassSelect && selectedRowKeys.length === contracts?.length) {
      setIsMassSelect(false);
    }
    if (isSelected) {
      return setSelectedRowKeys(selectedRowKeys.filter(key => key !== contract.id));
    }
    setSelectedRowKeys([...selectedRowKeys, contract.id]);
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect,
    onSelectAll,
  };

  return (
    <>
      <Row gutter={[20, 0]}>
        <Col xs={24} style={{ marginBottom: '20px' }}>
          <Button type={'primary'} onClick={() => navigate('create/')}>
            Создать договор
          </Button>
        </Col>
      </Row>
      <TableFilters
        fields={[
          TableFiltersItemsEnum.FIND_BY_AGENT_FIO,
          TableFiltersItemsEnum.PROJECT,
          TableFiltersItemsEnum.FROM_DATE,
          TableFiltersItemsEnum.TO_DATE,
          TableFiltersItemsEnum.VALIDITY_STATUS,
          TableFiltersItemsEnum.PROJECT_DIRECTION,
          TableFiltersItemsEnum.PROJECT_SPECIFICATION,
          TableFiltersItemsEnum.SIGN_SYSTEM,
          TableFiltersItemsEnum.TAX_STATUS,
          TableFiltersItemsEnum.DATE_WITHOUT_REQUEST,
          TableFiltersItemsEnum.WITH_REQUEST,
        ]}
        params={paginationAndSearchParams}
        setParams={setPaginationAndSearchParams}
        footer={
          <>
            <Col>
              <Button
                onClick={() => setIsRequestsModalOpen(true)}
                type={'primary'}
                disabled={!isSelectedContracts || !paginationAndSearchParams.withRequests}
              >
                Сформировать заявки
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => setIsActsModalOpen(true)}
                type={'primary'}
                disabled={!isSelectedContracts || paginationAndSearchParams.withRequests !== false}
              >
                Сформировать акты
              </Button>
            </Col>
            <Col>
              <Button onClick={() => setIsStatusModalOpen(true)} type={'primary'} disabled={!isSelectedContracts}>
                Изменить статус
              </Button>
            </Col>
            {isMassSelect && (
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Выбрано {data?.total}
              </Col>
            )}
          </>
        }
      />
      <CustomTable
        tableKey={'ContractsTable'}
        dataSource={contracts}
        rowSelection={rowSelection}
        columns={formatTableColumnsBreakWord(columns, isTableWiderThanMainElement)}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <DocsStatusModal
        open={isStatusModalOpen}
        setClose={() => setIsStatusModalOpen(false)}
        ids={selectedRowKeys}
        type={TemplateList.CONTRACT}
        withParams={isMassSelect}
        queryParamsForm={{
          ...paginationAndSearchParams,
          page: undefined,
          size: undefined,
        }}
      />
      <GenerateRequestsModal
        open={isRequestsModalOpen}
        setClose={() => setIsRequestsModalOpen(false)}
        ids={selectedRowKeys}
      />
      <GenerateActsModal
        open={isActsModalOpen}
        setClose={() => setIsActsModalOpen(false)}
        ids={selectedRowKeys}
        documentType={TemplateList.CONTRACT}
      />
    </>
  );
};

export default ContractsTable;
