import Title from 'antd/es/typography/Title';
import ContractsTable from '../../components/Tables/ContractsTable';

const Contracts = () => {
  return (
    <div>
      <Title>Договоры</Title>
      <ContractsTable />
    </div>
  );
};

export default Contracts;
