import Title from 'antd/es/typography/Title';
import { useParams } from 'react-router-dom';
import RequestHistoriesTable from '../../components/Tables/RequestHistoriesTable';
const RequestHistoriesPage = () => {
  const { id } = useParams();
  return (
    <>
      <Title>История изменения заявки</Title>
      <RequestHistoriesTable id={id} />
    </>
  );
};

export default RequestHistoriesPage;
