import { FC, useState } from 'react';
import CustomTable from '../UI/Table';
import { ISearchAgentsHistoriesPageQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useGetAgentHistoriesQuery } from '../../store/services/users/agents-infos';
import { RU_DATE, RU_DATE_TIME } from '../../core/constants/dateFormatting';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import { getPosTableNum } from '../../core/utils';

interface IAgentsHistoriesTable {
  id: string | undefined;
}

const AgentsHistoriesTable: FC<IAgentsHistoriesTable> = ({ id }) => {
  const [searchAndPaginationParams, setSearchAndPaginationParams] = useState<ISearchAgentsHistoriesPageQuery>({
    agentId: id,
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const { data } = useGetAgentHistoriesQuery(searchAndPaginationParams);
  const paginationCurrentPage = searchAndPaginationParams.page ? searchAndPaginationParams.page + 1 : 1;

  const agentHistories = data?.data.map((agentHistory, index) => ({
    ...agentHistory,
    posNum: getPosTableNum(
      index,
      searchAndPaginationParams.page || DEFAULT_PAGE,
      searchAndPaginationParams.size || DEFAULT_PAGE_SIZE
    ),
    changedBy: `${agentHistory.creator.lastName} ${agentHistory.creator.firstName} ${
      agentHistory.creator.patronymic ? agentHistory.creator.patronymic : ''
    }`,
    key: agentHistory.id,
  }));

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Обновил/а',
      dataIndex: 'changedBy',
      key: 'changedBy',
    },
    {
      title: 'Дата обновления',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: dateAndTime => {
        return dayjs(dateAndTime).format(RU_DATE_TIME);
      },
    },
    {
      title: 'Страна',
      dataIndex: 'country',
      key: 'country',
      render: country => {
        if (country) {
          return country;
        }
      },
    },
    {
      title: 'Серия паспорта',
      dataIndex: 'passportSeries',
      key: 'passportSeries',
      render: passportSeries => {
        if (passportSeries) {
          return passportSeries;
        }
      },
    },
    {
      title: 'Номер паспорта',
      dataIndex: 'passportNumber',
      key: 'passportNumber',
      render: passportNumber => {
        if (passportNumber) {
          return passportNumber;
        }
      },
    },
    {
      title: 'Кем выдан паспорт',
      dataIndex: 'passportReleasedBy',
      key: 'passportReleasedBy',
      render: passportReleasedBy => {
        if (passportReleasedBy) {
          return passportReleasedBy;
        }
      },
    },
    {
      title: 'Дата выдачи паспорта',
      dataIndex: 'passportReleasedAt',
      key: 'passportReleasedAt',
      align: 'center',
      render: passportReleasedAt => {
        if (passportReleasedAt) {
          return dayjs(passportReleasedAt).format(RU_DATE);
        }
      },
    },
    {
      title: 'Налоговый статус',
      dataIndex: ['taxStatus', 'name'],
      key: 'taxStatusName',
    },
    {
      title: 'Снилс',
      dataIndex: 'snils',
      key: 'snils',
      render: snils => {
        if (snils) {
          return snils;
        }
      },
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      key: 'inn',
      render: inn => {
        if (inn) {
          return inn;
        }
      },
    },
    {
      title: 'Адрес регистрации',
      dataIndex: 'registrationAddress',
      key: 'registrationAddress',
      render: registrationAddress => {
        if (registrationAddress) {
          return registrationAddress;
        }
      },
    },
    {
      title: 'Адрес проживания',
      dataIndex: 'residentialAddress',
      key: 'residentialAddress',
      render: residentialAddress => {
        if (residentialAddress) {
          return residentialAddress;
        }
      },
    },
    {
      title: 'Р/счет',
      dataIndex: 'paymentAccount',
      key: 'paymentAccount',
      render: paymentAccount => {
        if (paymentAccount) {
          return paymentAccount;
        }
      },
    },
    {
      title: 'Корр. счет',
      dataIndex: 'corrAccount',
      key: 'corrAccount',
      render: corrAccount => {
        if (corrAccount) {
          return corrAccount;
        }
      },
    },
    {
      title: 'БИК',
      dataIndex: 'bik',
      key: 'bik',
      render: bik => {
        if (bik) {
          return bik;
        }
      },
    },
    {
      title: 'Банк получателя',
      dataIndex: 'recipientBank',
      key: 'recipientBank',
      render: recipientBank => {
        if (recipientBank) {
          return recipientBank;
        }
      },
    },
    {
      title: 'ИНН банка',
      dataIndex: 'bankInn',
      key: 'bankInn',
      render: bankInn => {
        if (bankInn) {
          return bankInn;
        }
      },
    },
    {
      title: 'КПП банка',
      dataIndex: 'bankKpp',
      key: 'bankKpp',
      render: bankKpp => {
        if (bankKpp) {
          return bankKpp;
        }
      },
    },
  ];
  const handlePaginationChange = (page: number, size: number) => {
    setSearchAndPaginationParams({
      ...searchAndPaginationParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      <CustomTable
        tableKey={'AgentsHistoriesTable'}
        dataSource={agentHistories}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: searchAndPaginationParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
    </>
  );
};

export default AgentsHistoriesTable;
