import { theme, ThemeConfig } from 'antd';

const themeConfig: ThemeConfig = {
  token: {
    colorPrimary: '#3f2275',
    borderRadius: 8,
  },
  components: {
    Menu: {
      darkItemBg: 'rgba(0,0,0,0)',
      darkSubMenuItemBg: 'rgba(0, 0, 0, 0.40)',
      darkItemColor: '#fff',
    },
    Segmented: {
      colorBgLayout: 'rgb(215, 215, 215)',
    },
    Button: {
      controlOutline: 'none',
    },
    Modal: {
      titleColor: '#452B79',
      titleFontSize: 20,
    },
  },
  algorithm: theme.defaultAlgorithm,
};

export default themeConfig;
