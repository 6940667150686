import CustomTable from '../UI/Table';
import { Key, useEffect, useRef, useState } from 'react';
import { ISearchActPageQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IAct, IUser } from '../../core/types/entities';
import { SettingOutlined } from '@ant-design/icons';
import { useGetActsQuery, useRegenerateActDocumentsMutation, useRemoveActMutation } from '../../store/services/acts';
import ControlActForm from '../Forms/ActForms/ControlActForm';
import { RU_DATE } from '../../core/constants/dateFormatting';
import { formatTableColumnsBreakWord, getFullNameUser, getPosTableNum } from '../../core/utils';
import { actsStatusTranslations } from '../../core/constants/translations';
import ActsPaymentsSettingsModal from '../Modals/ActsPaymentsSettingsModal';
import { Link } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import { toast } from 'react-toastify';
import { Button, Col, Dropdown, MenuProps, Modal, Row } from 'antd';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum, TableFiltersItemsEnum } from '../../core/types/enums';
import TableFilters from '../TableFilters';
import { useGetSizeTable } from '../../core/hooks/useGetSizeTable';

const { confirm } = Modal;

const ActsTable = () => {
  const { role, user } = useAppSelector(state => state.authReducer);
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchActPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const { data } = useGetActsQuery(paginationAndSearchParams);
  const [removeAct, removeActStates] = useRemoveActMutation();
  const [regenerateActDocuments, regenerateActDocumentsStates] = useRegenerateActDocumentsMutation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isMassSelect, setIsMassSelect] = useState(false);
  const [showControlAct, setShowControlAct] = useState(false);
  const selectedAct = useRef<IAct | undefined>();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { isTableWiderThanMainElement } = useGetSizeTable();

  const acts = data?.data?.map((contract, index) => ({
    ...contract,
    posNum: getPosTableNum(
      index,
      paginationAndSearchParams.page || DEFAULT_PAGE,
      paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
    ),
    key: contract.id,
  }));

  useEffect(() => {
    if (removeActStates.isSuccess) {
      toast.success('Акт успешно удален');
    }
    if (removeActStates.isError) {
      toast.error('Возникла проблема при удалении акта');
    }
  }, [removeActStates.isError, removeActStates.isSuccess]);

  useEffect(() => {
    if (regenerateActDocumentsStates.isSuccess) {
      toast.success('Документы акта успешно перегенерированы');
    }
    if (regenerateActDocumentsStates.isError) {
      toast.error('Возникла проблема при перегенерировании документов акта');
    }
  }, [regenerateActDocumentsStates.isSuccess, regenerateActDocumentsStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Номер акта',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
    },
    {
      title: 'Дата Акта',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      render: dateAndTime => dayjs(dateAndTime).format(RU_DATE),
    },
    {
      title: 'Дата оплаты',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
      render: dateAndTime => dateAndTime && dayjs(dateAndTime).format(RU_DATE),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: status => actsStatusTranslations[status],
    },
    {
      title: 'Контрагент',
      dataIndex: 'agent',
      key: 'agent',
      render: (data: IUser) => (
        <Link style={{ whiteSpace: 'nowrap' }} to={`/agents/${data?.id}`}>
          {getFullNameUser(data)}
        </Link>
      ),
    },
    {
      title: 'Заявка',
      dataIndex: ['request', 'number'],
      key: 'request',
    },
    {
      title: 'Договор',
      dataIndex: '',
      key: 'contract',
      render: data => {
        const act = data as IAct;
        const contract = act.contract || act.request.contract;

        if (contract) {
          return <Link to={`/contracts/${contract?.id}`}>№ {contract?.number}</Link>;
        }
      },
    },
    {
      title: 'Комментарий',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, data: unknown) => {
        const act = data as IAct;
        return (
          <Dropdown menu={{ items: renderDropDownItems(act) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  const renderDropDownItems = (act: IAct) => {
    const isManager = role === RolesEnum.MANAGER;
    const isAdmin = role === RolesEnum.ADMIN;
    const hasUser = !!user;
    const isResponsibleUserInContract = hasUser && act.contract?.responsibleUser?.id === user.id;
    const isResponsibleUserInRequestContract = hasUser && act.request?.contract?.responsibleUser?.id === user.id;

    const items: MenuProps['items'] = [
      {
        key: 'drop-down-item-1',
        onClick: () => {
          selectedAct.current = act;
          setShowControlAct(true);
        },
        label: 'Редактировать',
      },
      {
        key: 'drop-down-item-2',
        onClick: () => {
          window.open(`/acts/histories/${act.id}`, '_blank');
        },
        label: 'История изменений',
      },
      {
        key: 'drop-down-item-3',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите перегенерировать документы акта?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              regenerateActDocuments(act.id);
            },
          });
        },
        label: 'Перегенерировать документы акта',
      },
    ];

    if (isManager) {
      items.splice(-1, 1);
    }

    if ((isManager && (isResponsibleUserInContract || isResponsibleUserInRequestContract)) || isAdmin) {
      items.push({
        key: 'drop-down-item-4',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить акт?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              removeAct(act.id);
            },
          });
        },
        label: 'Удалить',
        danger: true,
      });
    }

    return items;
  };
  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  const onSelectAll = (selected: boolean, selectedRows: Key[], changeRows: IAct[]) => {
    if (!isMassSelect && selectedRowKeys.length === acts?.length) {
      return setIsMassSelect(true);
    }
    if (selected) {
      const newSelectedRowKeys = changeRows.map((row: IAct) => row.id);
      setSelectedRowKeys(prevState => [...prevState, ...newSelectedRowKeys]);
    } else {
      setSelectedRowKeys([]);
    }

    setIsMassSelect(selected);
  };

  const onSelect = (act: IAct) => {
    const isSelected = selectedRowKeys.includes(act.id);
    if (isMassSelect && selectedRowKeys.length === acts?.length) {
      setIsMassSelect(false);
    }
    if (isSelected) {
      return setSelectedRowKeys(selectedRowKeys.filter(key => key !== act.id));
    }
    setSelectedRowKeys([...selectedRowKeys, act.id]);
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect,
    onSelectAll,
  };

  return (
    <>
      <Row gutter={[20, 0]}>
        <Col xs={24} style={{ marginBottom: '20px' }}>
          <Button
            type={'primary'}
            onClick={() => {
              selectedAct.current = undefined;
              setShowControlAct(true);
            }}
          >
            Создать акт
          </Button>
        </Col>
      </Row>
      <TableFilters
        fields={[
          TableFiltersItemsEnum.FIND_BY_AGENT_FIO,
          TableFiltersItemsEnum.PROJECT,
          TableFiltersItemsEnum.FROM_DATE,
          TableFiltersItemsEnum.TO_DATE,
          TableFiltersItemsEnum.FROM_PAYMENT_DATE,
          TableFiltersItemsEnum.TO_PAYMENT_DATE,
          TableFiltersItemsEnum.CONTRACT,
          TableFiltersItemsEnum.REQUEST,
          TableFiltersItemsEnum.PROJECT_DIRECTION,
          TableFiltersItemsEnum.PROJECT_SPECIFICATION,
          TableFiltersItemsEnum.SIGN_SYSTEM,
          TableFiltersItemsEnum.TAX_STATUS,
        ]}
        params={paginationAndSearchParams}
        setParams={setPaginationAndSearchParams}
        footer={
          <>
            <Col>
              <Button
                type={'primary'}
                disabled={selectedRowKeys.length === 0}
                onClick={() => setIsPaymentModalOpen(true)}
              >
                Настройки оплат
              </Button>
            </Col>
            {isMassSelect && (
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Выбрано {data?.total}
              </Col>
            )}
          </>
        }
      />
      <CustomTable
        tableKey={'ActsTable'}
        dataSource={acts}
        rowSelection={rowSelection}
        columns={formatTableColumnsBreakWord(columns, isTableWiderThanMainElement)}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <ControlActForm showModal={showControlAct} setShowModal={setShowControlAct} selectedAct={selectedAct.current} />
      <ActsPaymentsSettingsModal
        open={isPaymentModalOpen}
        setClose={() => setIsPaymentModalOpen(false)}
        ids={selectedRowKeys}
        withParams={isMassSelect}
        actQueryParamsForm={{
          ...paginationAndSearchParams,
          page: undefined,
          size: undefined,
        }}
      />
    </>
  );
};

export default ActsTable;
