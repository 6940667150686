import { api } from '../index';
import { ISearchPage, Template, TemplateVersion } from '../../../core/types/entities';
import { ISearchTemplatesPageQuery } from '../../../core/types/query';
import { ITemplateCreateData, TemplateVersionControlData } from '../../../core/types/forms';

export const templatesApi = api.injectEndpoints({
  endpoints: builder => ({
    getTemplates: builder.query<ISearchPage<Template>, ISearchTemplatesPageQuery>({
      query: params => ({ url: `/patterns`, params }),
      providesTags: ['template'],
    }),
    getTemplate: builder.query<void, number>({
      query: id => ({
        url: `/patterns/${id}`,
      }),
      providesTags: ['template'],
    }),
    getTemplateVersions: builder.query<ISearchPage<TemplateVersion>, number>({
      query: patternId => ({
        url: `/patterns/${patternId}/versions`,
      }),
      providesTags: ['template-version'],
    }),
    getTemplateVersionsByTemplateId: builder.mutation<ISearchPage<TemplateVersion>, number>({
      query: patternId => ({
        url: `/patterns/${patternId}/versions`,
      }),
      invalidatesTags: ['template'],
    }),
    getTemplateVersionById: builder.query<void, number>({
      query: patternVersionId => ({
        url: `/patterns/versions/${patternVersionId}`,
      }),
      providesTags: ['template'],
    }),
    addTemplate: builder.mutation<void, ITemplateCreateData>({
      query: data => ({
        url: '/patterns',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['template'],
    }),
    editTemplate: builder.mutation<void, ITemplateCreateData>({
      query: data => ({
        url: `/patterns/${data.id}`,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['template'],
    }),
    addTemplateVersion: builder.mutation<void, TemplateVersionControlData>({
      query: data => ({
        url: `/patterns/${data.templateId}/versions`,
        method: 'POST',
        body: {
          fileName: data.fileName,
        },
      }),
      invalidatesTags: ['template'],
    }),
    editTemplateVersion: builder.mutation<void, TemplateVersionControlData>({
      query: data => ({
        url: `/patterns/versions/${data.id}`,
        body: {
          fileName: data.fileName,
        },
        method: 'PATCH',
      }),
      invalidatesTags: ['template', 'template-version'],
    }),
  }),
});

export const {
  useGetTemplateQuery,
  useGetTemplatesQuery,
  useGetTemplateVersionsQuery,
  useAddTemplateMutation,
  useEditTemplateMutation,
  useAddTemplateVersionMutation,
  useEditTemplateVersionMutation,
  useGetTemplateVersionsByTemplateIdMutation,
} = templatesApi;
