import { FC, Key, useEffect, useState } from 'react';
import { Form, Modal, Segmented, Select } from 'antd';
import { ActStatus } from '../../core/types/enums';
import {
  getActsStatusName,
  getExceptionMessage,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
  isFetchBaseQueryError,
} from '../../core/utils';
import { useChangePaymentsDateActsMutation, useChangePaymentsStatusActsMutation } from '../../store/services/acts';
import { toast } from 'react-toastify';
import { IMassActionActs } from '../../core/types/forms';
import { IException } from '../../core/types/response';
import DatePickerBase from '../UI/DatePickerBase';

interface IActsPaymentsSettingsModal extends IMassActionActs {
  open: boolean;
  setClose: () => void;
  ids: Key[];
}

interface IStep {
  key: 'status' | 'date';
  title: string;
}

const steps: IStep[] = [
  {
    key: 'status',
    title: 'Статус оплаты',
  },
  {
    key: 'date',
    title: 'Дата оплаты',
  },
];
const ActsPaymentsSettingsModal: FC<IActsPaymentsSettingsModal> = ({
  open,
  setClose,
  ids,
  withParams,
  actQueryParamsForm,
}) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState<string | undefined>('status');
  const [changePaymentStatus, changePaymentStatusStates] = useChangePaymentsStatusActsMutation();
  const [changePaymentDate, changePaymentStatusDate] = useChangePaymentsDateActsMutation();

  useEffect(() => {
    if (changePaymentStatusStates.isSuccess) {
      toast.success('Статус оплаты у актов успешно изменен');
      handleCloseModal();
    }
    if (isFetchBaseQueryError(changePaymentStatusStates.error)) {
      toast.error(
        getExceptionMessage(changePaymentStatusStates.error.data as IException) ||
          'Произошла ошибка при изменении статуса оплаты актов'
      );
    }
  }, [changePaymentStatusStates.isSuccess, changePaymentStatusStates.isError]);

  useEffect(() => {
    if (changePaymentStatusDate.isSuccess) {
      toast.success('Дата оплаты у актов успешно изменена');
      handleCloseModal();
    }
    if (isFetchBaseQueryError(changePaymentStatusDate.error)) {
      toast.error(
        getExceptionMessage(
          (changePaymentStatusDate.error.data as IException) || 'Произошла ошибка при изменении даты оплаты актов'
        )
      );
    }
  }, [changePaymentStatusDate.isSuccess, changePaymentStatusDate.isError]);
  const handleSubmitForm = () => {
    const formData = form.getFieldsValue();
    const data = {
      withParams: withParams,
      actQueryParamsForm: withParams ? actQueryParamsForm : undefined,
      entityIds: !withParams ? { ids } : undefined,
    };

    if (currentStep === 'status') {
      return changePaymentStatus([formData.status, data]);
    }
    if (currentStep === 'date') {
      return changePaymentDate([formData.date, data]);
    }
  };

  const handleCloseModal = () => {
    setClose();
    form.resetFields();
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={'Настройки оплаты актов'}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={handleSubmitForm}
      onCancel={handleCloseModal}
      okText={'Сохранить'}
    >
      <Segmented
        block
        value={steps.find(step => step.key === currentStep)?.title}
        options={steps.map(step => step.title)}
        onChange={title => {
          setCurrentStep(steps.find(step => step.title === title)?.key);
          form.resetFields();
        }}
        style={{ margin: '20px 0' }}
      />
      <Form form={form} layout={'vertical'}>
        {currentStep === 'status' && (
          <Form.Item label={'Статус оплаты'} name={'status'} labelCol={{ span: 24 }} style={{ margin: '30px 0' }}>
            <Select>
              <Select.OptGroup label={'Статус оплаты'} />
              {Object.values(ActStatus).map(status => (
                <Select.Option key={status}>{getActsStatusName(status)}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {currentStep === 'date' && (
          <Form.Item
            label={'Дата оплаты'}
            name={'date'}
            getValueFromEvent={getValueFormDatePicker}
            getValueProps={getValueFormDatePickerProps}
          >
            <DatePickerBase
              disabledDate={date => {
                const dayOfWeek = date.day();
                return dayOfWeek === 0 || dayOfWeek === 6;
              }}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default ActsPaymentsSettingsModal;
