import { CSSProperties, useEffect } from 'react';
import Title from 'antd/es/typography/Title';

const Welcome = () => {
  useEffect(() => {
    const layoutStyles: CSSProperties = {
      backgroundImage: 'url(/images/mxm.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
    };

    const layout = document.getElementById('content-layout');

    if (layout) {
      Object.assign(layout.style, layoutStyles);
    }

    return () => {
      if (layout) {
        Object.assign(layout.style, { backgroundImage: '' } as CSSProperties);
      }
    };
  }, []);

  return (
    <div className={'welcome-wrp'}>
      <Title className={'welcome-title'}>Добро пожаловать!</Title>
    </div>
  );
};

export default Welcome;
