import Title from 'antd/es/typography/Title';
import DealsTable from '../../components/Tables/DealsTable';

const DealsPage = () => {
  return (
    <>
      <Title>Доп. соглашения</Title>
      <DealsTable />
    </>
  );
};

export default DealsPage;
