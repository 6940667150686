import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import { Button, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IServiceUnit } from '../../core/types/entities';
import {
  useAddServiceUnitMutation,
  useGetServiceUnitsQuery,
  useRemoveServiceUnitMutation,
  useUpdateServiceUnitMutation,
} from '../../store/services/contracts/service-units';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';

const { confirm } = Modal;
const ServiceUnitsTable = () => {
  const { data } = useGetServiceUnitsQuery();
  const [addServiceUnit, addServiceUnitStates] = useAddServiceUnitMutation();
  const [updateServiceUnit, updateServiceUnitStates] = useUpdateServiceUnitMutation();
  const [removeServiceUnit, removeServiceUnitStates] = useRemoveServiceUnitMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedServiceUnit, setSelectedServiceUnit] = useState<IServiceUnit | undefined>(undefined);
  const { role } = useAppSelector(state => state.authReducer);

  const serviceUnitsData = data?.map((status, index) => ({
    ...status,
    posNum: index + 1,
    key: status.id,
  }));

  useEffect(() => {
    if (addServiceUnitStates.isSuccess) {
      toast.success('Единица измерения успешно добавлена!');
      setShowModal(false);
      form.resetFields();
    }
    if (addServiceUnitStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (removeServiceUnitStates.isError) {
      toast.error('Произошла ошибка при удалении единицы измерения');
    }
  }, [addServiceUnitStates.isSuccess, addServiceUnitStates.isError, removeServiceUnitStates.isError]);

  useEffect(() => {
    if (updateServiceUnitStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateServiceUnitStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateServiceUnitStates.isSuccess, updateServiceUnitStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'Единица измерения',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const serviceUnit = data as IServiceUnit;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              data-testid={`edit-service-unit-${serviceUnit.id}`}
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue(serviceUnit);
                setSelectedServiceUnit(serviceUnit);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              data-testid={`delete-service-unit-${serviceUnit.id}`}
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить единицу измерения?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveServiceUnit(serviceUnit.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: { name: string }) => {
    if (selectedServiceUnit) {
      return updateServiceUnit([selectedServiceUnit.id, data]);
    }
    addServiceUnit(data);
  };

  const handleRemoveServiceUnit = (id: number) => {
    removeServiceUnit(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedServiceUnit(undefined);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable tableKey={'SeviceUnitsTable'} columns={columns} dataSource={serviceUnitsData} pagination={false} />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(selectedServiceUnit ? 'Изменить' : 'Добавить') + ' единицу измерения'}
        okText={selectedServiceUnit ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addServiceUnitStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Единица измерения'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Единица измерения" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ServiceUnitsTable;
