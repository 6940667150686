import Title from 'antd/es/typography/Title';
import ActsTable from '../../components/Tables/ActsTable';

const Acts = () => {
  return (
    <div>
      <Title>Акты</Title>
      <ActsTable />
    </div>
  );
};

export default Acts;
