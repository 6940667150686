import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { toast } from 'react-toastify';
import { ColumnsType } from 'antd/lib/table';
import { IProjectSpecification } from '../../core/types/entities';
import CustomTable from '../UI/Table';
import {
  useAddProjectSpecificationMutation,
  useGetProjectSpecificationsQuery,
  useRemoveProjectSpecificationMutation,
  useUpdateProjectSpecificationMutation,
} from '../../store/services/contracts/projects-specifications';
import { useGetProjectsQuery } from '../../store/services/contracts/projects';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';

const { Option } = Select;
const { confirm } = Modal;

const ProjectSpecificationsTable = () => {
  const { data: projects } = useGetProjectsQuery();
  const { data } = useGetProjectSpecificationsQuery();
  const [addProjectSpecification, addProjectSpecificationStates] = useAddProjectSpecificationMutation();
  const [updateProjectSpecification, updateProjectSpecificationStates] = useUpdateProjectSpecificationMutation();
  const [removeProjectSpecification, removeProjectSpecificationStates] = useRemoveProjectSpecificationMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedProjectSpecification, setSelectedProjectSpecification] = useState<IProjectSpecification | undefined>(
    undefined
  );
  const { role } = useAppSelector(state => state.authReducer);

  const projectSpecificationData = data?.map((projectSpecification, index) => ({
    ...projectSpecification,
    posNum: index + 1,
    key: projectSpecification.id,
  }));

  useEffect(() => {
    if (addProjectSpecificationStates.isSuccess) {
      toast.success('Новая cпецификация успешно добавлена!');
      setShowModal(false);
      form.resetFields();
    }
    if (addProjectSpecificationStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (removeProjectSpecificationStates.isError) {
      toast.error('Произошла ошибка при удалении cпецификации');
    }
  }, [
    addProjectSpecificationStates.isSuccess,
    addProjectSpecificationStates.isError,
    removeProjectSpecificationStates.isError,
  ]);

  useEffect(() => {
    if (updateProjectSpecificationStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateProjectSpecificationStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateProjectSpecificationStates.isSuccess, updateProjectSpecificationStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'Cпецификация',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const projectSpecification = data as IProjectSpecification;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              data-testid={`edit-project-specification-${projectSpecification.id}`}
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue(projectSpecification);
                setSelectedProjectSpecification(projectSpecification);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              data-testid={`delete-project-specification-${projectSpecification.id}`}
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить cпецификацию?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveContractType(projectSpecification.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: { name: string }) => {
    if (selectedProjectSpecification) {
      return updateProjectSpecification([selectedProjectSpecification.id, data]);
    }
    addProjectSpecification(data);
  };

  const handleRemoveContractType = (id: number) => {
    removeProjectSpecification(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProjectSpecification(undefined);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable
        tableKey={'ProjectSpecificationsTable'}
        columns={columns}
        dataSource={projectSpecificationData}
        pagination={false}
      />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(selectedProjectSpecification ? 'Редактировать' : 'Добавить') + ' cпецификацию'}
        okText={selectedProjectSpecification ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addProjectSpecificationStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Спецификация'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Спецификация" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          {!selectedProjectSpecification && (
            <Form.Item
              label={'Проект'}
              key={'projectId'}
              name={'projectId'}
              rules={[
                {
                  required: true,
                  message: 'Поле "Проект" обязательное!',
                },
              ]}
            >
              <Select placeholder="Выберите проект" allowClear>
                <Select.OptGroup label={'Выберите проект'} />
                {projects &&
                  projects.map(p => (
                    <Option value={p.id} key={p.id}>
                      {p.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ProjectSpecificationsTable;
