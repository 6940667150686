import Title from 'antd/es/typography/Title';
import RequestsTable from '../../components/Tables/RequestsTable';
const RequestsPage = () => {
  return (
    <div>
      <Title>Заявки</Title>
      <RequestsTable />
    </div>
  );
};

export default RequestsPage;
