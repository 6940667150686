import axios from 'axios';
import AuthService from '../../services/auth.service';

export const apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : 'https://backend.dev.docs.maxima.school';
const $api = axios.create({
  baseURL: apiUrl,
});

$api.interceptors.request.use(
  async req => {
    if (req.url?.includes('auth')) {
      return req;
    }
    const token = encodeURIComponent(`${localStorage.getItem('accessToken')}`);
    if (token && req.headers) {
      req.headers.Authorization = `Bearer ${token}`;
    }

    return req;
  },

  error => {
    return Promise.reject(error);
  }
);

$api.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    const originalRequest = error.config;

    if (
      (error.response.status === 400 || error.response.status === 401 || error.response.status === 406) &&
      !error.response.config.url.includes('auth/refresh')
    ) {
      originalRequest._isRetry = true;
      const refreshToken = encodeURIComponent(`${localStorage.getItem('refreshToken')}`);
      if (refreshToken) {
        try {
          const response = await AuthService.refreshToken(refreshToken);
          localStorage.setItem('accessToken', response.data.accessToken.token);
          localStorage.setItem('refreshToken', response.data.refreshToken.token);
          originalRequest.url = originalRequest.url.split('=')[0] + '=' + response.data.accessToken.token;

          return $api.request(originalRequest);
        } catch (error) {
          console.log('НЕ АВТОРИЗОВАН');
          await Promise.reject(error);
        }
      }
      await Promise.reject(error);
    }
    await Promise.reject(error);
  }
);
export { $api };
