import AgentProfile from '../components/AgentProfile';
import { useLocation, useParams } from 'react-router-dom';

const Main = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  return (
    <>
      <AgentProfile agentId={id} key={pathname} />
    </>
  );
};

export default Main;
