import Title from 'antd/es/typography/Title';
import MeetingsTable from '../../components/Tables/MeetingsTable';

const Meetings = () => {
  return (
    <>
      <Title>Видео-конференции</Title>
      <MeetingsTable />
    </>
  );
};

export default Meetings;
