import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Segmented, Select, Checkbox } from 'antd';
import { IAgentInfos } from '../../core/types/entities';
import {
  useGetFieldsToExcludeForAgentQuery,
  useRegisterAgentMutation,
  useUpdateAgentInfosMutation,
} from '../../store/services/users/agents-infos';
import { useGetTaxStatusesQuery } from '../../store/services/contracts/tax-status';
import { useUpdateUserMutation } from '../../store/services/users';
import { toast } from 'react-toastify';
import {
  getExceptionMessage,
  getValueFormDatePicker,
  getValueFormDatePickerProps,
  isFetchBaseQueryError,
  translit,
} from '../../core/utils';
import DatePickerBase from '../UI/DatePickerBase';
import { IException } from '../../core/types/response';
import { formattedFieldsToExcludeForAgent } from '../Tables/AgentsTable';

interface IAgentFormModal {
  open: boolean;
  setClose: () => void;
  initialData?: IAgentInfos;
}

interface ISteps {
  key: 'agent' | 'agentInfo';
  title: string;
}

const steps: ISteps[] = [
  {
    key: 'agent',
    title: 'Данные контрагента',
  },
  {
    key: 'agentInfo',
    title: 'Доп. информация',
  },
];

const UserFormModal: FC<IAgentFormModal> = ({ open, setClose, initialData }) => {
  const [agentForm] = Form.useForm();
  const [agentInfoForm] = Form.useForm();
  const [currentStep, setCurrentStep] = useState<string | undefined>('agent');
  const [registerAgent, registerAgentStates] = useRegisterAgentMutation();
  const [updateAgentInfo, updateAgentInfoStates] = useUpdateAgentInfosMutation();
  const [updateUserInfo, updateUserInfoStates] = useUpdateUserMutation();
  const { data } = useGetTaxStatusesQuery();
  const { data: fieldsToExcludeForAgent } = useGetFieldsToExcludeForAgentQuery();
  const login = Form.useWatch('login', agentForm);

  const generateLogin = () => {
    const { lastName, firstName } = agentForm.getFieldsValue();
    if (!lastName && !firstName) {
      toast.error('Для генерации поля "Фамилия" и "Имя" должны быть заполнены');
      return;
    }
    agentForm.setFieldsValue({ login: translit(`${lastName.trim()} ${firstName.trim()}`) });
  };

  useEffect(() => {
    if (initialData && open) {
      agentForm.setFieldsValue({
        ...initialData.agent,
        telegram: initialData.agent.telegram?.split(','),
      });
      agentInfoForm.setFieldsValue({
        ...initialData,
        taxStatusId: initialData.taxStatus.id,
        excludedFieldsIds: initialData.excludedFields.map(field => field.id),
      });
    }
  }, [initialData]);

  useEffect(() => {
    if (registerAgentStates.isSuccess) {
      toast.success('Контрагент успешно создан');
      handleClose();
    }
    if (registerAgentStates.isError) {
      toast.error('Ошибка при создании контрагента, проверьте поля на корректность');
    }
  }, [registerAgentStates.isSuccess, registerAgentStates.isError]);

  useEffect(() => {
    if (updateUserInfoStates.isSuccess) {
      toast.success('Данные контрагента успешно изменены');
      handleClose();
    }

    if (isFetchBaseQueryError(updateUserInfoStates.error)) {
      toast.error(
        getExceptionMessage(updateUserInfoStates.error.data as IException) ||
          'Ошибка при обновлении контрагента, проверьте поля на корректность'
      );
    }
  }, [updateUserInfoStates.isSuccess, updateUserInfoStates.isError]);

  useEffect(() => {
    if (updateAgentInfoStates.isSuccess) {
      toast.success('Дополнительная информация контрагента успешно изменена');
      handleClose();
    }
    if (updateAgentInfoStates.isError) {
      toast.error('Ошибка при обновлении дополнительной информации контрагента, проверьте поля на корректность');
    }
  }, [updateAgentInfoStates.isSuccess, updateAgentInfoStates.isError]);

  useEffect(() => {
    if (currentStep === 'agent') {
      agentForm.getFieldInstance('email')?.focus();
    } else {
      agentInfoForm.getFieldInstance('country')?.focus();
    }
  }, [currentStep]);

  const handleClose = () => {
    agentForm.resetFields();
    agentInfoForm.resetFields();
    setCurrentStep('agent');
    setClose();
  };
  const handleSubmit = async () => {
    const agent = agentForm.getFieldsValue();
    const agentInfo = agentInfoForm.getFieldsValue();

    const [isAgentInfoValid, isAgentFormValid] = await Promise.all([
      agentInfoForm
        .validateFields()
        .then(() => true)
        .catch(() => false),
      agentForm
        .validateFields()
        .then(() => true)
        .catch(() => false),
    ]);

    if (!isAgentInfoValid && !isAgentFormValid) {
      return;
    }

    const telegramString = (Array.isArray(agent.telegram) && agent.telegram.join(',')) || '';

    if (!initialData) {
      const result = {
        userForm: {
          ...agent,
          role: 'AGENT',
          state: 'CONFIRMED',
          telegram: telegramString,
        },
        agentInfoForm: agentInfo,
      };
      return registerAgent(result);
    }

    if (initialData && currentStep === 'agent') {
      return updateUserInfo({
        id: initialData.agent.id,
        data: {
          ...agent,
          telegram: telegramString,
        },
      });
    }

    if (initialData && currentStep === 'agentInfo') {
      return updateAgentInfo({
        id: initialData.id,
        data: agentInfo,
      });
    }

    return;
  };

  const renderAgentForm = () => {
    return (
      <Form
        style={{ display: currentStep === 'agent' ? 'block' : 'none' }}
        form={agentForm}
        layout={'vertical'}
        onFinish={handleSubmit}
      >
        <Form.Item label={'Email'} key={'email'} name={'email'}>
          <Input />
        </Form.Item>
        {!initialData && (
          <Form.Item label={'Пароль'} key={'password'} name={'password'}>
            <Input.Password />
          </Form.Item>
        )}
        <Form.Item
          label={'Телеграм'}
          key={'telegram'}
          name={'telegram'}
          rules={[
            {
              required: true,
              message: 'Поле "Телеграм" обязательное!',
            },
            {
              validator: (_, value) => {
                if (value && !(/^https:\/\/t\.me\//i.test(value) || /^@/i.test(value))) {
                  return Promise.reject('Ошибка валидации, поле должно содержать ссылку на аккаунт или тег');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Select mode={'tags'} open={false} />
        </Form.Item>
        <Form.Item
          label={'Фамилия'}
          key={'lastName'}
          name={'lastName'}
          rules={[
            {
              required: true,
              message: 'Поле "Фамилия" обязательное!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Имя'}
          key={'firstName'}
          name={'firstName'}
          rules={[
            {
              required: true,
              message: 'Поле "Имя" обязательное!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'Отчество'} key={'patronymic'} name={'patronymic'}>
          <Input />
        </Form.Item>
        <Form.Item
          label={'Логин'}
          name={'login'}
          key={'login'}
          rules={[
            {
              required: true,
              message: 'Поле "Логин" обязательное!',
            },
          ]}
        >
          <Row gutter={[20, 20]}>
            <Col xs={24} md={16}>
              <Input value={login} />
            </Col>
            <Col span={8}>
              <Button type={'primary'} onClick={generateLogin}>
                Сгенерировать
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label={'Номер телефона'}
          key={'phoneNumber'}
          name={'phoneNumber'}
          rules={[
            {
              required: true,
              message: 'Поле "Номер телефона" обязательное!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'Комментарии'} key={'comments'} name={'comments'}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Дата рождения"
          name="birthDate"
          getValueFromEvent={getValueFormDatePicker}
          getValueProps={getValueFormDatePickerProps}
        >
          <DatePickerBase />
        </Form.Item>
      </Form>
    );
  };
  const renderAgentInfoForm = () => {
    const items = [
      {
        label: 'Страна',
        key: 'country',
        required: true,
      },
      {
        label: 'Серия паспорта',
        key: 'passportSeries',
        required: true,
      },
      {
        label: 'Номер паспорта',
        key: 'passportNumber',
        required: true,
      },
      {
        label: 'Паспорт выдан',
        key: 'passportReleasedBy',
        required: true,
      },
      {
        label: 'Дата выдачи паспорта',
        key: 'passportReleasedAt',
        required: true,
      },
      {
        label: 'СНИЛС',
        key: 'snils',
        required: false,
      },
      {
        label: 'ИНН',
        key: 'inn',
        required: true,
      },
      {
        label: 'Адрес регистрации',
        key: 'registrationAddress',
        required: true,
      },
      {
        label: 'Адрес проживания',
        key: 'residentialAddress',
        required: false,
      },
      {
        label: 'Р/счет',
        key: 'paymentAccount',
        required: true,
      },
      {
        label: 'ОГРНИП',
        key: 'ogrnip',
        required: false,
      },
      {
        label: 'Банк получателя',
        key: 'recipientBank',
        required: true,
      },
      {
        label: 'Корр. счет',
        key: 'corrAccount',
        required: true,
      },
      {
        label: 'БИК',
        key: 'bik',
        required: true,
      },
      {
        label: 'ИНН',
        key: 'bankInn',
        required: false,
      },
      {
        label: 'КПП',
        key: 'bankKpp',
        required: false,
      },
    ];

    return (
      <Form
        style={{ display: currentStep === 'agentInfo' ? 'block' : 'none' }}
        form={agentInfoForm}
        layout={'vertical'}
        onFinish={handleSubmit}
      >
        {items.map(item => {
          if (item.key === 'passportReleasedAt') {
            return (
              <Form.Item
                label={item.label}
                key={item.key}
                name={item.key}
                rules={[
                  {
                    required: item.required,
                    message: `Поле "${item.label}" обязательное!`,
                  },
                ]}
                getValueFromEvent={getValueFormDatePicker}
                getValueProps={getValueFormDatePickerProps}
              >
                <DatePickerBase />
              </Form.Item>
            );
          }
          return (
            <Form.Item
              label={item.label}
              key={item.key}
              name={item.key}
              rules={[
                {
                  required: item.required,
                  message: `Поле "${item.label}" обязательное!`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        })}
        <Form.Item
          label={'Налоговый статус'}
          key={'taxStatusId'}
          name={'taxStatusId'}
          rules={[
            {
              required: true,
              message: 'Поле "Налоговый статус" обязательное!',
            },
          ]}
        >
          <Select allowClear>
            <Select.OptGroup label={'Налоговый статус'} />
            {data?.map(taxStatus => (
              <Select.Option key={taxStatus.id} value={taxStatus.id}>
                {taxStatus.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Поля для исключения из документов" name="excludedFieldsIds">
          <Checkbox.Group
            options={formattedFieldsToExcludeForAgent(fieldsToExcludeForAgent ?? [])}
            style={{ flexDirection: 'column' }}
          />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={!initialData ? 'Создать контрагента' : 'Изменить контрагента'}
      open={open}
      centered={true}
      okText={
        !initialData
          ? 'Создать'
          : 'Сохранить' + (initialData && currentStep === 'agent' ? ' данные пользователя' : ' доп. информацию')
      }
      cancelText={'Отмена'}
      confirmLoading={
        registerAgentStates.isLoading || updateUserInfoStates.isLoading || updateAgentInfoStates.isLoading
      }
      onOk={currentStep === 'agent' ? () => agentForm.submit() : () => agentInfoForm.submit()}
      onCancel={() => handleClose()}
      afterOpenChange={() => {
        agentForm.getFieldInstance('email')?.focus();
      }}
    >
      <Segmented
        block
        value={steps.find(step => step.key === currentStep)?.title}
        options={steps.map(step => step.title)}
        onChange={title => setCurrentStep(steps.find(step => step.title === title)?.key)}
        style={{ margin: '20px 0' }}
      />
      {renderAgentForm()}
      {renderAgentInfoForm()}
    </Modal>
  );
};

export default UserFormModal;
