import CustomTable from '../UI/Table';
import { ChangeEvent, Key, useEffect, useRef, useState } from 'react';
import { ISearchGroupsPageQuery } from '../../core/types/query';
import { ColumnsType } from 'antd/lib/table';
import { Button, Col, Dropdown, Form, Input, MenuProps, Modal, Row, Select } from 'antd';
import debounce from 'lodash/debounce';
import { IGroup } from '../../core/types/entities';
import { SettingOutlined } from '@ant-design/icons';
import { customFilterOption, getFullNameUser, getPosTableNum } from '../../core/utils';
import { useGetGroupsQuery, useParseGroupsMutation, useRemoveGroupMutation } from '../../store/services/groups';
import LessonGroupControlModal from '../Modals/LessonGroupControlModal';
import { Link } from 'react-router-dom';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { ISO_DATE, RU_DATE, RU_DATE_TIME } from '../../core/constants/dateFormatting';
import { RolesEnum, TypeLessonGroupEnum } from '../../core/types/enums';
import { lessonGroupTypeTranslations } from '../../core/constants/translations';
import { useAppSelector } from '../../core/hooks/redux';
import { useGetContractsCoursesQuery } from '../../store/services/contracts/courses';
import DatePickerBase from '../UI/DatePickerBase';

const { confirm } = Modal;
const { Option } = Select;

const LessonGroupTable = () => {
  const [paginationAndSearchParams, setPaginationAndSearchParams] = useState<ISearchGroupsPageQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
    sort: 'name,asc',
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const paginationCurrentPage = paginationAndSearchParams.page ? paginationAndSearchParams.page + 1 : 1;
  const { data } = useGetGroupsQuery(paginationAndSearchParams);
  const [showControlGroup, setShowControlGroup] = useState(false);
  const selectedGroup = useRef<IGroup | undefined>();
  const [removeGroup, { isError: isErrorRemoveGroup, isSuccess: isSuccessRemoveGroup }] = useRemoveGroupMutation();
  const [parseGroup, parseGroupStates] = useParseGroupsMutation();
  const { data: contractsCourses } = useGetContractsCoursesQuery();
  const { role } = useAppSelector(state => state.authReducer);

  const lessonGroups = data?.data.map((lessonGroup, index) => ({
    ...lessonGroup,
    posNum: getPosTableNum(
      index,
      paginationAndSearchParams.page || DEFAULT_PAGE,
      paginationAndSearchParams.size || DEFAULT_PAGE_SIZE
    ),
    key: lessonGroup.id,
  }));

  useEffect(() => {
    if (parseGroupStates.isSuccess) {
      toast.success('Парсинг лекций прошел успешно');
    }
    if (parseGroupStates.isError) {
      toast.error('Произошла ошибка при парсинге лекций');
    }
  }, [parseGroupStates.isError, parseGroupStates.isSuccess]);

  useEffect(() => {
    if (isSuccessRemoveGroup) {
      toast.success('Группа успешно удалена');
    }
    if (isErrorRemoveGroup) {
      toast.error('Сервер отказал в доступе! Произошла ошибка при удалении!');
    }
  }, [isErrorRemoveGroup, isSuccessRemoveGroup]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Тип',
      dataIndex: 'groupType',
      key: 'groupType',
      align: 'center',
      render: data => {
        type withoutAllType = Exclude<TypeLessonGroupEnum, TypeLessonGroupEnum.ALL>;
        const dataGroup = data as withoutAllType;
        return lessonGroupTypeTranslations[dataGroup];
      },
    },
    {
      title: 'Период',
      key: 'period',
      dataIndex: '',
      width: '135px',
      render: (_, data) => {
        const dataGroup = data as IGroup;
        return `От ${dayjs(dataGroup.startDate).format(RU_DATE)} до ${
          dataGroup?.finishDate ? dayjs(dataGroup?.finishDate).format(RU_DATE) : '"-"'
        }`;
      },
    },
    {
      title: 'Ссылка',
      dataIndex: 'url',
      key: 'url',
      render: url => (
        <a href={url} target={'_blank'} rel="noreferrer">
          Таблица
        </a>
      ),
    },
    {
      title: 'Курс',
      dataIndex: ['course', 'name'],
      key: 'courseName',
    },
    {
      title: 'ФИО контрагента',
      dataIndex: 'agent',
      key: 'agent',
      render: data => <Link to={`/agents/${data.id}`}>{getFullNameUser(data)}</Link>,
    },
    {
      title: 'ФИО создателя',
      dataIndex: 'creator',
      key: 'creator',
      render: data => getFullNameUser(data),
    },
    {
      title: 'Синхронизация',
      dataIndex: 'lastSyncDate',
      key: 'lastSyncDate',
      align: 'center',
      render: date => (date ? dayjs(date).format(RU_DATE_TIME) : '-'),
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_, data: unknown) => {
        const group = data as IGroup;
        return (
          <Dropdown menu={{ items: renderDropDownItems(group) }}>
            <SettingOutlined style={{ fontSize: '20px' }} />
          </Dropdown>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1, 1);
  }

  const renderDropDownItems = (group: IGroup) => {
    const items: MenuProps['items'] = [
      {
        key: 'drop-down-item-1',
        onClick: () => {
          selectedGroup.current = group;
          setShowControlGroup(true);
        },
        label: 'Изменение группы',
      },
      {
        label: 'Удаление группы',
        key: 'drop-down-item-2',
        onClick: () => {
          confirm({
            title: 'Вы точно хотите удалить группу?',
            centered: true,
            cancelText: 'Отмена',
            okText: 'Подтвердить',
            onOk() {
              removeGroup(group.id);
            },
          });
        },
        danger: true,
      },
    ];
    return items;
  };

  const handleSearch = debounce((value: ChangeEvent<HTMLInputElement>) => {
    const findText = value.target.value;
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: 0,
      name: findText,
    });
  }, 500);

  const handlePaginationChange = (page: number, size: number) => {
    setPaginationAndSearchParams({
      ...paginationAndSearchParams,
      page: page - 1,
      size: size,
    });
  };

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Row gutter={[20, 0]} style={{ marginBottom: '20px' }}>
          <Col xs={24}>
            <Button
              type={'primary'}
              onClick={() => {
                selectedGroup.current = undefined;
                setShowControlGroup(true);
              }}
            >
              Создать группу
            </Button>
          </Col>
        </Row>
      )}
      <Row gutter={[20, 20]} align={'bottom'}>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label="Тип" labelCol={{ span: 24 }}>
            <Select
              onChange={data =>
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  groupType: data,
                })
              }
              defaultValue={TypeLessonGroupEnum.ALL}
            >
              <Select.OptGroup label={'Тип'} />
              <Select.Option value={TypeLessonGroupEnum.ALL}>Все</Select.Option>
              <Select.Option value={TypeLessonGroupEnum.ONLINE}>Онлайн</Select.Option>
              <Select.Option value={TypeLessonGroupEnum.OFFLINE}>Офлайн</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label="Поиск по названию группы" labelCol={{ span: 24 }}>
            <Input allowClear placeholder="Введите название группы" onChange={handleSearch} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label="Поиск по курсу" labelCol={{ span: 24 }}>
            <Select
              showSearch
              filterOption={customFilterOption}
              placeholder="Выберите курс"
              allowClear
              onChange={data => {
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  courseId: data,
                });
              }}
            >
              <Select.OptGroup label={'Выберите курс'} />
              {!!contractsCourses &&
                contractsCourses?.map(p => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 20]} align={'bottom'}>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label={'Дата от'} labelCol={{ span: 24 }}>
            <DatePickerBase
              style={{ width: '100%' }}
              onChange={date =>
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  startDate: date?.format(ISO_DATE),
                })
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item label={'Дата до'} labelCol={{ span: 24 }}>
            <DatePickerBase
              style={{ width: '100%' }}
              onChange={date =>
                setPaginationAndSearchParams({
                  ...paginationAndSearchParams,
                  page: 0,
                  finishDate: date?.format(ISO_DATE),
                })
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item>
            <Button
              type={'primary'}
              onClick={() => {
                parseGroup({ ids: selectedRowKeys });
              }}
              disabled={selectedRowKeys.length === 0}
            >
              Запуск парсинга
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <CustomTable
        tableKey={'LessonGroupTable'}
        dataSource={lessonGroups ?? []}
        columns={columns}
        rowSelection={rowSelection}
        pagination={{
          current: paginationCurrentPage,
          pageSize: paginationAndSearchParams.size,
          total: data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <LessonGroupControlModal
        setShowModal={setShowControlGroup}
        showModal={showControlGroup}
        selectedGroup={selectedGroup.current}
      />
    </>
  );
};

export default LessonGroupTable;
