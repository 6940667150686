import CustomTable from '../UI/Table';
import { ColumnsType } from 'antd/lib/table';
import FileTypeButtonGroup from '../FileTypeButtonGroup';
import { IActCertificate } from '../../core/types/entities';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { RU_DATE } from '../../core/constants/dateFormatting';
import { FC, useState } from 'react';
import CertificateDownloadByAgentModal from '../Modals/CertificateDownloadByAgentModal';
import { useGetActsCertificatesQuery } from '../../store/services/acts';
import { ISearchActsCertificatesPageQuery } from '../../core/types/query';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../core/constants/pagination';
import ReceiptControlModal from '../Modals/ReceiptControlModal';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';

interface IAgentProfileReceiptsTable {
  agentId: string | undefined;
}

const AgentProfileReceiptsTable: FC<IAgentProfileReceiptsTable> = ({ agentId }) => {
  const { role, user } = useAppSelector(state => state.authReducer);
  const agentIdRes = role === RolesEnum.AGENT ? user?.id : agentId;
  const [searchAndPaginationParams, setSearchAndPaginationParams] = useState<ISearchActsCertificatesPageQuery>({
    agentId: agentIdRes,
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGE_SIZE,
  });

  const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [selectedActId, setSelectedActId] = useState<number | undefined>(undefined);
  const actsCertificates = useGetActsCertificatesQuery(searchAndPaginationParams, { skip: !agentIdRes });
  const paginationCurrentPage = searchAndPaginationParams.page ? searchAndPaginationParams.page + 1 : 1;

  const actsCertificateData = actsCertificates.data?.data.map((certificate, index) => ({
    ...certificate,
    key: certificate.paymentDate + index,
  }));

  const columns: ColumnsType = [
    {
      title: 'Дата оплаты',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
      render: date => {
        return dayjs(date).format(RU_DATE);
      },
    },
    {
      title: 'Справки',
      dataIndex: 'certificates',
      key: 'certificates',
      align: 'center',
      render: (_, data) => {
        const { actId, certificates } = data as IActCertificate;

        if (certificates && certificates.length !== 0) {
          return <FileTypeButtonGroup items={certificates.map(certificate => certificate.file)} />;
        }
        return (
          <Button
            onClick={() => {
              setIsCertificateModalOpen(true);
              setSelectedActId(actId);
            }}
            type={'text'}
            style={{
              color: 'rgb(69, 43, 121)',
              textDecoration: 'underline',
            }}
          >
            <span style={{ textDecoration: 'underline' }}>Загрузить</span>
          </Button>
        );
      },
    },
    {
      title: 'Чеки',
      dataIndex: 'receipt',
      key: 'receipt',
      align: 'center',
      render: (_, data) => {
        const { actId, receipt } = data as IActCertificate;

        if (receipt && receipt.length !== 0) {
          return <FileTypeButtonGroup items={receipt.map(receipts => receipts.file)} />;
        }
        return (
          <Button
            onClick={() => {
              setIsReceiptModalOpen(true);
              setSelectedActId(actId);
            }}
            type={'text'}
            style={{
              color: 'rgb(69, 43, 121)',
              textDecoration: 'underline',
            }}
          >
            <span style={{ textDecoration: 'underline' }}>Загрузить</span>
          </Button>
        );
      },
    },
  ];

  const handleCloseCertificateModal = () => {
    setIsCertificateModalOpen(false);
    setSelectedActId(undefined);
  };

  const handlePaginationChange = (page: number, size: number) => {
    setSearchAndPaginationParams({
      ...searchAndPaginationParams,
      page: page - 1,
      size: size,
    });
  };

  return (
    <>
      <CustomTable
        tableKey={'AgentProfileReceiptsTable'}
        dataSource={actsCertificateData}
        columns={columns}
        pagination={{
          current: paginationCurrentPage,
          pageSize: searchAndPaginationParams.size,
          total: actsCertificates.data?.total,
          onChange: handlePaginationChange,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['20', '50', '100'],
        }}
      />
      <CertificateDownloadByAgentModal
        open={isCertificateModalOpen}
        setClose={handleCloseCertificateModal}
        actId={selectedActId}
      />
      <ReceiptControlModal
        open={isReceiptModalOpen}
        setClose={() => setIsReceiptModalOpen(false)}
        actId={selectedActId}
      />
    </>
  );
};

export default AgentProfileReceiptsTable;
