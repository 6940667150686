import { useUploadFileMutation } from '../../store/services/files';
import { Button, Upload, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import { generateFileUrl } from '../../core/utils';
import { IFileInfo } from '../../core/types/entities';

interface FileUploadProps {
  onChange: (value: UploadFile[] | UploadFile) => void;
  accept: string;
  fileList?: UploadFile<any>[];
  disabled?: boolean;
}

const FileUpload = ({ onChange, accept, fileList, disabled }: FileUploadProps) => {
  const [uploadFile] = useUploadFileMutation();
  const upload = useRef();

  const handleUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const res = await uploadFile(formData);
      // @ts-ignore
      const storageFileName = (res!.data as string)[0];
      // @ts-ignore
      onChange({
        // @ts-ignore
        name: file.name,
        status: 'done',
        thumbUrl: '',
        uid: storageFileName,
        url: generateFileUrl({ storageFileName: storageFileName } as IFileInfo),
      });
      // @ts-ignore
      upload.current.onSuccess(res, {
        ...file,
        // @ts-ignore
        uid: res.data[0],
      });
    } catch (error) {
      // @ts-ignore
      upload.current.onError();
    }
  };

  const handleRemove = (file: UploadFile) => {
    if (fileList) {
      const newFileList = fileList.filter(file => file.status === 'removed');
      onChange(newFileList[0]);
    }
  };

  return (
    <Upload
      ref={upload}
      maxCount={1}
      accept={accept}
      onRemove={handleRemove}
      customRequest={({ file }) => handleUpload(file as File)}
      fileList={fileList}
      disabled={disabled}
      data-testid="file-upload"
    >
      <Button disabled={disabled} icon={<UploadOutlined />}>
        Загрузить
      </Button>
    </Upload>
  );
};

export default FileUpload;
