import { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { toast } from 'react-toastify';
import { ColumnsType } from 'antd/lib/table';
import { IProject } from '../../core/types/entities';
import CustomTable from '../UI/Table';
import {
  useAddProjectMutation,
  useGetProjectsQuery,
  useRemoveProjectMutation,
  useUpdateProjectMutation,
} from '../../store/services/contracts/projects';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../core/hooks/redux';
import { RolesEnum } from '../../core/types/enums';

const { confirm } = Modal;

const ProjectsTable = () => {
  const { data } = useGetProjectsQuery();
  const [addProject, addProjectStates] = useAddProjectMutation();
  const [updateProject, updateProjectStates] = useUpdateProjectMutation();
  const [removeProject, removeProjectStates] = useRemoveProjectMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [selectedProject, setSelectedProject] = useState<IProject | undefined>(undefined);
  const { role } = useAppSelector(state => state.authReducer);

  const projectsData = data?.map((projects, index) => ({
    ...projects,
    posNum: index + 1,
    key: projects.id,
  }));

  useEffect(() => {
    if (addProjectStates.isSuccess) {
      toast.success('Новый тип проекта успешно добавлен!');
      setShowModal(false);
      form.resetFields();
    }
    if (addProjectStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
    if (removeProjectStates.isError) {
      toast.error('Произошла ошибка при удалении типа проекта');
    }
  }, [addProjectStates.isSuccess, addProjectStates.isError, removeProjectStates.isError]);

  useEffect(() => {
    if (updateProjectStates.isSuccess) {
      toast.success('Успешно сохранено!');
      handleCloseModal();
    }
    if (updateProjectStates.isError) {
      toast.error('Произошла ошибка, повторите попытку');
    }
  }, [updateProjectStates.isSuccess, updateProjectStates.isError]);

  const columns: ColumnsType = [
    {
      title: '№',
      dataIndex: 'posNum',
      key: 'posNum',
      align: 'center',
      width: 100,
    },
    {
      title: 'Тип проекта',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 160,
      render: (_, data) => {
        const project = data as IProject;
        return (
          <div
            style={{
              display: 'inline-flex',
              gap: '10px',
            }}
          >
            <Button
              data-testid={`edit-project-${project.id}`}
              shape="circle"
              onClick={() => {
                setShowModal(true);
                form.setFieldsValue(project);
                setSelectedProject(project);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              data-testid={`delete-project-${project.id}`}
              danger
              shape="circle"
              onClick={() => {
                confirm({
                  title: 'Вы точно хотите удалить тип проекта?',
                  centered: true,
                  cancelText: 'Отмена',
                  okText: 'Подтвердить',
                  onOk() {
                    handleRemoveContractType(project.id);
                  },
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  if (role === RolesEnum.MANAGER) {
    columns.splice(-1);
  }

  const handleSubmitForm = (data: { name: string }) => {
    if (selectedProject) {
      return updateProject([selectedProject.id, data]);
    }
    addProject(data);
  };

  const handleRemoveContractType = (id: number) => {
    removeProject(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProject(undefined);
    form.resetFields();
  };

  return (
    <>
      {role !== RolesEnum.MANAGER && (
        <Button onClick={() => setShowModal(true)} type={'primary'} style={{ margin: '20px 0' }}>
          ДОБАВИТЬ
        </Button>
      )}
      <CustomTable tableKey={'ProjectsTable'} columns={columns} dataSource={projectsData} pagination={false} />
      <Modal
        forceRender
        open={showModal}
        centered
        title={(selectedProject ? 'Редактировать' : 'Добавить') + ' тип проекта'}
        okText={selectedProject ? 'Сохранить' : 'Добавить'}
        onCancel={handleCloseModal}
        onOk={() => form.submit()}
        confirmLoading={addProjectStates.isLoading}
        afterOpenChange={() => form.getFieldInstance('name')?.focus()}
      >
        <Form form={form} onFinish={handleSubmitForm} layout={'vertical'}>
          <Form.Item
            label={'Тип проекта'}
            key={'name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Поле "Тип проекта" обязательное!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProjectsTable;
