import { FC, useEffect } from 'react';
import { Col, Form, Modal, Row, Select } from 'antd';
import { useCreateCertificateMutation } from '../../store/services/certificates';
import { toast } from 'react-toastify';
import { getExceptionMessage, isFetchBaseQueryError, normFile } from '../../core/utils';
import { useGetActsQuery } from '../../store/services/acts';
import { useAppSelector } from '../../core/hooks/redux';
import FileUpload from '../UI/FileUpload';
import { ICertificateCreateForm } from '../../core/types/forms';
import { IException } from '../../core/types/response';
import { RolesEnum } from '../../core/types/enums';

interface ICertificateDownloadByAgentModal {
  open: boolean;
  setClose: () => void;
  actId?: number;
}

const CertificateDownloadByAgentModal: FC<ICertificateDownloadByAgentModal> = ({ open, setClose, actId }) => {
  const [form] = Form.useForm();
  const [createCertificate, createCertificateStates] = useCreateCertificateMutation();
  const acts = useGetActsQuery({ size: 999 }, { skip: !open }).data;
  const { role, user } = useAppSelector(state => state.authReducer);

  useEffect(() => {
    if (createCertificateStates.isSuccess) {
      toast.success('Справка успешно создана');
      handleCloseModal();
    }
    if (isFetchBaseQueryError(createCertificateStates.error)) {
      toast.error(
        getExceptionMessage(createCertificateStates.error.data as IException) ||
          'Возникла проблема при создании справки'
      );
    }
  }, [createCertificateStates.isError, createCertificateStates.isSuccess]);

  const handleCloseModal = () => {
    form.resetFields();
    setClose();
  };

  const handleCreateCertificate = () => {
    const data = form.getFieldsValue();
    const resultData: ICertificateCreateForm = {
      agentId: data.agentId,
      actId: data.actId,
      fileNames: [data.firstFile?.[0]?.uid, data.secondfile?.[0]?.uid],
    };

    if (actId) {
      resultData['actId'] = actId;
    }

    resultData['agentId'] = user?.id;

    createCertificate(resultData);
  };

  return (
    <Modal
      style={{ margin: '20px 0' }}
      title={(role === RolesEnum.AGENT ? 'Загрузить' : 'Создать') + ' справку'}
      open={open}
      centered={true}
      cancelText={'Закрыть'}
      onOk={() => form.submit()}
      onCancel={handleCloseModal}
      okText={'Создать'}
      destroyOnClose
    >
      <Form form={form} layout={'vertical'} onFinish={handleCreateCertificate}>
        {!actId && (
          <Form.Item
            label={'Акт'}
            name={'actId'}
            rules={[
              {
                required: true,
                message: 'Поле "Акт" обязательное!',
              },
            ]}
          >
            <Select allowClear>
              {acts?.data.map(act => (
                <Select.Option key={act.id} value={act.id}>
                  № {act.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              label={'Справка 1'}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              name={'firstFile'}
              rules={[
                {
                  required: true,
                  message: 'Поле "Справка 1" обязательное!',
                },
              ]}
            >
              <FileUpload onChange={() => {}} accept={'*'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={'Справка 2'}
              getValueFromEvent={normFile}
              valuePropName="fileList"
              name={'secondfile'}
              rules={[
                {
                  required: true,
                  message: 'Поле "Справка 2" обязательное!',
                },
              ]}
            >
              <FileUpload onChange={() => {}} accept={'*'} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CertificateDownloadByAgentModal;
