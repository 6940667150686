import { useEffect, useState } from 'react';

export function useGetSizeTable() {
  const [sizeTable, setSizeTable] = useState<DOMRect | undefined>(undefined);
  const [isTableWiderThanMainElement, setIsTableWiderThanMainElement] = useState(false);

  useEffect(() => {
    const tableWrapper = document.querySelector('.ant-table-content');
    const mainWrapper = document.querySelector('main');
    const tableSize = tableWrapper?.children[0].getBoundingClientRect();
    const mainWrapperSize = mainWrapper?.getBoundingClientRect();
    if (tableSize && mainWrapperSize) {
      setSizeTable(tableSize);
      setIsTableWiderThanMainElement(tableSize.width > mainWrapperSize.width);
    }
  }, []);

  return { sizeTable, isTableWiderThanMainElement };
}
